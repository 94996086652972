// import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import AppWrapperRoute from "./hooks/withRouteWrapper";
import GlobalStyle from "./App.styled";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#232a3d",
    },
  },
  typography: {
    fontFamily: "Fredoka One",
  },
});
const App = () => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <GlobalStyle />
        <Router>
          <AppWrapperRoute />
        </Router>
      </LocalizationProvider>
    </MuiThemeProvider>
  );
};

export default App;
