import { StoreState } from "models/StoreState";
import { HomePosting, HomeState } from "models/HomeState";
import { GeoObjectDetail } from "models/HomeState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ViewPortProps } from "../../models/HomeState";
import {
  createHousePosting,
  getActiveBooking,
  getAllBookingsApi,
  getDriversMap,
  getHousePosting,
  getPostingDuration,
} from "services/api/homeApi";
import { AllBooking } from "models/BookingStatus";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    isLoading: true,
    geoLocations: [],
    selectedGeoObject: undefined,
    defaultView: 0,
    viewPort: undefined,
    openFilterMobile: false,
    homePostings: [],
    hasActiveBooking: false,
    isNavigateFromActiveBooking: false,
    navigateToActiveBooking: false,
    allBookings: {} as AllBooking,
  } as HomeState,
  reducers: {
    setLoadingStatus(state, action) {
      state.isLoading = action.payload;
    },

    setAllGeoLocations(state, action) {
      state.geoLocations = action.payload;
    },
    setSelectedGeoObject(state, action) {
      state.selectedGeoObject = action.payload;
    },
    setDefaultView(state, action) {
      state.defaultView = action.payload;
    },
    setViewPort(state, action) {
      state.viewPort = action.payload;
    },
    openFilterMobile(state, action) {
      state.openFilterMobile = action.payload;
    },
    setHomePostings(state, action) {
      state.homePostings = action.payload;
    },
    setHasActiveBooking(state, action) {
      state.hasActiveBooking = action.payload;
    },
    setIsNavigateFromActiveBooking(state, action) {
      state.isNavigateFromActiveBooking = action.payload;
    },
    setNavigateToActiveBooking(state, action) {
      state.navigateToActiveBooking = action.payload;
    },
    setAllBooking(state, action) {
      state.allBookings = action.payload;
    },
  },
});
export const getIsNavigateFromActiveBooking = (state: StoreState): boolean =>
  state.home.isNavigateFromActiveBooking;
export const getNavigateToActiveBooking = (state: StoreState): boolean =>
  state.home.navigateToActiveBooking;
export const getHasActiveBooking = (state: StoreState): boolean =>
  state.home.hasActiveBooking;
export const getFilterMobileIsOpen = (state: StoreState): boolean =>
  state.home.openFilterMobile;
export const getDefaultView = (state: StoreState): number =>
  state.home.defaultView;
export const getLoadingStatus = (state: StoreState): boolean =>
  state.home.isLoading;

export const getAllGeoLocations = (state: StoreState): GeoObjectDetail[] =>
  state.home.geoLocations;

export const getSelectedGeoLocationObject = (
  state: StoreState
): GeoObjectDetail | undefined => state.home.selectedGeoObject;

export const getViewPort = (state: StoreState): ViewPortProps | undefined =>
  state.home.viewPort;

export const getAllHousePostings = (state: StoreState): HomePosting[] =>
  state.home.homePostings;

export const getAllBookings = (state: StoreState): AllBooking =>
  state.home.allBookings;

export const getActiveBookingThunk = createAsyncThunk(
  "home/getActiveBookingThunk",
  async (access_token: string, thunkApi) => {
    const { dispatch } = thunkApi;
    const bookingDetails = await getActiveBooking(access_token);
    const hasActiveBooking = Object.keys(bookingDetails).length > 0;
    dispatch(homeSlice.actions.setHasActiveBooking(hasActiveBooking));
    dispatch(homeSlice.actions.setNavigateToActiveBooking(hasActiveBooking));
    dispatch(homeSlice.actions.setLoadingStatus(false));
  }
);
export const getAllBookingsThunk = createAsyncThunk(
  "home/getAllBookingsThunk",
  async (access_token: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(homeSlice.actions.setLoadingStatus(true));
    const bookingDetails = await getAllBookingsApi(access_token);
    dispatch(homeSlice.actions.setAllBooking(bookingDetails));
    dispatch(homeSlice.actions.setLoadingStatus(false));
  }
);

type GetPostingDurationRequest = {
  accessToken: string;
  postingId: number;
  date: string;
};
export const getPostingDurationThunk = createAsyncThunk(
  "home/getPostingDurationThunk",
  async (request: GetPostingDurationRequest, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const state: StoreState = getState() as StoreState;
    const { accessToken, postingId, date } = request;
    const response = await getPostingDuration(
      {
        id: postingId,
        date,
      },
      accessToken
    );
    const updated: GeoObjectDetail = {
      ...state.home.selectedGeoObject!,
      available_duration: response,
    };
    dispatch(homeSlice.actions.setSelectedGeoObject(updated));
  }
);

export const getAllDriverParkingSpotMap = createAsyncThunk(
  "home/getAllDriverParkingSpotMap",
  async (access_token: string, thunkApi) => {
    const { dispatch } = thunkApi;
    const driverMaps = await getDriversMap(access_token);
    dispatch(homeSlice.actions.setAllGeoLocations(driverMaps));
    dispatch(homeSlice.actions.setLoadingStatus(false));
  }
);

export const getAllHousePostingsThunk = createAsyncThunk(
  "home/getAllHousePostingsThunk",
  async (access_token: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(homeSlice.actions.setLoadingStatus(true));
    const postings = await getHousePosting(access_token);
    dispatch(homeSlice.actions.setHomePostings(postings));
    dispatch(homeSlice.actions.setLoadingStatus(false));
  }
);
type Request = {
  accessToken: string;
  posting: any;
};
export const createHomePostingsThunk = createAsyncThunk(
  "home/createHomePostingsThunk",
  async (request: Request, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, posting } = request;
    await createHousePosting(posting, accessToken);
    dispatch(getAllHousePostingsThunk(accessToken));
  }
);

export const {
  setSelectedGeoObject,
  setDefaultView,
  setViewPort,
  openFilterMobile,
  setHasActiveBooking,
  setIsNavigateFromActiveBooking,
  setLoadingStatus,
  setNavigateToActiveBooking,
} = homeSlice.actions;
export default homeSlice.reducer;
