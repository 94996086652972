import { FormValues } from "./index";

export const validateForm = (values: FormValues) => {
    let errors: any = {};

    if (!values.email) {
        errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    if (!values.password) {
        errors.password = "Password is required";
    }

    return errors;
};