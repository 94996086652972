import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CheckInRequest, CheckInState, Parking } from "models/CheckInState";
import { StoreState } from "models/StoreState";
import { checkInAPI, checkOutAPI, getParkingAPI } from "services/api/checkInApi";

const checkInSlice = createSlice({
    name: "checkIn",
    initialState: {
        isLoading: true,
        success: false,
        parking: null,
        checkoutTime: "-",
        checkInError: null
    } as CheckInState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setSuccess(state, action) {
            state.success = action.payload;
        },
        setParking(state, action) {
            state.parking = action.payload;
        },
        setCheckoutTime(state, action) {
            state.checkoutTime = action.payload;
        },
        setCheckInError(state, action) {
            state.checkInError = action.payload;
        }
    },
});

export const getParkingThunk = createAsyncThunk(
    "drivers/getParkingThunk",
    async (accessToken: string, thunkApi) => {
        const { dispatch } = thunkApi;
        const response = await getParkingAPI(accessToken);
        dispatch(checkInSlice.actions.setParking(response));
        dispatch(checkInSlice.actions.setCheckoutTime("-"));
        dispatch(checkInSlice.actions.setIsLoading(false));
    }
);

type CheckInType = {
    accessToken: string;
    checkIn: CheckInRequest;
};
export const checkInThunk = createAsyncThunk(
    "drivers/checkInThunk",
    async (request: CheckInType, thunkApi) => {
        const { dispatch } = thunkApi;
        const { accessToken, checkIn } = request;
        dispatch(checkInSlice.actions.setCheckInError(null));
        dispatch(checkInSlice.actions.setIsLoading(true));
        const checkedIn = await checkInAPI(checkIn, accessToken);
        if (checkedIn.success) {
            const response = await getParkingAPI(accessToken);
            dispatch(checkInSlice.actions.setParking(response));
            dispatch(checkInSlice.actions.setIsLoading(false));
        } else {
            dispatch(checkInSlice.actions.setIsLoading(false));
            dispatch(checkInSlice.actions.setCheckInError(checkedIn.message));
        }
    }
);

export const checkOutThunk = createAsyncThunk(
    "drivers/checkOutThunk",
    async (accessToken: string, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(checkInSlice.actions.setIsLoading(true));
        const response = await checkOutAPI(accessToken);
        dispatch(checkInSlice.actions.setIsLoading(false));
        dispatch(checkInSlice.actions.setCheckoutTime(response));
    }
);

export const getLoadingStatus = (state: StoreState): boolean =>
  state.checkIn.isLoading;
export const getSuccessStatus = (state: StoreState): boolean =>
  state.checkIn.success;

export const getDriverParking = (state: StoreState): Parking | null =>
    state.checkIn.parking;
export const getCheckoutTime = (state: StoreState): string =>
    state.checkIn.checkoutTime;
export const getCheckInError = (state: StoreState): string | null =>
    state.checkIn.checkInError;

export const {
    setIsLoading,
    setSuccess,
    setCheckInError
} = checkInSlice.actions;
export default checkInSlice.reducer;