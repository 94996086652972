import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import {
  checkInThunk,
  checkOutThunk,
  getCheckoutTime,
  getDriverParking,
  getParkingThunk,
  getLoadingStatus,
  getCheckInError,
  setCheckInError,
  setIsLoading
} from "redux/slices/checkInSlices";
import LoadingComponent from "components/LoadingComponent";
import {
  getActiveBookingThunk,
  getFilterMobileIsOpen,
  getHasActiveBooking,
  openFilterMobile,
  setNavigateToActiveBooking
} from "redux/slices/homeSlices";
import KebabIcon from "assets/kebab.svg";
import useScreenSize from "hooks/useScreenSize";
import MobileFilterList from "components/FilterListComponent/MobileFilterList";
import WithAuthWrapper from "hooks/withAuthWrapper";
import { getIsApprovedDriver } from "redux/slices/authenticationSlices";
import { getProfileThunk } from "redux/slices/accountSlices";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import LocationErrorModal from "components/LocationErrorModal";
import Logo from "assets/images/logo-park.png";

const Status = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingStatus);
  const driverParking = useSelector(getDriverParking);
  const checkoutTime = useSelector(getCheckoutTime);
  const isApprovedDriver = useSelector(getIsApprovedDriver);
  const isFilterMobileOpen = useSelector(getFilterMobileIsOpen);
  const checkInError = useSelector(getCheckInError);
  const [state, setState] = useState(0);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [showLocationError, setShowLocationError] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const hasActiveBooking = useSelector(getHasActiveBooking);

  const handleSuccesCurrentPosition = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    document.body.style.overflow = "initial";
    setLoadingLocation(false);
    setLatitude(latitude);
    setLongitude(longitude);
    setShowLocationError(false);
  };

  const handleErrorCurrentPosition = (error: GeolocationPositionError) => {
    setLoadingLocation(false);
    document.body.style.overflow = "hidden";
    setShowLocationError(true);
  };

  useEffect(() => {
    if (isApprovedDriver) {
      navigator.geolocation.getCurrentPosition(
        handleSuccesCurrentPosition,
        handleErrorCurrentPosition,
        {
          enableHighAccuracy: true,
          timeout: 2000,
          maximumAge: 0
        }
      );
    }
  }, [latitude, longitude, isApprovedDriver]);

  useEffect(() => {
    if (!isLoading && driverParking) {
      if (driverParking.check_in_at != "-") {
        setState(1);
      }
    }
  }, [isLoading, driverParking]);

  useEffect(() => {
    dispatch(setNavigateToActiveBooking(false));
    dispatch(getParkingThunk(accessToken));
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && checkoutTime != "-") {
      setState(state + 1);
      dispatch(getProfileThunk(accessToken));
    }
  }, [isLoading, checkoutTime]);

  useEffect(() => {
    if (!hasActiveBooking) {
      dispatch(getActiveBookingThunk(accessToken));
    }
  }, [hasActiveBooking]);

  const handleSubmit = () => {
    if (isApprovedDriver) {
      if (state == 0) {
        dispatch(
          checkInThunk({
            checkIn: {
              latitude: latitude,
              longitude: longitude
            },
            accessToken
          })
        );
      } else if (state == 1) {
        dispatch(checkOutThunk(accessToken));
      } else {
        dispatch(setIsLoading(true));
        dispatch(getParkingThunk(accessToken));
        setState(0);
      }
    } else {
      window.open(`https://wa.me/6${driverParking?.phone_number}`);
    }
  };

  return (
    <>
      <Snackbar
        open={checkInError !== null}
        autoHideDuration={2000}
        onClose={() => {
          dispatch(setCheckInError(null));
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          color="error"
          variant="filled"
        >
          {checkInError}
        </Alert>
      </Snackbar>
      {(showLocationError || !latitude || !longitude) &&
        !isLoading &&
        isApprovedDriver && (
          <LocationErrorModal
            isLoading={loadingLocation}
            onClose={() => {}}
            onEnable={() => {
              setLoadingLocation(true);
              navigator.geolocation.getCurrentPosition(
                handleSuccesCurrentPosition,
                handleErrorCurrentPosition,
                {
                  enableHighAccuracy: true,
                  timeout: 2000,
                  maximumAge: 0
                }
              );
            }}
          />
        )}
      {isLoading && <LoadingComponent />}
      {!isLoading && (
        <>
          {isFilterMobileOpen && <MobileFilterList />}
          <div
            className="maincontainer"
            style={{ padding: "0%", paddingBottom: 20 }}
          >
            <div style={{ display: "flex", height: "80px", width: "100%" }}>
              <img
                onClick={() => dispatch(openFilterMobile(true))}
                alt="logo"
                src={KebabIcon}
                style={{
                  position: "fixed",
                  top: 20,
                  left: 20,
                  height: "1.5em",
                  width: "1.5em",
                  cursor: "pointer"
                }}
              />
              <img
                src={Logo}
                width={120}
                alt="logo"
                style={{
                  margin: "auto"
                }}
              />
            </div>

            <div className="container" style={{ padding: "0%" }}>
              <h3
                style={{
                  color: "#e0479e",
                  fontFamily: "Fredoka One",
                  textAlign: "center"
                }}
              >
                my status
              </h3>

              <div
                className="container"
                style={{
                  color: "#e0479e",
                  fontFamily: "Fredoka One",
                  width: "100%",
                  position: "relative",
                  height: 250,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div>
                  {state == 0 && (
                    <img
                      src={require("../../assets/icons/parking/checkin.png")}
                      style={{ width: 150 }}
                    />
                  )}
                  {state == 1 && (
                    <img
                      src={require("../../assets/icons/parking/parkingcheckout.png")}
                      style={{ width: 150 }}
                    />
                  )}
                  {state == 2 && (
                    <img
                      src={require("../../assets/icons/parking/checkin.png")}
                      style={{ width: 150 }}
                    />
                  )}
                </div>
                {isApprovedDriver && (
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      bottom: -20,
                      right: 20
                    }}
                  >
                    <div
                      onClick={() => {
                        if (hasActiveBooking) {
                          window.open(
                            `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${driverParking?.latitude},${driverParking?.longitude}`
                          );
                        }
                      }}
                      style={{
                        width: 50,
                        height: 50,
                        background: `url(${require("../../assets/images/googlemap.png")})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        borderRadius: "50%",
                        boxShadow: "0 5px 10px rgba(0,0,0,.1)"
                      }}
                    ></div>
                  </div>
                )}
              </div>

              <div
                className="text-center"
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "0% 10% 10% 10%"
                }}
              >
                {!isApprovedDriver && (
                  <>
                    <div
                      className=""
                      style={{
                        fontSize: "15px",
                        paddingTop: "10px",
                        paddingBottom: "5px",
                        color: "#e0479e"
                      }}
                    >
                      Driver Plate No
                    </div>
                    <div
                      className=""
                      style={{
                        fontSize: "17px",
                        paddingBottom: "10px",
                        fontWeight: 600,
                        paddingTop: "5px",
                        color: "#575252"
                      }}
                    >
                      {driverParking?.plate_number}
                    </div>
                  </>
                )}
                <div
                  className=""
                  style={{
                    fontSize: "15px",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#e0479e"
                  }}
                >
                  Address
                </div>
                <div
                  className=""
                  style={{
                    fontSize: "17px",
                    paddingBottom: "10px",
                    fontWeight: 600,
                    paddingTop: "5px",
                    color: "#575252"
                  }}
                >
                  {driverParking?.unit_no}, {driverParking?.address}
                </div>

                <div
                  className=""
                  style={{
                    fontSize: "15px",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#e0479e"
                  }}
                >
                  Check In Time
                </div>
                <div
                  className=""
                  style={{
                    fontSize: "17px",
                    paddingBottom: "10px",
                    fontWeight: 600,
                    paddingTop: "5px",
                    color: "#575252"
                  }}
                >
                  {driverParking?.check_in_at}
                </div>

                <div
                  className=""
                  style={{
                    fontSize: "15px",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#e0479e"
                  }}
                >
                  Check Out Time
                </div>
                <div
                  className=""
                  style={{
                    fontSize: "17px",
                    paddingBottom: "10px",
                    fontWeight: 600,
                    paddingTop: "5px",
                    color: "#575252"
                  }}
                >
                  {checkoutTime}
                </div>

                <div
                  className=""
                  style={{
                    fontSize: "15px",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#e0479e"
                  }}
                >
                  {/* if driver */}
                  Days Remaining
                  {/* if owner */}
                  {/* Start  Date */}
                </div>
                <div
                  className=""
                  style={{
                    fontSize: "17px",
                    paddingBottom: "10px",
                    fontWeight: 600,
                    paddingTop: "5px",
                    color: "#575252"
                  }}
                >
                  {driverParking?.remaining}

                  {/* if owner */}
                  {/* 1st January 2023 */}
                </div>

                <br />

                <div className="form-group">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary btn-block"
                    style={{
                      fontFamily: "Fredoka One",
                      borderRadius: "20px",
                      backgroundColor:
                        state == 1 && isApprovedDriver ? "#52489C" : "#e0479e",
                      border: "none",
                      fontSize: "16px",
                      padding: "10px"
                    }}
                    disabled={!hasActiveBooking}
                  >
                    {!isApprovedDriver && "contact driver"}
                    {isApprovedDriver && state == 0 && "check in"}
                    {isApprovedDriver && state == 1 && "check out"}
                    {isApprovedDriver && state == 2 && "complete"}
                  </button>
                  {isApprovedDriver && state !== 2 && (
                    <button
                      type="button"
                      onClick={() => {
                        window.open(
                          `https://wa.me/6${driverParking?.phone_number}`
                        );
                      }}
                      className="btn btn-primary btn-block"
                      style={{
                        fontFamily: "Fredoka One",
                        borderRadius: "20px",
                        backgroundColor: "#52489C",
                        border: "none",
                        fontSize: "16px",
                        padding: "10px",
                        margin: 0,
                        marginTop: 20
                      }}
                      disabled={!hasActiveBooking}
                    >
                      contact homeowner
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WithAuthWrapper(Status);
