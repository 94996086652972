import { HereItem, HereResponse } from "models/HomeOwner";
import { useEffect, useState } from "react";
import { hereAutoCompleteAPI } from "services/api/homeOwnerApi";
import * as Styled from "./AutoComplete.styled";

type AutoCompleteProps = {
    onSelected: (item: HereItem|null) => void;
};
const AutoComplete: React.FC<AutoCompleteProps> = (props) => {
    const [input, setInput] = useState<string>("");
    const [addresses, setAddresses] = useState<HereItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<number|null>(null);

    useEffect(() => {

        const timeout = setTimeout(() => {
            if (input.length >= 4 && selected == null) {
                getAddress();
            }
        }, 1000);

        return () => clearTimeout(timeout);

    }, [input]);

    const resetAddresses = () => {
        setAddresses([]);
    }

    const getAddress = async () => {
        setIsLoading(true);
        const response = await hereAutoCompleteAPI(input);

        setIsLoading(false);
        setAddresses(response);
    };

    return (
        <Styled.AutoCompleteHolder highlighted={input.length > 0}>
            <Styled.AutoCompleteField type="text" placeholder="Street Address" value={input} onChange={e => {
                setInput(e.target.value)
                setIsLoading(true);
                setSelected(null);
                props.onSelected(null);
                resetAddresses();
            }} />
            {input.length > 0 && (isLoading || addresses.length > 0) && (
                <Styled.AutoCompleteListHolder>
                    {isLoading && <Styled.AutoCompleteList>Loading...</Styled.AutoCompleteList>}
                    {addresses.map((item, key) => (
                        <Styled.AutoCompleteList onClick={() => {
                            props.onSelected(item);
                            setInput(item.address.label);
                            setSelected(key);
                            resetAddresses();
                        }} key={item.id}>{item.address.label}</Styled.AutoCompleteList>
                    ))}
                </Styled.AutoCompleteListHolder>
            )}
        </Styled.AutoCompleteHolder>
    );
};

export default AutoComplete;