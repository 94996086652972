import React, { ReactNode, ComponentType } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";

const WithThemeWrapper = <
  T extends JSX.IntrinsicAttributes & { children?: ReactNode }
>(
  WrappedComponent: ComponentType<T>
): React.FC<T> => {
  const Layout = (props: T) => {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <WrappedComponent {...props} />
        </ThemeProvider>
      </React.Fragment>
    );
  };

  return Layout;
};

export default WithThemeWrapper;
