import { FC } from "react";
import * as Styled from "./IconComponent.styled";

import parking from "assets/parking.png";

type Props = {
  price?: number;
};
const IconComponent: FC<Props> = (props) => {
  const { price } = props;
  return (
    <div
      style={{
        display: "flex",
        borderRadius: "20px",
        width: 120,
        height: 50,
        padding: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          background: "#e0479e",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          width: "30%",
          margin: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontFamily: "Fredoka One",
            color: "#fff",
            fontSize: 15,
            fontWeight: "bold",
            textAlign: "center",
            margin: 0,
          }}
        >
          P
        </p>
      </div>
      <div
        style={{
          fontFamily: "Fredoka One",
          background: "#FFf",
          borderTopRightRadius: "20px",
          borderBottomRightRadius: "20px",
          width: "70%",
          margin: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontFamily: "Fredoka One",
            color: "#e0479e",
            fontSize: 12,
            fontWeight: "bold",
            textAlign: "center",
            margin: 0,
          }}
        >
          {`RM ${price ?? `-`}`}
        </p>
      </div>
    </div>
  );
};

export default IconComponent;
