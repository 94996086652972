import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Styled from "./LocationErrorModal.styled";

type ForgotPasswordModalProps = {
    onClose: () => void;
    onEnable: () => void;
    isLoading: boolean;
};
const LocationErrorModal: React.FC<ForgotPasswordModalProps> = props => {
    return (
        <Styled.MainWrapper>
            <Styled.MainContainer>
                <Styled.TopHolder>
                    <div></div>
                    <Styled.Title>location access required</Styled.Title>
                    <div></div>
                </Styled.TopHolder>
                <Styled.SizedBox height={20} />
                <Styled.Message>Error! We cannot provide this feature without access to your location. Please enable location services to continue. We need it to offer you the best possible experience and personalize our services to your needs.</Styled.Message>
                <Styled.SizedBox height={20} />
                <Styled.Button onClick={props.onEnable} disabled={props.isLoading}>{props.isLoading ? "loading..." : "enable"}</Styled.Button>
            </Styled.MainContainer>
        </Styled.MainWrapper>
    );
};

export default LocationErrorModal;