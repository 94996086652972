import React, { FC, useEffect, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import Cross24 from "../../assets/Cross24";
import * as Styled from "./DetailsModal.styled";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  getHasActiveBooking,
  getPostingDurationThunk,
  getSelectedGeoLocationObject
} from "redux/slices/homeSlices";
import { BookingRequest } from "models/Driver";
import {
  bookingThunk,
  getLoadingBookingStatus,
  getBooking
} from "redux/slices/driverSlices";
import { Formik, Form as FormikForm } from "formik";
import { useNavigate } from "react-router-dom";
import { getIsPendingDriver } from "redux/slices/authenticationSlices";
import SelectInput from "components/MDInput/SelectInput";
import {
  MobileDatePicker,
  PickersDayProps,
  PickersDay
} from "@mui/x-date-pickers";
import dayJs, { Dayjs } from "dayjs";

const initialValues = {
  start_date: "",
  duration: "",
  emergency_address: ""
};

type CreateFormProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DetailsModal: FC<CreateFormProps> = (props) => {
  const accessToken = localStorage.getItem("carching-token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onClose } = props;
  const [alignment, setAlignment] = React.useState("1");
  const selectedLocation = useSelector(getSelectedGeoLocationObject);
  const isLoading = useSelector(getLoadingBookingStatus);
  const booking = useSelector(getBooking);
  const isPendingDriver = useSelector(getIsPendingDriver);
  const hasActiveBooking = useSelector(getHasActiveBooking);
  const formikRef = useRef<any>(null);

  useEffect(() => {
    if (!isLoading && !!booking) {
      navigate("/checkout");
    }
  }, [booking, isLoading]);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const handleBook = (values) => {
    if (isPendingDriver) {
      alert("You only can book when your registration has been approved.");
    } else {
      if (!!selectedLocation && !!accessToken) {
        const request: BookingRequest = {
          ...values,
          posting_id: selectedLocation.posting_id,
          is_working_nearby: alignment
        };

        dispatch(
          bookingThunk({
            accessToken,
            booking: request
          })
        );
      }
    }
  };

  const customDay = (
    props: PickersDayProps<Dayjs> & { disabledDays?: string[] }
  ) => {
    const { disabledDays, day, ...other } = props;

    var isDisabled = false;

    if ((disabledDays ?? []).indexOf(day.format("YYYY-MM-DD")) >= 0) {
      isDisabled = true;
    } else if (day.isBefore(dayJs(new Date()), "date")) {
      isDisabled = true;
    } else if (day.isBefore(dayJs(selectedLocation?.start_date), "date")) {
      isDisabled = true;
    } else if (day.isAfter(dayJs(selectedLocation?.end_date), "date")) {
      isDisabled = true;
    }
    // else if (
    //   (disabledDays ?? []).indexOf(day.add(1, "month").format("YYYY-MM-DD")) >=
    //   0
    // ) {
    //   isDisabled = true;
    // }

    // else if (day.isBefore(disabledDays![0]) && dayJs(disabledDays![0]).diff(day, 'month') < 1) {
    //   isDisabled = true;
    // } else if (day.isAfter(disabledDays![disabledDays!.length-1]) && dayJs(disabledDays![disabledDays!.length-1]).diff(day, 'month') > -1) {
    //   isDisabled = true;
    // }
    return <PickersDay {...other} day={day} disabled={isDisabled} />;
  };

  return (
    <Modal
      style={{ inset: 0 }}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      onClose={onClose}
      open={!!isOpen}
    >
      <Styled.MainContainer>
        <Styled.ModalContentContainer>
          <Styled.CloseButton onClick={onClose}>
            <Cross24 colour="#fff" />
          </Styled.CloseButton>
          <Styled.ImageContainer>
            <Styled.ImageContent
              src={selectedLocation?.home_picture_url}
              alt="carpark"
            />
          </Styled.ImageContainer>

          <Formik
            initialValues={initialValues}
            onSubmit={handleBook}
            innerRef={formikRef}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <Styled.ListContainer>
                  <Styled.Addresscontainer>
                    <Styled.AddressLabel>
                      {selectedLocation?.address}
                    </Styled.AddressLabel>
                  </Styled.Addresscontainer>
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <MobileDatePicker
                    sx={{
                      width: "100%",
                      background: "#f0f1f5",
                      borderRadius: "20px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                      }
                    }}
                    label="Start Date"
                    minDate={dayJs(new Date())}
                    format="DD/MM/YYYY"
                    maxDate={dayJs(selectedLocation?.end_date)}
                    onAccept={(date) => {
                      if (!!selectedLocation?.posting_id && !!accessToken) {
                        dispatch(
                          getPostingDurationThunk({
                            accessToken,
                            postingId: selectedLocation?.posting_id,
                            date: date?.format("YYYY-MM-DD") ?? ""
                          })
                        );
                        setFieldValue("start_date", date?.format("YYYY-MM-DD"));
                        setFieldValue("duration", "");
                      }
                    }}
                    slots={{
                      day: customDay
                    }}
                    slotProps={{
                      day: {
                        disabledDays: selectedLocation?.disabled_days ?? []
                      } as any
                    }}
                  />
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <SelectInput
                    title={`${
                      values.start_date === ""
                        ? "Please select start date first"
                        : "Duration"
                    }`}
                    disabled={values.start_date === ""}
                    items={Array((selectedLocation?.duration ?? 0) + 1)
                      .fill(0)
                      .map((_, i) => {
                        const month = i + 1;
                        return {
                          id: month,
                          name: month + " " + (month > 1 ? "months" : "month"),
                          disabled:
                            month > (selectedLocation?.available_duration ?? 1)
                        };
                      })}
                    onSelected={(val) => {
                      setFieldValue("duration", val);
                    }}
                    value={values.duration}
                    defaultValue={values.duration}
                    width={"100%"}
                  />
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <Styled.PriceContainer>
                    <Styled.ListTitle>Price Per Month: RM100</Styled.ListTitle>
                  </Styled.PriceContainer>
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    sx={{
                      width: "100%",
                      background: "#f0f1f5",
                      borderRadius: "20px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                      },
                      "& .MuiToggleButton-root.Mui-selected": {
                        backgroundColor: "#e0479e",
                        color: "#fff",
                        borderRadius: "20px"
                      },
                      "& .MuiToggleButton-root.Mui-selected:hover": {
                        backgroundColor: "#e0479e",
                        color: "#fff",
                        borderRadius: "20px"
                      },
                      "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                        border: "none"
                      },
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        border: "none"
                      }
                    }}
                  >
                    <ToggleButton
                      value="1"
                      sx={{
                        width: "50%",
                        borderColor: "transparent",
                        textTransform: "capitalize",
                        borderRadius: "20px"
                      }}
                    >
                      I'm working nearby
                    </ToggleButton>
                    <ToggleButton
                      value="0"
                      sx={{
                        width: "50%",
                        borderColor: "transparent",
                        textTransform: "capitalize",
                        borderRadius: "20px"
                      }}
                    >
                      I'm staying nearby
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Styled.ListContainer>

                <Styled.Field
                  name="emergency_address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={4}
                  value={values.emergency_address}
                  placeholder="Work/Home Address (Please note that we only require this address in case of an emergency situation. This will be our last resort if we cannot contact the driver via phone call)"
                />

                <Styled.Note>
                  * Please note that parking booked will only be for weekdays
                  from 8:30am to 6:30am, and does not include public holiday and
                  weekend
                </Styled.Note>
                <Styled.SubmitButton
                  disabled={
                    hasActiveBooking ||
                    values.start_date === "" ||
                    values.duration === "" ||
                    values.emergency_address === ""
                  }
                  onClick={() => {
                    if (formikRef.current) {
                      formikRef.current.handleSubmit();
                    }
                  }}
                >
                  {isLoading ? "loading..." : "book"}
                </Styled.SubmitButton>
              </FormikForm>
            )}
          </Formik>
        </Styled.ModalContentContainer>
      </Styled.MainContainer>
    </Modal>
  );
};
export default DetailsModal;
