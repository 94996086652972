import React, { FC, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCameraAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import {
  HereItem,
  HomeOwnerRegistrationStepTwoRequest,
} from "models/HomeOwner";
import FileInput from "../FileInput";
import { validateForm } from "./validateForm";
import AutoComplete from "components/AutoComplete";
import LocationErrorModal from "components/LocationErrorModal";
import * as Styled from "pages/SignUp/SignUp.styled";

export interface FormValues {
  unit_no: string;
  address: string;
  electricity_bill_url: string;
  home_url: string;
}

const initialValues: FormValues = {
  unit_no: "",
  address: "",
  electricity_bill_url: "",
  home_url: "",
};

type HomeOwnerRegistrationStepTwoProps = {
  submitApplication: (request: HomeOwnerRegistrationStepTwoRequest) => void;
  goBack: () => void;
};

const HomeOwnerRegistrationStepTwo: FC<HomeOwnerRegistrationStepTwoProps> = (
  props
) => {
  const { submitApplication } = props;
  const [selected, setSelected] = useState<HereItem | null>(null);
  const formikRef = React.useRef<any>(null);
  const [latitude, setLatitude] = React.useState<number | null>(null);
  const [longitude, setLongitude] = React.useState<number | null>(null);
  const [allow, setAllow] = React.useState<boolean>(false);
  const [showLocationError, setShowLocationError] = React.useState<boolean>(
    false
  );
  const [loadingLocation, setLoadingLocation] = React.useState<boolean>(false);

  const handleSuccesCurrentPosition = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    setLoadingLocation(false);
    document.body.style.overflow = "initial";
    setLatitude(latitude);
    setLongitude(longitude);
    setAllow(true);
    setShowLocationError(false);
  };

  const handleErrorCurrentPosition = (error: GeolocationPositionError) => {
    setLoadingLocation(false);
    document.body.style.overflow = "hidden";
    setShowLocationError(true);
  };

  const onSubmit = (values: FormValues) => {
    if (!selected) {
      alert("Please select address");
      return;
    }

    const address = selected.address;

    const request: HomeOwnerRegistrationStepTwoRequest = {
      ...values,
      electricity_bill_picture: values.electricity_bill_url,
      home_picture: values.home_url,
      street: address.street,
      country_name: address.countryName,
      country_code: address.countryCode,
      state_code: address.stateCode,
      state: address.state,
      county: address.county,
      city: address.city,
      district: address.district,
      postal_code: address.postalCode,
      place_id: selected.id,
      address: address.label,
      picture_latitude: latitude!,
      picture_longitude: longitude!,
      step: 2,
    };

    submitApplication(request);
  };

  return (
    <>
      {showLocationError && (
        <LocationErrorModal
          isLoading={loadingLocation}
          onClose={() => {}}
          onEnable={() => {
            setLoadingLocation(true);
            navigator.geolocation.getCurrentPosition(
              handleSuccesCurrentPosition,
              handleErrorCurrentPosition,
              {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 2000,
              }
            );
          }}
        />
      )}
      <div className="maincontainer">
        <div
          className="container"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <FontAwesomeIcon
            onClick={props.goBack}
            icon={faChevronLeft}
            style={{ color: "#ccc", paddingTop: "40px" }}
          ></FontAwesomeIcon>
          <h3
            className="text-center"
            style={{
              color: "#e0479e",
              fontFamily: "Fredoka One",
              paddingBottom: "5%",
              paddingTop: "20px",
            }}
          >
            homeowner
          </h3>
          <p>
            <div className="" style={{ color: "#e0479e", fontSize: "10px" }}>
              Step 2 of 2
            </div>
            <div
              className=""
              style={{
                color: "#e0479e",
                fontFamily: "Fredoka One",
                fontSize: "20px",
              }}
            >
              tell us more about your home
            </div>
          </p>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formikRef}
            validate={validateForm}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              values,
              handleBlur,
              handleChange,
              setFieldValue,
              errors,
              submitCount,
            }) => (
              <FormikForm>
                <div
                  className="form-group input-group flex-column"
                  style={{ paddingTop: "3%" }}
                >
                  <Styled.Field
                    name="unit_no"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.unit_no}
                    highlighted={!!values.unit_no}
                    error={errors.unit_no != null}
                    type="text"
                    placeholder="Unit No."
                  />

                  <p
                    className="m-0 ml-1 mt-1 error"
                    style={{
                      fontSize: "0.7em",
                      color: "#e0479e",
                    }}
                  >
                    <ErrorMessage name="unit_no" />
                  </p>
                </div>

                <div
                  className="form-group input-group"
                  style={{ paddingTop: "3%" }}
                >
                  <AutoComplete onSelected={(item) => setSelected(item)} />

                  {!selected && submitCount > 0 && (
                    <p
                      className="m-0 mt-1 error text-danger"
                      style={{ fontSize: "0.9em" }}
                    >
                      Invalid address. Please select an address from the
                      autocomplete suggestions
                    </p>
                  )}
                </div>
                <div
                  className=""
                  style={{
                    fontSize: "17px",
                    paddingTop: "3%",
                    color: "#575252",
                  }}
                >
                  Kindly take a picture of your:
                </div>
                <p
                  className="m-0 mt-1 error text-danger"
                  style={{ fontSize: "0.9em" }}
                >
                  <ErrorMessage name="electricity_bill_url" />
                </p>
                <p
                  className="m-0 mt-1 error text-danger"
                  style={{ fontSize: "0.9em" }}
                >
                  <ErrorMessage name="home_url" />
                </p>
                <div className="row" style={{ paddingTop: "2.5%" }}>
                  <div
                    className="col-10"
                    style={{
                      color: errors.electricity_bill_url
                        ? "#dc3545"
                        : values.electricity_bill_url
                        ? "#e0479e"
                        : "#575252",
                    }}
                  >
                    • Electricity bill
                  </div>
                  <FileInput
                    name="electricity_bill_picture"
                    error={errors.electricity_bill_url != null}
                    onChange={(image) => {
                      setFieldValue("electricity_bill_url", image);
                    }}
                  />
                </div>
                <div className="row" style={{ paddingTop: "2.5%" }}>
                  <div
                    className="col-10"
                    style={{
                      color: errors.home_url
                        ? "#dc3545"
                        : values.home_url
                        ? "#e0479e"
                        : "#575252",
                    }}
                  >
                    • Home -{" "}
                    <a
                      style={{ color: "#e0479e" }}
                      target="_blank"
                      href="https://media.discordapp.net/attachments/912697718273622067/1088269439448907876/fotor_2023-3-20_12_47_3.png?width=1704&height=1136"
                    >
                      Sample
                    </a>
                  </div>
                  <FileInput
                    name="home_picture"
                    error={errors.home_url != null}
                    onChange={(image) => {
                      setFieldValue("home_url", image);
                    }}
                    allow={allow}
                    customFunc={() => {
                      navigator.geolocation.getCurrentPosition(
                        handleSuccesCurrentPosition,
                        handleErrorCurrentPosition,
                        {
                          enableHighAccuracy: true,
                          timeout: 2000,
                          maximumAge: 0,
                        }
                      );
                    }}
                  />
                </div>
                <p
                  style={{ fontSize: "0.68em", color: "#a4a4a4", marginTop: 5 }}
                >
                  * Please make sure photos are clear to speed up approval
                  process
                </p>

                <p
                  style={{ fontSize: "0.68em", color: "#a4a4a4", marginTop: 0 }}
                >
                  * Please note that we will be using this registered property
                  and the home image as your listing property details
                </p>

                <p
                  className="m-0 mt-1"
                  style={{ fontSize: "0.68em", color: "#a4a4a4" }}
                >
                  * To safeguard your privacy, only your photo and street
                  address will be shown to potential drivers. Unit number will
                  only be shared with drivers who have completed booking.
                </p>

                <div className="form-group" style={{ paddingTop: "10%" }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#e0479e",
                      borderColor: "#e0479e",
                      fontFamily: "Fredoka One",
                    }}
                    onClick={() => {
                      if (formikRef.current) {
                        formikRef.current.handleSubmit();
                      }
                    }}
                  >
                    {" "}
                    submit{" "}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default HomeOwnerRegistrationStepTwo;
