import axios, { AxiosResponse } from "axios";
import { AxiosGenericResponse } from "models/common";
import {
  CurrentHomeOwner,
  HereItem,
  HereResponse,
  HomeOwnerApplicationStatus,
  HomeOwnerRegistrationStepOneRequest,
  HomeOwnerRegistrationStepTwoRequest,
} from "models/HomeOwner";
import { getRequest, postRequest } from "services/client/request";

const apiVersion = "/api";

export const submitApplicationAPI = async (
  request:
    | HomeOwnerRegistrationStepOneRequest
    | HomeOwnerRegistrationStepTwoRequest,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await postRequest(
    `${apiVersion}/carefrees/homeowner_register`,
    accessToken,
    request
  );
  return response.data.success;
};

export const getApplicationStatusAPI = async (
  accessToken: string
): Promise<HomeOwnerApplicationStatus> => {
  const response: AxiosResponse<
    AxiosGenericResponse<HomeOwnerApplicationStatus>
  > = await getRequest(
    `${apiVersion}/carefrees/homeowners/status`,
    accessToken
  );
  const applicationStatus: HomeOwnerApplicationStatus = response.data.data;
  return applicationStatus;
};

export const hereAutoCompleteAPI = async (
  query: string
): Promise<HereItem[]> => {
  const response: AxiosResponse<HereResponse> = await axios.get(
    `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${query}&in=countryCode:MYS&apiKey=${process.env.REACT_APP_HERE_API_KEY}`
  );
  const addresses: HereItem[] = response.data.items.filter(
    (item) => item.resultType === "street"
  );

  return addresses;
};

export const getCurrentHomeDetailsAPI = async (
  accessToken: string
): Promise<CurrentHomeOwner> => {
  const response: AxiosResponse<
    AxiosGenericResponse<CurrentHomeOwner>
  > = await getRequest(
    `${apiVersion}/carefrees/homeowners/current`,
    accessToken
  );
  const applicationStatus: CurrentHomeOwner = response.data.data;
  return applicationStatus;
};
