import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User, LoginRequest } from "models/User";
import { StoreState } from "models/StoreState";
import { AuthenticationState } from "models/AuthenticationState";
import { NewUser } from "models/Authentication";
import {
  login,
  signUp,
  getUserDetail,
  getCurrentUserCarefreeStatus,
  requestOTPAPI,
  resetPassword,
  resendVerificationEmail,
} from "services/api/authenticationApi";
import { setDefaultView } from "./homeSlices";
import { setIsMissingEmergencyInfo } from "./driverSlices";

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isLoading: false,
    isAuthenticated: false,
    accessToken: "",
    user: {},
    loginFailed: false,
    registerFailed: false,
    errorMessage: null,
    isApprovedDriver: false,
    isApprovedHomeOwner: false,
    isRegisteringDriver: false,
    isRegisteringHomeOwner: false,
    isHomeOwner: true,
    isLoadingOTP: false,
    isSentOTP: false,
    isPendingDriver: false,
    isPendingHomeOwner: false,
    resetPasswordEmailSent: null,
    isLoadingResetPassword: false,
    isLoadingVerificationEmail: false,
    isSentVerificationEmail: null,
    isRegistered: false,
    isLogout: false,
  } as AuthenticationState,
  reducers: {
    setUserDetails(state, action) {
      state.user = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setLoginFailed(state, action) {
      state.loginFailed = action.payload;
    },
    setIsApprovedDriver(state, action) {
      state.isApprovedDriver = action.payload;
    },
    setIsApprovedHomeOwner(state, action) {
      state.isApprovedHomeOwner = action.payload;
    },
    setIsRegisteringDriver(state, action) {
      state.isRegisteringDriver = action.payload;
    },
    setIsRegisteringHomeOwner(state, action) {
      state.isRegisteringHomeOwner = action.payload;
    },
    setIsHomeOwner(state, action) {
      state.isHomeOwner = action.payload;
    },
    setRegisterFailed(state, action) {
      state.registerFailed = action.payload;
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    setIsLoadingOTP(state, action) {
      state.isLoadingOTP = action.payload;
    },
    setIsSentOTP(state, action) {
      state.isSentOTP = action.payload;
    },
    setIsPendingHomeOwner(state, action) {
      state.isPendingHomeOwner = action.payload;
    },
    setIsPendingDriver(state, action) {
      state.isPendingDriver = action.payload;
    },
    setResetPasswordEmailSent(state, action) {
      state.resetPasswordEmailSent = action.payload;
    },
    setIsLoadingResetPassword(state, action) {
      state.isLoadingResetPassword = action.payload;
    },
    setIsLoadingVerificationEmail(state, action) {
      state.isLoadingVerificationEmail = action.payload;
    },
    setIsSentVerificationEmail(state, action) {
      state.isSentVerificationEmail = action.payload;
    },
    setIsRegistered(state, action) {
      state.isRegistered = action.payload;
    },
    setIsLogout(state, action) {
      state.isLogout = action.payload;
    },
  },
});

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.authentication.isLoading;
export const getIsAuthenticatedStatus = (state: StoreState): boolean =>
  state.authentication.isAuthenticated;
export const getUserDetails = (state: StoreState): User =>
  state.authentication.user;
export const getAccessToken = (state: StoreState): string =>
  state.authentication.accessToken;
export const getLoginFailed = (state: StoreState): boolean =>
  state.authentication.loginFailed;
export const getIsApprovedDriver = (state: StoreState): boolean =>
  state.authentication.isApprovedDriver;
export const getIsApprovedHomeOwner = (state: StoreState): boolean =>
  state.authentication.isApprovedHomeOwner;
export const getIsRegisteringDriver = (state: StoreState): boolean =>
  state.authentication.isRegisteringDriver;
export const getIsRegisteringHomeOwner = (state: StoreState): boolean =>
  state.authentication.isRegisteringHomeOwner;
export const getIsHomeOwner = (state: StoreState): string => {
  if (state.authentication.isApprovedDriver) return "Driver";

  if (state.authentication.isApprovedHomeOwner) return "HomeOwner";
  return "None";
};
export const getIsPendingDriver = (state: StoreState): boolean =>
  state.authentication.isPendingDriver;
export const getIsPendingHomeOwner = (state: StoreState): boolean =>
  state.authentication.isPendingHomeOwner;
export const getRegisterFailed = (state: StoreState): boolean =>
  state.authentication.registerFailed;
export const getErrorMessage = (state: StoreState): string | null =>
  state.authentication.errorMessage;

export const getIsLoadingOTP = (state: StoreState): boolean =>
  state.authentication.isLoadingOTP;
export const getIsSentOTP = (state: StoreState): boolean =>
  state.authentication.isSentOTP;

export const getLoadingResetPasswordStatus = (state: StoreState): boolean =>
  state.authentication.isLoadingResetPassword;
export const getResetPasswordEmailSent = (state: StoreState): boolean | null =>
  state.authentication.resetPasswordEmailSent;

export const getLoadingVerificationEmailStatus = (state: StoreState): boolean =>
  state.authentication.isLoadingVerificationEmail;
export const getSentVerificationEmail = (state: StoreState): boolean | null =>
  state.authentication.isSentVerificationEmail;

export const getIsRegistered = (state: StoreState): boolean =>
  state.authentication.isRegistered;
export const getIsLogout = (state: StoreState): boolean =>
  state.authentication.isLogout;
// Actions
export const validateAuthentication = createAsyncThunk(
  "authentication/validateAuth",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    try {
      dispatch(authenticationSlice.actions.setIsLoading(true));
      const [userDetails, { driver, homeowner }] = await Promise.all([
        getUserDetail(accessToken),
        getCurrentUserCarefreeStatus(accessToken),
      ]);

      if (homeowner == "Approved") {
        dispatch(authenticationSlice.actions.setIsApprovedHomeOwner(true));
      } else if (driver == "Approved") {
        dispatch(authenticationSlice.actions.setIsApprovedDriver(true));
      } else if (homeowner === "Pending Approval") {
        dispatch(authenticationSlice.actions.setIsPendingHomeOwner(true));
      } else if (driver === "Pending Approval") {
        dispatch(authenticationSlice.actions.setIsPendingDriver(true));
      } else if (driver === "Missing Emergency Info") {
        dispatch(setIsMissingEmergencyInfo(true));
      } else if (driver != "") {
        dispatch(authenticationSlice.actions.setIsRegisteringDriver(true));
      } else if (homeowner != "") {
        dispatch(authenticationSlice.actions.setIsRegisteringHomeOwner(true));
      }

      if (!!userDetails) {
        dispatch(authenticationSlice.actions.setIsAuthenticated(true));
        dispatch(authenticationSlice.actions.setUserDetails(userDetails));
      } else {
        dispatch(authenticationSlice.actions.setLoginFailed(true));
        dispatch(authenticationSlice.actions.setIsAuthenticated(false));
        dispatch(authenticationSlice.actions.setUserDetails(undefined));
        dispatch(logout());
      }
    } catch (e) {
      dispatch(authenticationSlice.actions.setLoginFailed(true));
      dispatch(authenticationSlice.actions.setIsAuthenticated(false));
      dispatch(authenticationSlice.actions.setUserDetails(undefined));
      dispatch(logout());
    }
    dispatch(authenticationSlice.actions.setIsLoading(false));
  }
);

export const loginRequest = createAsyncThunk(
  "authentication/login",
  async (credentials: LoginRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsLoading(true));
    dispatch(authenticationSlice.actions.setIsLogout(false));
    const loginResponse = await login(credentials);
    if (!!loginResponse.success) {
      const { token, user } = loginResponse;

      dispatch(authenticationSlice.actions.setAccessToken(token));
      dispatch(authenticationSlice.actions.setUserDetails(user));
      dispatch(authenticationSlice.actions.setIsAuthenticated(true));
      dispatch(authenticationSlice.actions.setLoginFailed(false));
      localStorage.setItem("carching-token", token);
    } else {
      dispatch(authenticationSlice.actions.setLoginFailed(true));
      dispatch(authenticationSlice.actions.setIsAuthenticated(false));
      dispatch(authenticationSlice.actions.setAccessToken(undefined));
      dispatch(authenticationSlice.actions.setIsLoading(false));
    }
  }
);

export const getCarefreeStatus = createAsyncThunk(
  "authentication/getCarefreeStatus",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    // dispatch(authenticationSlice.actions.setIsLoading(true));
    const { homeowner, driver } = await getCurrentUserCarefreeStatus(
      accessToken
    );
    if (homeowner == "Approved") {
      dispatch(authenticationSlice.actions.setIsApprovedHomeOwner(true));
    } else if (driver == "Approved") {
      dispatch(authenticationSlice.actions.setIsApprovedDriver(true));
    } else if (driver === "Missing Emergency Info") {
      dispatch(setIsMissingEmergencyInfo(true));
    } else if (driver != "") {
      dispatch(authenticationSlice.actions.setIsRegisteringDriver(true));
    } else if (homeowner != "") {
      dispatch(authenticationSlice.actions.setIsRegisteringHomeOwner(true));
    }
    dispatch(authenticationSlice.actions.setIsLoading(false));
  }
);

export const signUpNewAccount = createAsyncThunk(
  "authentication/signUp",
  async (credentials: NewUser, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsLoading(true));
    const loginResponse = await signUp(credentials);
    if (loginResponse.success) {
      const { token, user } = loginResponse;
      // dispatch(authenticationSlice.actions.setAccessToken(token));
      // dispatch(authenticationSlice.actions.setUserDetails(user));
      // dispatch(authenticationSlice.actions.setIsAuthenticated(true));
      // localStorage.setItem("carching-token", token);
      dispatch(authenticationSlice.actions.setIsLoading(false));
      dispatch(authenticationSlice.actions.setIsRegistered(true));
    } else {
      const { message } = loginResponse;
      dispatch(authenticationSlice.actions.setRegisterFailed(true));
      dispatch(authenticationSlice.actions.setErrorMessage(message));
      dispatch(authenticationSlice.actions.setIsAuthenticated(false));
      dispatch(authenticationSlice.actions.setAccessToken(undefined));
      dispatch(authenticationSlice.actions.setIsLoading(false));
    }
  }
);

export const logout = createAsyncThunk(
  "authentication/logout",
  async (_, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsAuthenticated(false));
    dispatch(authenticationSlice.actions.setAccessToken(undefined));
    dispatch(authenticationSlice.actions.setUserDetails(undefined));
    dispatch(authenticationSlice.actions.setIsApprovedDriver(false));
    dispatch(authenticationSlice.actions.setIsApprovedHomeOwner(false));
    dispatch(authenticationSlice.actions.setIsRegisteringDriver(false));
    dispatch(authenticationSlice.actions.setIsRegisteringHomeOwner(false));
    dispatch(authenticationSlice.actions.setIsLogout(true));
    localStorage.removeItem("carching-token");
    //window.location.reload();
  }
);

export const requestOTP = createAsyncThunk(
  "authentication/requestOTP",
  async (phone: string, thunkApi) => {
    const { dispatch } = thunkApi;
    
    if (["0000000000", "1111111111", "0111111111", "0123456789", "9876543210"].indexOf(phone) !== -1) {
        dispatch(authenticationSlice.actions.setRegisterFailed(true));
        dispatch(authenticationSlice.actions.setErrorMessage("Please provide a valid mobile number"));
        return;
    }

    dispatch(authenticationSlice.actions.setIsLoadingOTP(true));
    const response = await requestOTPAPI(phone);
    if (response === "success") {
      dispatch(authenticationSlice.actions.setIsSentOTP(response));
    } else {
      dispatch(authenticationSlice.actions.setRegisterFailed(true));
      dispatch(authenticationSlice.actions.setErrorMessage(response));
    }
    dispatch(authenticationSlice.actions.setIsLoadingOTP(false));
  }
);

export const forgetAccountPassword = createAsyncThunk(
  "authentication/forgotPassword",
  async (email: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsLoadingResetPassword(true));
    const response = await resetPassword(email);
    dispatch(authenticationSlice.actions.setIsLoadingResetPassword(false));
    dispatch(authenticationSlice.actions.setResetPasswordEmailSent(response));
  }
);

export const resendVerificationEmailThunk = createAsyncThunk(
  "authentication/resendVerificationEmailThunk",
  async (email: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsLoadingVerificationEmail(true));
    const response = await resendVerificationEmail(email);
    dispatch(authenticationSlice.actions.setIsLoadingVerificationEmail(false));
    dispatch(authenticationSlice.actions.setIsSentVerificationEmail(response));
  }
);

export const {
  setIsRegisteringDriver,
  setIsRegisteringHomeOwner,
  setIsHomeOwner,
  setLoginFailed,
  setRegisterFailed,
  setErrorMessage,
  setIsSentOTP,
  setIsLoading,
  setResetPasswordEmailSent,
  setIsSentVerificationEmail,
  setIsRegistered,
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
