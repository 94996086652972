import DriverRegistrationStepOne from "components/DriverStepOne";
import DriverRegistrationStepTwo from "components/DriverStepTwo";
import LoadingComponent from "components/LoadingComponent";
import RegistrationSuccess from "components/RegistrationSuccess";
import WithAuthWrapper from "hooks/withAuthWrapper";
import {
  DriverRegistrationStepOneRequest,
  DriverRegistrationStepTwoRequest,
} from "models/Driver";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getIsRegisteringHomeOwner } from "redux/slices/authenticationSlices";
import {
  getApplicationStatus,
  getApplicationStatusThunk,
  getLoadingStatus,
  getSuccessStatus,
  setSuccess,
  submitApplicationThunk,
} from "redux/slices/driverSlices";

const DriverRegister: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const [page, setPage] = React.useState<number>(0);
  const success = useSelector(getSuccessStatus);
  const isLoading = useSelector(getLoadingStatus);
  const applicationStatus = useSelector(getApplicationStatus);
  const isRegisteringHomeOwner = useSelector(getIsRegisteringHomeOwner);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isRegisteringHomeOwner) {
      navigate("/homeowner/register");
    }
  }, [isRegisteringHomeOwner]);

  useEffect(() => {
    if (!isLoading && !!success) {
      setPage((page) => page + 1);
      dispatch(setSuccess(false));
    }
  }, [success, isLoading]);

  useEffect(() => {
    if (!!applicationStatus) {
      setPage(applicationStatus.state);
    }
  }, [applicationStatus]);

  useEffect(() => {
    if (!!accessToken) {
      dispatch(getApplicationStatusThunk(accessToken));
    }
  }, [dispatch]);

  const submitApplication = (
    request: DriverRegistrationStepOneRequest | DriverRegistrationStepTwoRequest
  ) => {
    if (
      window.confirm("Have you verified that all the information is correct?")
    ) {
      if (!!accessToken) {
        dispatch(
          submitApplicationThunk({
            accessToken,
            driver: request,
          })
        );
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingComponent />}
      {!isLoading && (
        <>
          {page == 0 && (
            <DriverRegistrationStepOne submitApplication={submitApplication} />
          )}
          {page == 1 && (
            <DriverRegistrationStepTwo
              submitApplication={submitApplication}
              goBack={() => setPage((page) => page - 1)}
            />
          )}
          {page == 2 && <RegistrationSuccess isHomeOwner={false} />}
        </>
      )}
    </>
  );
};

export default WithAuthWrapper(DriverRegister);
