import { CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");

const StripeCallback: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <Callback />
    </Elements>
  );
};

const Callback: React.FC = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<string>("success");

  const formatUnixTime = (unixTime) => {
    const date = new Date(unixTime * 1000); // convert Unix time to milliseconds
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // add leading zero if needed
    const day = ("0" + date.getDate()).slice(-2); // add leading zero if needed
    const hours = ("0" + date.getHours()).slice(-2); // add leading zero if needed
    const minutes = ("0" + date.getMinutes()).slice(-2); // add leading zero if needed
    const seconds = ("0" + date.getSeconds()).slice(-2); // add leading zero if needed
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const searchParams = new URLSearchParams(window.location.search);
  const startDate = searchParams.get("start_date");
  const endDate = searchParams.get("end_date");
  const amount = searchParams.get("amount");
  const paymentTime = formatUnixTime(searchParams.get("time"));

  return (
    <div
      className="maincontainer"
      style={{ overflow: "auto", paddingBottom: "10%" }}
    >
      <div className="container" style={{ padding: "30px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            marginBottom: "8%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardMedia
            sx={{
              objectFit: "contain",
              width: "150px",
              height: "150px",
              backgroundColor: "white",
              borderRadius: 2,
            }}
            image={
              status === "success"
                ? require("../../assets/icons/payment/confirmation tick_.png")
                : require("../../assets/icons/payment/rejected.png")
            }
            title=""
          />
          <h3
            className="card-title text-center"
            style={{
              color: "#df479e",
              fontFamily: "Fredoka One",
              width: "50%",
              marginTop: "30px",
            }}
          >
            {status == "success"
              ? "payment successful!"
              : "payment unsuccessful!"}
          </h3>
        </div>

        {status === "success" && (
          <div className="" style={{ width: "100%", backgroundColor: "white" }}>
            <div className="row">
              <div className="col-6">
                <div
                  className=""
                  style={{
                    fontSize: "1em",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#e0479e",
                    fontWeight: "500",
                  }}
                >
                  Payment Amount
                </div>
              </div>
              <div className="col-6" style={{}}>
                <div
                  className="text-right"
                  style={{
                    fontSize: "0.9em",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#575252",
                    fontWeight: "600",
                  }}
                >
                  RM {amount}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div
                  className=""
                  style={{
                    fontSize: "1em",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#e0479e",
                    fontWeight: "500",
                  }}
                >
                  Payment Method
                </div>
              </div>
              <div className="col-6" style={{}}>
                <div
                  className="text-right"
                  style={{
                    fontSize: "0.9em",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#575252",
                    fontWeight: "700",
                  }}
                >
                  Credit Card / Debit Card
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div
                  className=""
                  style={{
                    fontSize: "1em",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#e0479e",
                    fontWeight: "500",
                  }}
                >
                  Payment Time
                </div>
              </div>
              {/* <div className="col-3" style={{}}>
                              <div className="text-right" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: '#655ba7', fontWeight: '600' }}>
                                  01/03/2023
                              </div>
                          </div> */}
              <div className="col-6" style={{}}>
                <div
                  className="text-right"
                  style={{
                    fontSize: "0.9em",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    color: "#575252",
                    fontWeight: "600",
                  }}
                >
                  {paymentTime}
                </div>
              </div>
            </div>

            <br />
            <br />

            <p
              style={{
                textAlign: "center",
                color: "#575252",
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              Your booking from {startDate} to {endDate} has been confirmed.
            </p>
            <h4
              className="card-title text-center"
              style={{ color: "#575252", fontWeight: 600, fontSize: "12px" }}
            >
              Thank You for choosing our app. Enjoy a hassle-free parking
              experience. Have a great day!
            </h4>

            <div className="form-group">
              <button
                type="button"
                onClick={() => navigate("/home")}
                className="btn btn-primary btn-block"
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#e0479E",
                  fontSize: "20px",
                  padding: "10px",
                  fontFamily: "Fredoka One",
                  border: "none",
                  marginTop: "20%",
                }}
              >
                {" "}
                done{" "}
              </button>
            </div>
          </div>
        )}
        {status == "failed" && (
          <>
            <p style={{ textAlign: "center" }}>
              Unfortunately, we are unable to process your payment. Please try
              again.
            </p>
            <div className="form-group" style={{ marginTop: 100 }}>
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#e0479E",
                  fontSize: "1em",
                  padding: "10px",
                  fontFamily: "Fredoka One",
                  border: "none",
                }}
              >
                try again
              </button>
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#d5d5d5",
                  fontSize: "1em",
                  padding: "10px",
                  fontFamily: "Fredoka One",
                  border: "none",
                }}
              >
                cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StripeCallback;
