import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@mui/material/ListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Divider } from "@material-ui/core";
import MDTypography from "components/MDTypography";
import { AllBookingDetails } from "models/BookingStatus";
import { useSelector } from "react-redux";
import { getIsApprovedHomeOwner } from "redux/slices/authenticationSlices";
import { useNavigate } from "react-router-dom";

type Props = {
  bookings: AllBookingDetails[];
  title: string;
};

const BookingItem: React.FC<Props> = (props) => {
  const { bookings, title } = props;
  const isApprovedHomeOwner = useSelector(getIsApprovedHomeOwner);
  const navigate = useNavigate();

  const getDateDescription = (details: AllBookingDetails): string => {
    switch (title) {
      case "active":
        return `Ends at ${new Date(details.end_date).toDateString()}`;
      case "upcoming":
        return `Stars at ${new Date(details.start_date).toDateString()}`;
      case "ended":
        return `Ends at ${new Date(details.end_date).toDateString()}`;
      default:
        return `Cancelled at ${new Date(details.created_at).toDateString()}`;
    }
  };

  return (
    <List style={{ padding: 0 }}>
      {bookings.length <= 0 ? (
        <span
          style={{
            padding: "40px",
            color: "#e0479e",
          }}
        >
          -
        </span>
      ) : (
        <>
          {bookings.map((booking) => (
            <div key={booking.id}>
              <Divider />
              <ListItem
                sx={{
                  padding: "20px 20px",
                  paddingLeft: "35px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "grid" }}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    sx={{
                      fontFamily: "Roboto !important",
                      textTransform: "normal",
                      color: "#e0479e",
                    }}
                  >
                    {`Booking ID: ${booking.id}`}
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    sx={{
                      fontFamily: "Fredoka One",
                      color: "#e0479e",
                    }}
                  >
                    {isApprovedHomeOwner
                      ? booking.plate_number
                      : booking.street}
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{
                      fontFamily: "Roboto !important",
                      textTransform: "normal",
                      color: "#e0479e",
                    }}
                  >
                    {getDateDescription(booking)}
                  </MDTypography>
                </div>
                <FontAwesomeIcon
                  icon={faSearch}
                  onClick={() => {
                    navigate("/booking-details", {
                      state: {
                        title: title,
                        booking: booking,
                      },
                    });
                  }}
                  style={{
                    color: "#e0479e",
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    borderRadius: "60%",
                    border: "solid 1px #e0479e",
                    padding: "4px",
                  }}
                />
              </ListItem>
            </div>
          ))}
        </>
      )}
    </List>
  );
};

export default BookingItem;
