import { FormValues } from "./index";

export const validateForm = (values: FormValues) => {
  let errors: any = {};
  const regexPhone = /^\d{10,11}$/;
  if (!values.full_name) {
    errors.full_name = "Full Name is required.";
  } else if (!/^[a-zA-Z\s]+$/.test(values.full_name)) {
    errors.full_name =
      "Name can only contain alphabetical characters and spaces";
  }

  if (!values.ic_number) {
    errors.ic_number = "IC Number is required.";
  } else if (!/^[0-9]{12}$/.test(values.ic_number)) {
    errors.ic_number =
      "Please ensure that your IC Number contains exactly 12 digits";
  }

  if (!values.gender) {
    errors.gender = "Gender is required";
  }

  if (!values.emergency_contact_name) {
    errors.emergency_contact_name = "Please fill this field";
  } else if (!/^[a-zA-Z\s]+$/.test(values.emergency_contact_name)) {
    errors.emergency_contact_name =
      "Name can only contain alphabetical characters and spaces";
  }

  if (!values.emergency_contact_relationship) {
    errors.emergency_contact_relationship = "Please fill this field";
  }

  if (!values.emergency_contact_num) {
    errors.emergency_contact_num = "Please fill this field";
  } else if (!regexPhone.test(values.emergency_contact_num)) {
    errors.emergency_contact_num =
      "Please enter a 9 or 10-digit mobile number without spaces or special characters";
  } else if (
    [
      "0000000000",
      "1111111111",
      "0111111111",
      "0123456789",
      "9876543210"
    ].indexOf(values.emergency_contact_num) !== -1
  ) {
    errors.emergency_contact_num = "Please provide a valid mobile number";
  }

  if (!values.ic_front_url) {
    errors.ic_front_url = "Please take a picture of your Malaysian IC (front)";
  } else if (!values.ic_back_url) {
    errors.ic_back_url = "Please take a picture of your Malaysian IC (back)";
  } else if (!values.driver_license_front_url) {
    errors.driver_license_front_url =
      "Please take a picture of your License (front)";
  } else if (!values.driver_license_back_url) {
    errors.driver_license_back_url =
      "Please take a picture of your License (back)";
  } else if (!values.selfie_url) {
    errors.selfie_url = "Please take a picture of your Selfie";
  }

  return errors;
};
