import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import { LoginRequest } from "models/User";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsApprovedDriver,
  getIsApprovedHomeOwner,
  getIsAuthenticatedStatus,
  getIsLogout,
  getIsRegisteringDriver,
  getIsRegisteringHomeOwner,
  getLoadingStatus,
  getLoginFailed,
  getResetPasswordEmailSent,
  loginRequest,
  setLoginFailed,
  setResetPasswordEmailSent,
} from "redux/slices/authenticationSlices";
import * as Styled from "./SignIn.styled";
import { Alert, Snackbar } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PasswordField from "components/PasswordField";
import { validateForm } from "./validateForm";
import ForgotPasswordModal from "components/ForgotPasswordModal";
import login from "assets/json/login.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Logo from "assets/images/logo-park.png";

export interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: "",
  password: "",
};

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = React.useRef<any>(null);
  const isAuthenticated = useSelector(getIsAuthenticatedStatus);
  const isApprovedDriver = useSelector(getIsApprovedDriver);
  const isApprovedHomeOwner = useSelector(getIsApprovedHomeOwner);
  const isRegisteringDriver = useSelector(getIsRegisteringDriver);
  const isRegisteringHomeOwner = useSelector(getIsRegisteringHomeOwner);
  const isLoading = useSelector(getLoadingStatus);
  const loginFailed = useSelector(getLoginFailed);
  const resetPasswordEmailSent = useSelector(getResetPasswordEmailSent);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const isLogout = useSelector(getIsLogout);

  useEffect(() => {
    if (isAuthenticated) {
      if (isApprovedDriver || isApprovedHomeOwner) {
        navigate("/home");
      } else if (isRegisteringDriver) {
        navigate("/driver/register");
      } else if (isRegisteringHomeOwner) {
        navigate("/homeowner/register");
      } else {
        navigate("/intro");
      }
    }
  }, [
    isAuthenticated,
    isApprovedDriver,
    isApprovedHomeOwner,
    isRegisteringDriver,
    isRegisteringHomeOwner,
    navigate,
  ]);

  useEffect(() => {
    if (isLogout) {
      window.location.reload();
    }
  }, [isLogout]);

  const onSubmit = (values: FormValues) => {
    const { email, password } = values;
    if (email !== "" && password !== "" && !!email && !!password) {
      const request: LoginRequest = {
        ...values,
      };
      dispatch(loginRequest(request));
    }
  };

  return (
    <Styled.MainContainer>
      {showResetPassword && (
        <ForgotPasswordModal onClose={() => setShowResetPassword(false)} />
      )}
      <Styled.TopSection>
        <Snackbar
          open={loginFailed}
          autoHideDuration={2000}
          onClose={() => {
            dispatch(setLoginFailed(false));
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            User not found or password mismatched.
          </Alert>
        </Snackbar>
        <Snackbar
          open={resetPasswordEmailSent === true}
          autoHideDuration={2000}
          onClose={() => {
            dispatch(setResetPasswordEmailSent(null));
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Email sent!
          </Alert>
        </Snackbar>
        <Snackbar
          open={resetPasswordEmailSent === false}
          autoHideDuration={2000}
          onClose={() => {
            dispatch(setResetPasswordEmailSent(null));
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong!
          </Alert>
        </Snackbar>
        <img src={Logo} />
        <Player
          autoplay
          loop
          src={login}
          style={{ height: "250px", width: "250px" }}
        />

        <Styled.Spacer height={20} />
        <Styled.Title>
          parking, one tap
          <br />
          away
        </Styled.Title>
        <Styled.Spacer height={20} />
        <Styled.FormHolder>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formikRef}
            validate={validateForm}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, handleBlur, handleChange, setFieldValue, errors }) => (
              <FormikForm style={{ width: "100%" }}>
                <Styled.Field
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  highlighted={!!values.email}
                  error={errors.email != null}
                  value={values.email}
                  type="text"
                  placeholder="Email"
                />
                <Styled.FieldErrorMessage>
                  <ErrorMessage name="email" />
                </Styled.FieldErrorMessage>
                <Styled.Spacer height={20} />
                <PasswordField
                  name="password"
                  placeholder="Password"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  value={values.password}
                  error={errors.password}
                  showing={showPassword}
                  setShowing={() => setShowPassword((show) => !show)}
                />
                <Styled.FieldErrorMessage>
                  <ErrorMessage name="password" />
                </Styled.FieldErrorMessage>
                <Styled.Spacer height={40} />
                <Styled.Button type="submit" disabled={isLoading}>
                  {isLoading ? "loading..." : "login"}
                </Styled.Button>
                <Styled.Spacer height={10} />
              </FormikForm>
            )}
          </Formik>
          <Styled.ForgotPasswordLink onClick={() => setShowResetPassword(true)}>
            forgot password?
          </Styled.ForgotPasswordLink>
        </Styled.FormHolder>
      </Styled.TopSection>
      <Styled.SignUpText>
        Don't have an account?{" "}
        <Link to="/signup">
          <Styled.SignUpLink>sign up</Styled.SignUpLink>
        </Link>
      </Styled.SignUpText>
    </Styled.MainContainer>
  );
};

export default SignIn;
