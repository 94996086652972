import styled from "styled-components";
import screenSize from "styles/mediaQueries";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  align-items: center;
  height: 100vh;
  justify-content: space-between;
  ${screenSize.minTablet`

    margin: auto;
      width: 60%;
      `}
`;

export const Title = styled.h3`
  color: red;
  font-family: "Fredoka One";
  text-align: center;
  width: 100%;
  color: #e0479e;
`;

export const MidSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SuccessTitle = styled.h6`
  color: red;
  font-family: "Fredoka One";
  text-align: center;
  width: 100%;
  color: #e0479e;
`;

export const SuccessMessage = styled.p`
  font-size: 0.8em;
  color: #e0479e;
  line-height: 25px;
  text-align: center;
  margin: 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterMessage = styled.p`
  font-size: 0.7em;
  margin: 0;
`;

export const FooterLink = styled.p`
  font-size: 0.7em;
  color: #e0479e;
  margin: 0;
  font-family: "Fredoka One";
`;

type SpacerProps = {
  height: number;
};
export const Spacer = styled.div<SpacerProps>`
  height: ${(props) => `${props.height}px`};
`;
