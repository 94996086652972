import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgetAccountPassword, getLoadingResetPasswordStatus, getResetPasswordEmailSent } from "redux/slices/authenticationSlices";
import * as Styled from "./ForgotPasswordModal.styled";

type ForgotPasswordModalProps = {
    onClose: () => void;
};
const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = props => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const isLoading = useSelector(getLoadingResetPasswordStatus);
    const resetPasswordEmailSent = useSelector(getResetPasswordEmailSent);

    useEffect(() => {
        if (!isLoading && resetPasswordEmailSent !== null) {
            props.onClose();
        }
    }, [isLoading, resetPasswordEmailSent]);

    const handleSubmit = () => {
        setError("");

        if (!email) {
            setError("Email address is required");
            return;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            setError("Invalid email format. Please enter a valid email address (e.g. example@email.com).");
            return;
        }

        dispatch(forgetAccountPassword(email));
        
    };

    return (
        <Styled.MainWrapper>
            <Styled.MainContainer>
                <Styled.TopHolder>
                    <div></div>
                    <Styled.Title>reset password</Styled.Title>
                    <div></div>
                    <FontAwesomeIcon icon={faClose} color="#bbb" style={{position: "absolute", right: 10}} onClick={props.onClose} />
                </Styled.TopHolder>
                <Styled.SizedBox height={20} />
                <Styled.Field 
                    type="text" 
                    placeholder="Email Address"
                    highlighted={!!email}
                    error={!!error}
                    onChange={e => setEmail(e.target.value)}
                />
                <Styled.SizedBox height={5} />
                <Styled.FieldErrorMessage>{error}</Styled.FieldErrorMessage>
                <Styled.SizedBox height={20} />
                <Styled.Button onClick={handleSubmit} disabled={isLoading} >{isLoading ? "loading..." : "ok"}</Styled.Button>
            </Styled.MainContainer>
        </Styled.MainWrapper>
    );
};

export default ForgotPasswordModal;