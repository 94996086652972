import React from "react";
import * as Styled from "./FilterListComponent.styled";
import { GeoObjectDetail } from "models/HomeState";
import Typography from "@material-ui/core/Typography";
import { MIN_TABLET } from "styles/mediaQueries";
import useScreenSize from "hooks/useScreenSize";
import { useDispatch } from "react-redux";
import { setSelectedGeoObject, setViewPort } from "redux/slices/homeSlices";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

type Props = {
  location: GeoObjectDetail;
  openDetails: () => void;
};
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px",
    width: 500,
    position: "relative",
    marginTop: 20,
    marginBottom: 65,
  },
  card: {
    maxWidth: 360,
    position: "relative",
    borderRadius: 20,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "57%",
    margin: 0,
    justifyContent: "left",
    alignItems: "left",
    padding: 10,
    border: "1px solid #F0F1F5",
    borderRadius: 20,
    boxShadow: "10px 5px 5px rgb(0,0,0, 0.4)",
    background: "#fff",
    position: "absolute",
    bottom: -45,
    left: "7%",
    "@media print, screen and (min-width: 768px)": {
      left: "13%",
    },
  },
  content: {
    flex: "1 0 auto",
    padding: 0,
  },
  cover: {
    width: 500,
    height: 200,
    objectFit: "cover",
  },
  ribbon: {
    backgroundColor: "#e0479e",
    position: "absolute",
    color: "white",
    width: 150,
    zIndex: 3,
    textAlign: "center",
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,

    "&::before": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    "&::after": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    transform: "rotate(-45deg)",
    top: 0,
    left: 0,
    marginLeft: -35,
  },
  span: {
    marginLeft: -25,
    textAlgin: "left",
    fontWeight: 200,
    fontFamily: "Fredoka One",
  },
}));
const CardComponent: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { location, openDetails } = props;
  const [screenWidth] = useScreenSize();
  const dispatch = useDispatch();

  const handlePopUp = () => {
    if (screenWidth >= MIN_TABLET) {
      // dispatch(
      //   setViewPort({
      //     longitude: Number(location.longitude),
      //     latitude: Number(location.latitude),
      //     zoom: 14,
      //     transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
      //   })
      // );
    }
    dispatch(setSelectedGeoObject(location));
  };

  const restructureAddress = () => {
    if (location) {
      var firstIndexWithComma = location.address.indexOf(",");
      var arr = [
        location.address.slice(0, firstIndexWithComma),
        location.address.slice(firstIndexWithComma + 1),
      ];

      if (/\d/.test(arr[0])) {
        // Remove unit no
        return location.address.replace(/.*?,/, "");
      } else {
        return location.address;
      }
    }
    return "";
  };

  return (
    <Styled.CardContainer>
      <div className={classes.root}>
        <Card
          className={classes.card}
          onClick={() => {
            handlePopUp();
            openDetails();
          }}
          style={{
            cursor: "pointer",
            minWidth: screenWidth >= MIN_TABLET ? "435px" : "auto",
          }}
        >
          <CardContent className={classes.content}>
            <div className={classes.ribbon}>
              <span className={classes.span}>{`RM ${location.price}`}</span>
            </div>
          </CardContent>

          <CardMedia
            className={classes.cover}
            image={location.home_picture_url}
            title={location.title}
          />
        </Card>
        <div
          className={classes.details}
          onClick={() => {
            handlePopUp();
            openDetails();
          }}
        >
          <p
            style={{
              color: "#575252",
              fontSize: 15,
              fontWeight: "bold",
              marginBottom: 2,
              marginLeft: 5,
            }}
          >
            {location.street.toLowerCase()}:{restructureAddress().toLowerCase()}
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                color: "#575252",
                fontSize: 10,
                textAlign: "left",
                marginBottom: 2,
                marginLeft: 5,
              }}
            >
              {location.start_date ?? "dd/mm"} - {location.end_date ?? "dd/mm"}
            </p>
            <button
              style={{
                width: "100px",
                padding: 5,
                border: "none",
                fontFamily: "Fredoka One",
                color: "#fff",
                borderRadius: "20px",
                backgroundColor: "#e0479e",
                float: "right",
                fontSize: 12,
              }}
            >
              book
            </button>
          </div>
        </div>
      </div>
    </Styled.CardContainer>
  );
};
export default CardComponent;
