import LoadingComponent from "components/LoadingComponent";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getIsAuthenticatedStatus,
  getLoginFailed,
  getUserDetails,
  logout,
  validateAuthentication,
} from "redux/slices/authenticationSlices";
import UserContext from "./usePageContext";

const WithAuthWrapper = <
  T extends JSX.IntrinsicAttributes & { children?: ReactNode }
>(
  WrappedComponent: React.ComponentType<T>
): React.FC<T> => {
  const Layout = (props: T) => {
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const user = useSelector(getUserDetails);
    const loginFailed = useSelector(getLoginFailed);
    const accessToken = localStorage.getItem("carching-token");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      // Initialse
      if (!!accessToken) {
        dispatch(validateAuthentication(accessToken));
      } else {
        dispatch(logout());
        navigate("/");
      }
    }, []);

    useEffect(() => {
      // Validation
      if (!!loginFailed) {
        dispatch(logout());
        navigate("/");
      }
    }, []);

    return (
      <React.Fragment>
        {!isAuthenticated && <LoadingComponent />}
        {isAuthenticated && !!accessToken && user && (
          <UserContext.Provider
            value={{
              user: user,
            }}
          >
            <WrappedComponent {...props} />
          </UserContext.Provider>
        )}
      </React.Fragment>
    );
  };

  return Layout;
};

export default WithAuthWrapper;
