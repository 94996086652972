import styled from "styled-components";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import screenSize from "styles/mediaQueries";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Avatar } from "@mui/material";

export const FilterMainComponent = styled.div`
  background: #ffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #232a3d;
  height: 100%;
`;
export const HeaderTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  font-family: Fredoka One;
  margin: 0 1px;
  ${screenSize.minTablet`
	margin: 0;
	font-size: 20px;
  text-align: center;
	`}
`;
export const Link = styled.a`
  color: #e0479e;
  text-decoration: underline;
`;
export const CreateButton = styled(Button)`
  &&& {
    color: white;
    background-color: #232a3d;
    border-radius: 6px;
    height: 36px;
    padding: 0 10px;
    position: absolute;
    right: 10px;
    ${screenSize.minTablet`
		position: initial;
		`}
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  height: 80px;
  ${screenSize.minTablet`
	padding: 1rem 1rem 0 1rem;
	height: 120px;
  width: 100%;
	`}
`;
export const LabelContainer = styled.div`
  margin-top: 1px;
  margin-bottom: 1px;
`;
export const Label = styled.p`
  color: #abb2c4;
  font-weight: 500;
  font-size: 10px;
  text-align: left;
  font-family: Fredoka One;
  margin: 0;
`;
export const ContentLabel = styled.p`
  color: #e0479e;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  margin: 5px 0 15px 0;
  font-family: Fredoka One;
`;

export const FullWidthContainer = styled.div`
  width: 100%;
  display: block;
`;

export const FilterContainer = styled.div`
  display: block;
  width: 100%;
`;

export const DropdonwInput = styled(FormControl)`
  &&& {
    color: #e1e6f2;

    &:focus: {
      border-color: #e1e6f2;
    }

    .MuiInputBase-root {
      margin: auto;
      width: 100%;
    }

    .MuiSelect-outlined {
      padding: 6px 32px 6px 6px;
      background: #f0f3fa;
      border: 1px solid #e1e6f2;
      &:focus: {
        border: 1px solid #e1e6f2;
      }
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      display: none;
    }
  }
`;
export const OutlinedInputFiled = styled(OutlinedInput)`
  .Mui-focused {
    background: pink;
  }
  &&& {
    .MuiOutlinedInput-input {
      padding: 6px 32px 6px 6px;

      &:focus: {
        border: 1px solid #e0479e;
      }
    }
    .MuiInputLabel-outlined {
      border: 1px solid #e0479e;
    }
    .Mui-focused {
      background: pink;
    }
  }
`;
export const DropdonwInputMobile = styled(FormControl)`
  &&& {
    color: #e1e6f2;
    display: block;
    margin-top: 20px;
    margin-top: 2rem;
    height: 30px;
    &:focus: {
      border-color: #e1e6f2;
    }

    .MuiInputBase-root {
      margin: auto;
      width: 100%;
    }
    .MuiSelect-selectMenu {
      min-height: 30px;
    }
    .MuiSelect-outlined {
      padding: 6px 32px 6px 6px;
      background: #f0f3fa;
      border: 1px solid #e1e6f2;
      &:focus: {
        border: 1px solid #e1e6f2;
      }
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      display: none;
    }
  }
`;
export const HelpCountContainer = styled.div`
  display: block;
  width: 100%;
`;
export const SortContainer = styled.div`
	width: 100%;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
	display: block;
	justify-content: center;
	margin-bottom: 1rem;
	margin-top 1rem;
	margin: auto;
	align-items: center;
	padding-bottom: 10px;
	padding-top: 1rem;
`;

export const SortInnerContainer = styled.div`
  width: 90%;
  margin: auto;
`;
export const SortInnerContainerMobile = styled.div`
  width: 90%;
  margin: auto;
  display: block;
`;
export const TabContainer = styled(Tabs)`
  &&& {
    border-bottom: 1px solid #f0f0f0;
    height: 2rem;
  }
`;

export const TabItems = styled(Tab)`
  &&& {
    text-transform: inherit;
    font-weight: 600;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 1rem;
  display: block;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const TotalCount = styled.p`
  font-size: 36px;
  font-weight: 700;
  margin: 0;
`;
export const HelpedText = styled.p`
  font-size: 12px;
  color: #232a3d;
  font-weight: 600;
  margin: 0;
`;

export const PeopleText = styled.p`
  font-size: 10px;
  color: #abb2c4;
  font-weight: 600;
  margin: 0;
`;
export const SelectInput = styled(Select)`
  &&& {
    background: #f0f3fa;
    color: #838a9e;
    min-width: 140px;
    font-size: normal;
  }
  &:focus: {
    border-color: #e1e6f2;
  }
`;
export const SaveButton = styled(Button)`
  &&& {
    background: #232a3d;
    margin-top: 2rem;
    width: 100%;
    text-transform: capitalize;
    height: 45px;
  }
`;

export const InputLabelSelect = styled(InputLabel)`
  &&& {
    transform: translate(10%, 50%) scale(1);
    font-weight: 400;
  }
`;
export const LogoutButton = styled(ExitToAppIcon)`
  float: right;
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  right: 0;
  ${screenSize.minTablet`
	position: initial;
	`};
`;

export const Footer = styled.footer`
  background: white;
  color: #838a9e;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
`;

export const CardScrollbar = styled.div`
  height: 80%;

  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #838a9e;
    outline: 1px solid slategrey;
  }
  ${screenSize.minTablet`
	overflow: auto;
	overflow-x: hidden;
	`}
`;
type SeeInMapaLinkProp = {
  type: string;
};
export const SeeInMapLink = styled.a<SeeInMapaLinkProp>`
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => (props.type === "Helper" ? "#2d65fc" : "#f86a36")};
`;
export const Bluelogo = styled.img`
  width: 80%;
  margin: auto;
  ${screenSize.minTablet`
  width: 100%;
	`};
`;
export const KebabIcon = styled.img`
  height: 1.5em;
  width: 1.5em;
  margin-right: 2rem;
  margin-left: 2rem;
  cursor: pointer;
`;
export const MobileFilterListContainer = styled.div`
  width: 80%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 200;
  background: white;

  ${screenSize.minTablet`
  width: 30%;
	`}
`;

export const MobileFilterFooter = styled.footer`
  height: 80px;
  width: 80%;
  border-top: 1px solid #f0f0f0;
  position: fixed;
  bottom: 0;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: #838a9e;
  background: #fff;
  ${screenSize.minTablet`
  width: 30%;
	`}
`;

export const CancelShareButton = styled(Button)`
  &&& {
    width: 100%;
    color: white;
    background-color: #232a3d;
    border-radius: 8px;
    height: 45px;
    padding: 0 10px;
    margin-bottom: 1rem;
    font-weight: bold;
    text-transform: capitalize;
  }
`;
export const ShareButton = styled(Button)`
  &&& {
    width: 100%;
    color: #232a3d;
    background-color: white;
    border-radius: 8px;
    height: 45px;
    padding: 0 10px;
    margin-bottom: 1rem;
    font-weight: bold;
    text-transform: capitalize;
  }
`;
export const ShareContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 90%;
  display: block;
  justify-content: center;
  align-items: center;
`;

export const AccountLogo = styled(Avatar)`
  &&& .MuiAvatar-img {
    height: 100%;
  }
`;
export const SideBarHeaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  height: 100px;
  ${screenSize.minTablet`
	padding: 1rem 1rem 0 1rem;
	height: 120px;
	`}
`;

export const CardContainer = styled.div`
  ${screenSize.minTablet`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    justify-items: center;
  `}
`;
