// react-router-dom components
import { Link } from "react-router-dom";
import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { faMapLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ExploreOwner() {

    return (
        <div className="maincontainer" style={{overflow: 'auto',  paddingBottom: '10%' }}>
            <div class="container" style={{ padding: '20px', width: '100%', backgroundColor: 'lightGrey',}}>
                <h3 class="card-title mt-3 text-center" style={{ color: "#ff69b4", fontFamily: "Fredoka One", paddingBottom: "5%", opacity: '50%' }}>explore</h3>


                <div class='' style={{ width: '100%', backgroundColor: 'white' }}>
                    <CardMedia
                        sx={{ height: 250, backgroundColor: 'black', borderRadius: 2, }}
                        image=""
                        title=""
                    />
                    <div class="text-center" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '30px', paddingTop: '30px', color: 'grey' }}>
                        Parking Name
                    </div>
                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <div class="text-center" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '30px', paddingTop: '30px' }}>
                        123B, Jalan Aminuddin Baki, Taman Tun Dr Ismail, 60000, Kuala Lumpur, Malaysia
                    </div>
                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <div class="text-center" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '30px', paddingTop: '30px', color: 'grey' }}>
                        Listing Start Date
                    </div>
                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <div class="text-center" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '30px', paddingTop: '30px' }}>
                        RM 123
                    </div>
                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <div class="text-center" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '30px', paddingTop: '30px' }}>
                        <FontAwesomeIcon icon={faMapLocation} style={{ paddingRight: '5px' }}></FontAwesomeIcon>
                        Show on Map
                    </div>
                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <br/>
                    <br/>
                    <br/>
                    <div class="form-group" style={{padding: '10px'}}>
                        <button type="button" class="btn btn-primary btn-block" style={{borderRadius: "20px", backgroundColor:"lightGrey", borderColor:"lightGrey", fontSize: '20px', padding: '10px'}}> submit </button>
                    </div>   
                </div>

            </div>
        </div>
    );
}

export default ExploreOwner;
