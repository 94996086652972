import React from "react";
import * as Styled from "./FilterListComponent.styled";
import { GeoObjectDetail, HomePosting } from "models/HomeState";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
type Props = {
  posting: HomePosting;
  openDetails: () => void;
};
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px",
    width: 500,
    position: "relative",
    marginTop: 20,
    marginBottom: 65,
    "@media print, screen and (min-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  card: {
    maxWidth: 360,
    position: "relative",
    borderRadius: 20,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "57%",
    margin: 0,
    justifyContent: "left",
    alignItems: "left",
    padding: 10,
    border: "1px solid #F0F1F5",
    borderRadius: 20,
    boxShadow: "10px 5px 5px rgb(0,0,0, 0.4)",
    background: "#fff",
    position: "absolute",
    bottom: -45,
    left: "7%",
    "@media print, screen and (min-width: 768px)": {
      left: "21%",
    },
  },
  content: {
    flex: "1 0 auto",
    padding: 0,
  },
  cover: {
    width: 500,
    height: 200,
    objectFit: "cover",
  },
  span: {
    marginLeft: -25,
    textAlgin: "left",
    fontWeight: 200,
    fontFamily: "Fredoka One",
  },
}));
const PostCardComponent: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { posting, openDetails } = props;
  return (
    <div className={classes.root}>
      <Card
        className={classes.card}
        style={{ cursor: "pointer" }}
        onClick={() => {
          openDetails();
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 10,
            top: 8,
          }}
        >
          <div
            style={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              background: `${
                posting.status === "Cancelled"
                  ? "#575252"
                  : posting.status === "Available for booking"
                  ? "#1ed841"
                  : "#e0479e"
              }`,
              zIndex: 3,
            }}
          />
        </div>

        <CardMedia
          className={classes.cover}
          image={posting.home_picture_url}
          title={posting.title}
        />
      </Card>
      <div
        className={classes.details}
        onClick={() => {
          openDetails();
        }}
      >
        <p
          style={{
            color: "#575252",
            fontSize: 15,
            fontWeight: "bold",
            marginBottom: 2,
            marginLeft: 5,
          }}
        >
          {posting.street.toLowerCase()}
        </p>
        <p
          style={{
            color: "#575252",
            fontSize: 10,
            textAlign: "left",
            marginBottom: 2,
            marginLeft: 5,
          }}
        >
          Status: {posting.status}
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{
              color: "#575252",
              fontSize: 10,
              textAlign: "left",
              marginBottom: 2,
              marginLeft: 5,
            }}
          >
            {posting.start_date ?? "dd/mm"} - {posting.end_date ?? "dd/mm"}
          </p>
          <IconButton
            sx={{
              float: "right",
              padding: 0,
            }}
          >
            <EditIcon
              sx={{
                color: "#e0479e",
              }}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
export default PostCardComponent;
