import React, { useEffect, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import Cross24 from "../../assets/Cross24";
import * as Styled from "./DetailsModal.styled";
import { Formik, Form as FormikForm } from "formik";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  createHomePostingsThunk,
  getAllHousePostingsThunk,
  getLoadingStatus,
  setLoadingStatus
} from "redux/slices/homeSlices";
import {
  getCurrentHomeOwnerDetails,
  getCurrentHomeOwnerThunk
} from "redux/slices/homeOwnerSlices";
import SelectInput from "components/MDInput/SelectInput";
import { HomePosting } from "models/HomeState";
import { deleteHousePosting } from "services/api/homeApi";
import { getIsPendingHomeOwner } from "redux/slices/authenticationSlices";
import {
  MobileDatePicker,
  PickersDay,
  PickersDayProps
} from "@mui/x-date-pickers";
import { makeStyles } from "@material-ui/core/styles";
import dayJs, { Dayjs } from "dayjs";
import { getProfile, getProfileThunk } from "redux/slices/accountSlices";
import moment from "moment";

const initialValues = {
  month: 1,
  start_date: ""
};
type Prop = {
  isOpen: boolean;
  handleClose: () => void;
  isView?: boolean;
  postingDetail?: HomePosting;
};

const useStyles = makeStyles((theme) => ({
  styleOverrides: {
    "&&&": {
      "& .MuiOutlinedInput-input ": {
        textAlign: "center"
      }
    }
  }
}));
const CreatePostingModal: React.FC<Prop> = (props) => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const { isOpen, handleClose, isView, postingDetail } = props;
  const formikRef = useRef<any>(null);
  const dispatch = useDispatch();
  const currentHomeOwner = useSelector(getCurrentHomeOwnerDetails);
  const isLoading = useSelector(getLoadingStatus);
  const isPendingHomeOwner = useSelector(getIsPendingHomeOwner);
  const profileDetails = useSelector(getProfile);
  const classes = useStyles();
  useEffect(() => {
    dispatch(getProfileThunk(accessToken));
    dispatch(getCurrentHomeOwnerThunk(accessToken));
  }, []);

  const getEndDate = (startDate: string, months: number): string => {
    if (startDate) {
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + months + 1);
      endDate.setDate(endDate.getDate() - 1);
      return moment(endDate).format("DD/MM/YYYY");
    }
    return "";
  };

  const onSubmit = (values) => {
    console.log(values);
    if (isPendingHomeOwner) {
      alert(
        "We're thrilled that you're interested in this amazing feature! But before you can use it, we need to give your account the thumbs up. Our team is hard at work reviewing your application, and we promise to let you know as soon as you're all set to go. Thanks for your patience and keep on hosting!"
      );
    } else {
      const { start_date, month } = values;
      if (start_date === "") {
        alert("Start date field is required!");
      } else if (month === "") {
        alert("Month field is required!");
      } else {
        if (
          window.confirm(
            "Note that the dates you have selected show when your property will be listed and shown to drivers for booking. There is a minimum booking period of one month so your property parking space may need to be available until one month after the end date specified here. If you have any questions or concerns, please don't hesitate to contact us."
          )
        ) {
          dispatch(
            createHomePostingsThunk({
              accessToken,
              posting: values
            })
          );
          handleClose();
        }
      }
    }
  };

  const onCancel = async () => {
    if (postingDetail) {
      dispatch(setLoadingStatus(true));
      try {
        await deleteHousePosting(postingDetail.id, accessToken);
      } catch (e) {
        alert("Something went wrong! Please try again later.");
      }
      dispatch(getAllHousePostingsThunk(accessToken));
      dispatch(setLoadingStatus(false));

      handleClose();
    }
  };

  const customDay = (
    props: PickersDayProps<Dayjs> & { disabledDays?: string[] }
  ) => {
    const { disabledDays, day, ...other } = props;

    var isDisabled = false;

    if ((disabledDays ?? []).indexOf(day.format("YYYY-MM-DD")) >= 0) {
      isDisabled = true;
    } else if (day.isBefore(dayJs(new Date()), "date")) {
      isDisabled = true;
    } else if (
      day.isAfter(dayJs(new Date().setMonth(new Date().getMonth() + 3)), "date")
    ) {
      isDisabled = true;
    }

    return <PickersDay {...other} day={day} disabled={isDisabled} />;
  };
  const getImageContent = (): string => {
    if (postingDetail) {
      return postingDetail.home_picture_url;
    }
    if (Object.keys(currentHomeOwner).length) {
      return currentHomeOwner?.home_picture;
    }
    if (profileDetails && profileDetails.home_owner) {
      return (
        profileDetails.home_owner?.home_picture_url ??
        require("../../assets/logo.png")
      );
    }

    return ""; // require("../../assets/icons/parking/BOOKINGS.png");
  };

  return (
    <Modal
      style={{ inset: 0 }}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      onClose={handleClose}
      open={!!isOpen}
    >
      <Styled.MainContainer>
        <Styled.ModalContentContainer>
          <Styled.CloseButton onClick={handleClose}>
            <Cross24 colour="#fff" />
          </Styled.CloseButton>
          <Styled.ImageContainer isNew={getImageContent() === ""}>
            {getImageContent() === "" ? (
              <img
                src={require("../../assets/icons/parking/BOOKINGS.png")}
                style={{
                  margin: "auto"
                }}
              />
            ) : (
              <Styled.ImageContent src={getImageContent()} alt="carpark" />
            )}
          </Styled.ImageContainer>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <Styled.ListContainer>
                  <Styled.Addresscontainer isNew={getImageContent() === ""}>
                    <Styled.AddressLabel>
                      {postingDetail
                        ? postingDetail.address
                        : currentHomeOwner?.address}
                    </Styled.AddressLabel>
                  </Styled.Addresscontainer>
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <Styled.PriceContainer>
                    <Styled.ListTitle>
                      {`Unit No: ${
                        postingDetail
                          ? postingDetail.unit_no
                          : currentHomeOwner?.unit_no ||
                            "Not registered as home owner yet."
                      }`}
                    </Styled.ListTitle>
                  </Styled.PriceContainer>
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <MobileDatePicker
                    sx={{
                      width: "100%",
                      background: "#f0f1f5",
                      borderRadius: "20px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                      }
                    }}
                    label="Start Date"
                    className={classes.styleOverrides}
                    minDate={dayJs(new Date())}
                    format="DD/MM/YYYY"
                    maxDate={dayJs(
                      new Date().setMonth(new Date().getMonth() + 3)
                    )}
                    onChange={(date) => {
                      setFieldValue("start_date", date?.format("YYYY-MM-DD"));
                    }}
                    slots={{
                      day: customDay
                    }}
                    defaultValue={
                      postingDetail ? dayJs(postingDetail?.start_date) : null
                    }
                    disabled={isView}
                  />
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <SelectInput
                    disabled={isView}
                    title="Duration"
                    items={[
                      { id: 1, name: "1 month" },
                      { id: 2, name: "2 months" },
                      { id: 3, name: "3 months" }
                    ]}
                    onSelected={(searchValue) =>
                      setFieldValue("month", searchValue)
                    }
                    defaultValue={postingDetail ? postingDetail.month : 1}
                    width={"50ch"}
                    margin={0}
                  />
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <Styled.PriceContainer>
                    <Styled.ListTitle>
                      {`End Date: ${
                        postingDetail
                          ? moment(
                              new Date(postingDetail?.end_date ?? "")
                            ).format("DD/MM/YYYY")
                          : getEndDate(values.start_date, values.month)
                      }`}
                    </Styled.ListTitle>
                  </Styled.PriceContainer>
                </Styled.ListContainer>

                <Styled.ListContainer>
                  <Styled.PriceContainer>
                    <Styled.ListTitle>Price Per Month: RM100</Styled.ListTitle>
                  </Styled.PriceContainer>
                </Styled.ListContainer>
                {postingDetail && (
                  <Styled.ListContainer>
                    <Styled.PriceContainer>
                      <Styled.ListTitle>
                        {`Status: ${postingDetail.status}`}
                      </Styled.ListTitle>
                    </Styled.PriceContainer>
                  </Styled.ListContainer>
                )}
              </FormikForm>
            )}
          </Formik>
          {isView ? (
            <Styled.SubmitButton
              onClick={onCancel}
              disabled={postingDetail?.status !== "Available for booking"}
            >
              {isLoading ? "loading..." : "cancel posting"}
            </Styled.SubmitButton>
          ) : (
            <>
              <Styled.Note>
                * Please note that parking booked will only be for weekdays from
                8:30am to 6:30pm, and does not include public holiday and
                weekend
              </Styled.Note>

              <Styled.SubmitButton
                onClick={() => {
                  if (formikRef.current) {
                    formikRef.current.handleSubmit();
                  }
                }}
              >
                {isLoading ? "loading..." : "submit"}
              </Styled.SubmitButton>
            </>
          )}
        </Styled.ModalContentContainer>
      </Styled.MainContainer>
    </Modal>
  );
};
export default CreatePostingModal;
