import { combineReducers } from "redux";

import homeReducer from "redux/slices/homeSlices";
import homeOwnerReducer from "redux/slices/homeOwnerSlices";
import driverReducer from "redux/slices/driverSlices";
import authenticationReducer from "redux/slices/authenticationSlices";
import accountReducer from "redux/slices/accountSlices";
import historyReducer from "redux/slices/historySlices";
import checkInReducer from "redux/slices/checkInSlices";

const rootReducer = combineReducers({
	authentication: authenticationReducer,
	home: homeReducer,
	homeOwner: homeOwnerReducer,
	driver: driverReducer,
	account: accountReducer,
	history: historyReducer,
	checkIn: checkInReducer,
});

export default rootReducer;
