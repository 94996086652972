import IconComponent from "components/IconComponent";
import React from "react";

import ReactMapGL, { MapRef, Marker } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGeoLocations,
  setSelectedGeoObject,
} from "redux/slices/homeSlices";

const MapComponent = ({ children }) => {
  const allGeoLocations = useSelector(getAllGeoLocations);
  const dispatch = useDispatch();

  return (
    <ReactMapGL
      reuseMaps={true}
      initialViewState={{
        longitude: 101.6249189,
        latitude: 3.1508079,
        zoom: 10,
        //  transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
      }}
      scrollZoom={true}
      boxZoom={true}
      touchZoomRotate={true}
      refreshExpiredTiles={false}
      maxTileCacheSize={2}
      touchPitch={true}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      mapboxAccessToken={
        "pk.eyJ1Ijoid2ludGF0dCIsImEiOiJja29mMWl3cXowYXR2Mm9vOTBrZ3ZrcHpxIn0.Tw32F8QiKlFoylOcungQ3g"
      }
      //{...viewport}
      style={{ width: "100%", height: "120vh" }}
      //   onRender={setViewport}
    >
      {!!allGeoLocations &&
        allGeoLocations.map((location, index) => {
          return (
            <Marker
              key={index}
              longitude={Number(location.longitude)}
              latitude={Number(location.latitude)}
            >
              <div
                onClick={() => {
                  dispatch(setSelectedGeoObject(location));
                }}
                style={{ cursor: "pointer" }}
              >
                <IconComponent price={location.price} />
              </div>
            </Marker>
          );
        })}
      {children}
    </ReactMapGL>
  );
};

export default MapComponent;
