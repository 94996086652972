import React, { FC, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faCameraAlt,
  faL,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import { HomeOwnerRegistrationStepOneRequest } from "models/HomeOwner";
import { useSelector } from "react-redux";
import {
  getApplicationStatus,
  getLoadingStatus,
} from "redux/slices/homeOwnerSlices";
import FileInput from "../FileInput";
import { validateForm } from "./validateForm";
import * as Styled from "pages/SignUp/SignUp.styled";

export interface FormValues {
  full_name: string;
  ic_number: string;
  gender: string;
  ic_front_url: string;
  ic_back_url: string;
  selfie_url: string;
  emergency_contact_name: string;
  emergency_contact_relationship: string;
  emergency_contact_num: string;
}

const initialValues: FormValues = {
  full_name: "",
  ic_number: "",
  gender: "",
  ic_front_url: "",
  ic_back_url: "",
  selfie_url: "",
  emergency_contact_name: "",
  emergency_contact_relationship: "",
  emergency_contact_num: "",
};

type HomeOwnerRegistrationStepOneProps = {
  submitApplication: (request: HomeOwnerRegistrationStepOneRequest) => void;
};

const HomeOwnerRegistrationStepOne: FC<HomeOwnerRegistrationStepOneProps> = (
  props
) => {
  const { submitApplication } = props;
  const isLoading = useSelector(getLoadingStatus);
  const applicationStatus = useSelector(getApplicationStatus);
  const [showBack, setShowBack] = useState(false);
  const formikRef = React.useRef<any>(null);

  useEffect(() => {
    if (!isLoading && !!applicationStatus.first_step) {
      const values = applicationStatus.first_step;
      initialValues.full_name = values.full_name;
      initialValues.ic_number = values.ic_number;
      initialValues.gender = values.gender;
      initialValues.emergency_contact_name = values.emergency_contact_name;
      initialValues.emergency_contact_num = values.emergency_contact_num;
      initialValues.emergency_contact_relationship =
        values.emergency_contact_relationship;
    } else if (!isLoading && !applicationStatus.first_step) {
      setShowBack(true);
    }
  }, [isLoading, applicationStatus]);

  const onSubmit = (values: FormValues) => {
    const request: HomeOwnerRegistrationStepOneRequest = {
      ...values,
      ic_front_picture: values.ic_front_url,
      ic_back_picture: values.ic_back_url,
      selfie_picture: values.selfie_url,
      step: 1,
    };

    submitApplication(request);
  };

  return (
    <div className="maincontainer">
      <div
        className="container"
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "80px",
        }}
      >
        {showBack && (
          <Link to="/intro">
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ color: "#ccc", paddingTop: "40px" }}
            ></FontAwesomeIcon>
          </Link>
        )}
        <h3
          className="text-center"
          style={{
            color: "#e0479e",
            fontFamily: "Fredoka One",
            paddingBottom: "5%",
            paddingTop: "20px",
          }}
        >
          homeowner
        </h3>
        <p>
          <div className="" style={{ color: "#e0479e", fontSize: "10px" }}>
            Step 1 of 2
          </div>
          <div
            className=""
            style={{
              color: "#e0479e",
              fontFamily: "Fredoka One",
              fontSize: "20px",
            }}
          >
            tell us more about yourself
          </div>
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formikRef}
          validate={validateForm}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ values, handleBlur, handleChange, setFieldValue, errors }) => (
            <FormikForm>
              <div className="form-group input-group flex-column">
                <Styled.Field
                  name="full_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.full_name}
                  highlighted={!!values.full_name}
                  error={errors.full_name != null}
                  type="text"
                  placeholder="Full Name (As per IC)"
                />

                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="full_name" />
                </p>
              </div>
              <div className="form-group input-group flex-column">
                <Styled.Field
                  name="ic_number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ic_number}
                  highlighted={!!values.ic_number}
                  error={errors.ic_number != null}
                  type="text"
                  placeholder="IC Number"
                />

                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="ic_number" />
                </p>
              </div>
              <div className="form-group input-group flex-column">
                <select
                  name="gender"
                  defaultValue={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control w-100"
                  style={{
                    borderRadius: "20px",
                    background: "#f0f1f5",
                    border: errors.gender
                      ? "1px solid #dc3545"
                      : values.gender
                      ? "1px solid #f0f1f5"
                      : "1px solid #f0f1f5",
                  }}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="gender" />
                </p>
              </div>
              <div
                className=""
                style={{ fontSize: "17px", paddingTop: "3%", color: "#575252" }}
              >
                Kindly take a picture of your:
              </div>
              <p
                className="m-0 ml-1 mt-1 error"
                style={{
                  fontSize: "0.7em",
                  color: "#e0479e",
                }}
              >
                <ErrorMessage name="ic_front_url" />
              </p>
              <p
                className="m-0 ml-1 mt-1 error"
                style={{
                  fontSize: "0.7em",
                  color: "#e0479e",
                }}
              >
                <ErrorMessage name="ic_back_url" />
              </p>
              <p
                className="m-0 ml-1 mt-1 error"
                style={{
                  fontSize: "0.7em",
                  color: "#e0479e",
                }}
              >
                <ErrorMessage name="selfie_url" />
              </p>
              <div className="row" style={{ paddingTop: "2.5%" }}>
                <div
                  className="col-10"
                  style={{
                    color: errors.ic_front_url
                      ? "#dc3545"
                      : values.ic_front_url
                      ? "#e0479e"
                      : "#575252",
                  }}
                >
                  • Malaysian IC (front)
                </div>
                <FileInput
                  name="ic_front_picture"
                  error={errors.ic_front_url != null}
                  onChange={(image) => {
                    setFieldValue("ic_front_url", image);
                  }}
                />
              </div>
              <div className="row" style={{ paddingTop: "2.5%" }}>
                <div
                  className="col-10"
                  style={{
                    color: errors.ic_back_url
                      ? "#dc3545"
                      : values.ic_back_url
                      ? "#e0479e"
                      : "#575252",
                  }}
                >
                  • Malaysian IC (back)
                </div>
                <FileInput
                  name="ic_back_picture"
                  error={errors.ic_back_url != null}
                  onChange={(image) => {
                    setFieldValue("ic_back_url", image);
                  }}
                />
              </div>
              <div className="row" style={{ paddingTop: "2.5%" }}>
                <div
                  className="col-10"
                  style={{
                    color: errors.selfie_url
                      ? "#dc3545"
                      : values.selfie_url
                      ? "#e0479e"
                      : "#575252",
                  }}
                >
                  • Self (selfie)
                </div>
                <FileInput
                  name="selfie_picture"
                  error={errors.selfie_url != null}
                  onChange={(image) => {
                    setFieldValue("selfie_url", image);
                  }}
                />
              </div>
              <p style={{ fontSize: "0.68em", color: "#a4a4a4", marginTop: 5 }}>
                * Please make sure photos are clear to speed up approval process
              </p>
              <div className="" style={{ fontSize: "17px", paddingTop: "5%" }}>
                Emergency contact details:
              </div>
              <p
                style={{
                  fontSize: "0.68em",
                  color: "#a4a4a4",
                  marginTop: 0,
                  marginBottom: "0",
                }}
              >
                In case we cannot contact you during an emergency
              </p>
              <div
                className="form-group input-group flex-column"
                style={{ paddingTop: "5%" }}
              >
                <Styled.Field
                  name="emergency_contact_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.emergency_contact_name}
                  highlighted={!!values.emergency_contact_name}
                  error={errors.emergency_contact_name != null}
                  type="text"
                  placeholder="Full Name (As per IC)"
                />

                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="emergency_contact_name" />
                </p>
              </div>
              <div className="form-group input-group flex-column">
                <select
                  name="emergency_contact_relationship"
                  defaultValue={values.emergency_contact_relationship}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control w-100"
                  style={{
                    borderRadius: "20px",
                    background: "#f0f1f5",
                    border: errors.emergency_contact_relationship
                      ? "1px solid #dc3545"
                      : values.emergency_contact_relationship
                      ? "1px solid #f0f1f5"
                      : "1px solid #f0f1f5",
                  }}
                >
                  <option value="">Relationship</option>
                  <option value="Family">Family</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Friends">Friends</option>
                  <option value="Others">Others</option>
                </select>
                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="emergency_contact_relationship" />
                </p>
              </div>
              <div className="form-group input-group flex-column">
                <Styled.Field
                  name="emergency_contact_num"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.emergency_contact_num}
                  highlighted={!!values.emergency_contact_num}
                  error={errors.emergency_contact_num != null}
                  type="text"
                  placeholder="Mobile Number"
                />
                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="emergency_contact_num" />
                </p>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#e0479e",
                    borderColor: "#e0479e",
                    fontFamily: "Fredoka One",
                  }}
                  onClick={() => {
                    if (formikRef.current) {
                      formikRef.current.handleSubmit();
                    }
                  }}
                >
                  next
                </button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default HomeOwnerRegistrationStepOne;
