// react-router-dom components
import { Link } from "react-router-dom";
import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

function PaymentUnsuccessful() {

    return (
        <div className="maincontainer" style={{ overflow: 'auto', paddingBottom: '10%' }}>
            <div class="container" style={{ padding: '30px', width: '100%' }}>
                <div class="" style={{height: '200px', width: '200px', marginLeft: '30%', marginBottom: '5%'}}>
                <CardMedia
                    sx={{ objectFit: "contain", width: '100%', height: '100%', backgroundColor: 'white', borderRadius: 2, }}
                    image={require('../assets/images/no.png')}
                    title=""
                />
                </div>


                <h3 class="card-title mt-3 text-center" style={{ color: "#df479e", fontFamily: "Fredoka One", paddingBottom: "5%", paddingRight: "30%", paddingLeft: "30%"}}>payment Unsuccessful!</h3>

                <div class='' style={{ width: '100%', backgroundColor: 'white' }}>

                <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'black', fontWeight: '500' }}>
                                Unfortunately, we are unable to process your payment. Please try again.
                            </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div class="form-group" style={{ padding: '10px' }}>
                        <button type="button" class="btn btn-primary btn-block" style={{ borderRadius: "20px", backgroundColor: "lightGrey", borderColor: "lightGrey", fontSize: '20px', padding: '10px' }}> try again </button>
                    </div>
                    <div class="form-group" style={{ padding: '10px' }}>
                        <button type="button" class="btn btn-primary btn-block" style={{ borderRadius: "20px", backgroundColor: "lightGrey", borderColor: "lightGrey", fontSize: '20px', padding: '10px' }}> cancel </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default PaymentUnsuccessful;
