import { AxiosResponse } from "axios";
import { BookingStatus } from "models/BookingStatus";
import { AxiosGenericResponse } from "models/common";
import {
  BookingRequest,
  DriverApplicationStatus,
  DriverRegistrationStepOneRequest,
  DriverRegistrationStepTwoRequest,
  EmergencyContactUpdateRequest,
} from "models/Driver";
import { Booking } from "models/DriverState";
import { getRequest, postRequest } from "services/client/request";

const apiVersion = "/api";

export const submitApplicationAPI = async (
  request: DriverRegistrationStepOneRequest | DriverRegistrationStepTwoRequest,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await postRequest(
    `${apiVersion}/carefrees/driver_register`,
    accessToken,
    request
  );

  return response.data.success;
};

export const existingDriverRegisterationAPI = async (
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await postRequest(
    `${apiVersion}/carefrees/existing_register`,
    accessToken
  );

  return response.data.success;
};

export const getApplicationStatusAPI = async (
  accessToken: string
): Promise<DriverApplicationStatus> => {
  const response: AxiosResponse<
    AxiosGenericResponse<DriverApplicationStatus>
  > = await getRequest(`${apiVersion}/carefrees/drivers/status`, accessToken);
  const applicationStatus: DriverApplicationStatus = response.data.data;
  return applicationStatus;
};

export const bookParkingSpotAPI = async (
  request: BookingRequest,
  accessToken: string
): Promise<Booking> => {
  const response: AxiosResponse<
    AxiosGenericResponse<Booking>
  > = await postRequest(
    `${apiVersion}/carefrees/drivers/book`,
    accessToken,
    request
  );
  const booking: Booking = response.data.data;

  return booking;
};

export const checkoutAPI = async (
  bookingID: number,
  accessToken: string
): Promise<string> => {
  const request = {
    booking_id: bookingID,
  };
  try {
    const response: AxiosResponse = await postRequest(
      `${apiVersion}/carefrees/drivers/checkout`,
      accessToken,
      request
    );
    return response.data.url;
  } catch (error: any) {
    alert(error);
  }

  return "";
};

export const cancelBookingAPI = async (
  bookingID: number,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await postRequest(
    `${apiVersion}/carefrees/drivers/booking/${bookingID}/cancel`,
    accessToken
  );
  return response.data.success;
};

export const updateEmergencyContact = async (
  request: EmergencyContactUpdateRequest,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await postRequest(
    `${apiVersion}/carefrees/update_emergency_contact`,
    accessToken,
    request
  );
  return response.data.success;
};
