import styled from "styled-components";

type FieldProps = {
  highlighted: boolean;
  error: boolean;
  showingPrompt?: boolean;
};
export const Field = styled.input<FieldProps>`
  width: 100%;
  ${(props) =>
    props.showingPrompt
      ? `
        border-radius: 30px 30px 0 0;
    `
      : "border-radius: 30px;"}
  border: 1px solid ${(props) =>
    props.error ? "red" : props.highlighted ? `#e0479e` : `#F0F1F5`};
  border-bottom: ${(props) => (props.showingPrompt ? "none" : "auto")};
  padding: 10px 20px;
  outline: none;
  background: #f0f1f5;
  ::placeholder {
    color: #d5d5d5;
  }
`;

export const PasswordFieldHolder = styled.div`
  position: relative;
`;
