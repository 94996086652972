import React, { FC } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faL, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { Route, useNavigate } from "react-router-dom";
import * as Styled from "./ExistingDriverRegistrationSuccess.styled";
import { Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { validateForm } from "./validateForm";
import { EmergencyContactUpdateRequest } from "models/Driver";
import { useDispatch } from "react-redux";
import { updateEmergencyContactThunk } from "redux/slices/driverSlices";

export interface FormValues {
  emergency_contact_name: string,
  emergency_contact_relationship: string,
  emergency_contact_num: string,
};

const initialValues: FormValues = {
  emergency_contact_name: "",
  emergency_contact_relationship: "",
  emergency_contact_num: ""
};

const ExistingDriverRegistrationSuccess: FC = (props) => {
  const accessToken = localStorage.getItem("carching-token");
  const navigate = useNavigate();
  const formikRef = React.useRef<any>(null);
  const dispatch = useDispatch();

  const handleSubmit = (values: FormValues) => {
    if (!!accessToken) {
      const request: EmergencyContactUpdateRequest = {
        ...values
      }
  
      dispatch(updateEmergencyContactThunk({
        accessToken,
        emergencyContact: request,
      }));
    }
  };

  return (
    <Styled.MainContainer>
      <Styled.Title>
        driver
      </Styled.Title>
      <Styled.MidSection>
        <img src={require("../../assets/images/checkmark.png")} style={{ width: 120 }} />
        <Styled.Spacer height={20} />
        <Styled.SuccessTitle>welcome back carching driver!</Styled.SuccessTitle>
        <Styled.Spacer height={15} />
        <Styled.SuccessMessage>
          Thank you for your support! We would need additional details to complete your sign up process.
        </Styled.SuccessMessage>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validateForm}
          validateOnChange={false}
          validateOnBlur={false}
          innerRef={formikRef}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors }) => (
            <FormikForm style={{width: "100%", marginTop: 40}}>
              <div className="" style={{ fontSize: "0.9em", paddingTop: "5%" }}>Emergency contact details:</div>
              <Styled.InfoMessage>In case we cannot contact you during an emergency</Styled.InfoMessage>
              <div className="form-group input-group flex-column" style={{ paddingTop: "5%" }}>
                <input name="emergency_contact_name" value={values.emergency_contact_name} className="form-control w-100" style={{ borderRadius: "20px", border: errors.emergency_contact_name ? "1px solid #dc3545" : values.emergency_contact_name ? "1px solid #e0479e" : "1px solid #ced4da" }} placeholder="Full Name (As per IC)" type="text" onChange={handleChange} onBlur={handleBlur} />
                <p className="m-0 mt-1 error text-danger" style={{ fontSize: "0.8em" }}><ErrorMessage name="emergency_contact_name" /></p>
              </div>
              <div className="form-group input-group flex-column">
                <select name="emergency_contact_relationship" defaultValue={values.emergency_contact_relationship} onChange={handleChange} onBlur={handleBlur} className="form-control w-100" style={{ borderRadius: "20px", border: errors.emergency_contact_relationship ? "1px solid #dc3545" : values.emergency_contact_relationship ? "1px solid #e0479e" : "1px solid #ced4da" }}>
                  <option value="">Relationship</option>
                  <option value="Family">Family</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Friends">Friends</option>
                  <option value="Others">Others</option>
                </select>
                <p className="m-0 mt-1 error text-danger" style={{ fontSize: "0.8em" }}><ErrorMessage name="emergency_contact_relationship" /></p>
              </div>
              <div className="form-group input-group flex-column">
                <input name="emergency_contact_num" value={values.emergency_contact_num} onChange={handleChange} onBlur={handleBlur} className="form-control w-100" style={{ borderRadius: "20px", border: errors.emergency_contact_relationship ? "1px solid #dc3545" : values.emergency_contact_num ? "1px solid #e0479e" : "1px solid #ced4da" }} placeholder="Mobile Number" type="text" />
                <p className="m-0 mt-1 error text-danger" style={{ fontSize: "0.8em" }}><ErrorMessage name="emergency_contact_num" /></p>
              </div>
              <MDBox sx={{ paddingTop: "30px", width: "100%" }}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  onClick={() => {
                    if (formikRef.current) {
                      formikRef.current.handleSubmit();
                    }
                  }}
                  style={{ borderRadius: 20, textTransform: "lowercase", fontFamily: "Fredoka One" }}
                  fullWidth
                >
                  take me to the available parking!
                </MDButton>
              </MDBox>
            </FormikForm>
          )}
        </Formik>
      </Styled.MidSection>
    </Styled.MainContainer>
  );
};

export default ExistingDriverRegistrationSuccess;