import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllGeoLocations,
  getAllHousePostings,
} from "redux/slices/homeSlices";
import * as Styled from "./ListView.styled";
import CardComponent from "../FilterListComponent/CardComponent";
import CreateForm from "components/DetailsModal";
import {
  getIsHomeOwner,
  getIsPendingHomeOwner,
} from "redux/slices/authenticationSlices";
import PostCardComponent from "components/FilterListComponent/PostingCardComponent";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import CreatePostModal from "components/DetailsModal/createPostingModal";
import MDTypography from "components/MDTypography";
import { HomePosting } from "models/HomeState";

const ListView: React.FC = () => {
  const filteredLocations = useSelector(getAllGeoLocations);
  const homePostings = useSelector(getAllHousePostings);
  const isHomeOwner = useSelector(getIsHomeOwner);
  const [isCreateForm, openCreateForm] = useState<boolean>(false);
  const [isHomeOwnerViewDetails, setHomeOwnerViewDetails] = useState<
    HomePosting | undefined
  >(undefined);
  const isPendingHomeOwner = useSelector(getIsPendingHomeOwner);

  const shouldDisplayCreateButton = () => {
    const nonCancelPosts = homePostings.filter(
      (post) => post.status !== "Cancelled" && post.status !== "Ended"
    );
    if (nonCancelPosts.length > 0) {
      return false;
    }
    return true;
  };
  return (
    <>
      <Styled.CardScrollbar>
        {isHomeOwner === "HomeOwner" || isPendingHomeOwner ? (
          <>
            <MDTypography
              sx={{
                textAlign: "center",
                fontFamily: "Fredoka One",
                color: "#e0479e",
                marginTop: 2,
                fontSize: "25px",
              }}
            >
              my dashboard
            </MDTypography>
            {homePostings.length === 0 && (
              <MDTypography
                sx={{
                  textAlign: "center",
                  marginTop: 5,
                  marginBottom: 5,
                  fontFamily: "Fredoka One",
                }}
              >
                No postings yet
              </MDTypography>
            )}
            <CreatePostModal
              isView={!!isHomeOwnerViewDetails}
              postingDetail={isHomeOwnerViewDetails}
              isOpen={isCreateForm}
              handleClose={() => openCreateForm(false)}
            />
            <Styled.CardContainer>
              {homePostings &&
                homePostings.map((post, index) => {
                  return (
                    <PostCardComponent
                      posting={post}
                      key={index}
                      openDetails={() => {
                        setHomeOwnerViewDetails(post);
                        openCreateForm(true);
                      }}
                    />
                  );
                })}
              {shouldDisplayCreateButton() && (
                <MDBox
                  sx={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    width: "100%",
                    paddingBottom: "40px",
                  }}
                >
                  <MDButton
                    variant="gradient"
                    color={"primary"}
                    onClick={() => {
                      setHomeOwnerViewDetails(undefined);
                      openCreateForm(true);
                    }}
                    fullWidth
                  >
                    Create post
                  </MDButton>
                </MDBox>
              )}
            </Styled.CardContainer>
          </>
        ) : (
          <>
            {filteredLocations.length === 0 && (
              <MDTypography
                sx={{
                  textAlign: "center",
                  marginTop: 5,
                  marginBottom: 5,
                  fontFamily: "Fredoka One",
                }}
              >
                No postings yet
              </MDTypography>
            )}
            <CreateForm
              isOpen={isCreateForm}
              onClose={() => openCreateForm(false)}
            />
            {!!filteredLocations &&
              filteredLocations.map((location, index) => {
                return (
                  <CardComponent
                    location={location}
                    key={index}
                    openDetails={() => {
                      openCreateForm(true);
                    }}
                  />
                );
              })}
          </>
        )}
      </Styled.CardScrollbar>
    </>
  );
};

export default ListView;
