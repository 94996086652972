import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListSelected from "assets/list_selected.svg";
import ListUnselected from "assets/list_unselected.svg";
import MapSelected from "assets/map_selected.svg";
import MapUnselected from "assets/map_unselected.svg";

import * as Styled from "./ToggleButton.styled";
import { setDefaultView, getDefaultView } from "redux/slices/homeSlices";
import {
  getIsApprovedDriver,
  getIsHomeOwner,
  getIsPendingDriver,
} from "redux/slices/authenticationSlices";

const ToggleButton: React.FC = () => {
  const dispatch = useDispatch();
  const selectedView = useSelector(getDefaultView);
  const isApprovedDriver = useSelector(getIsApprovedDriver);
  const isPendingDriver = useSelector(getIsPendingDriver);
  const [isSelected, setIsSelected] = useState<number>(0 || selectedView);

  useEffect(() => {
    setIsSelected(selectedView);
  }, [selectedView]);
  return (
    <Styled.ToggleContainer isSelected={isApprovedDriver}>
      {(isApprovedDriver || isPendingDriver) && (
        <>
          <Styled.UnselectedButton
            isSelected={isSelected === 0}
            onClick={() => {
              dispatch(setDefaultView(0));
              setIsSelected(0);
            }}
          >
            <img
              alt={"map"}
              src={isSelected === 0 ? MapSelected : MapUnselected}
            />
          </Styled.UnselectedButton>
          <Styled.UnselectedButton
            isSelected={isSelected === 1}
            onClick={() => {
              dispatch(setDefaultView(1));
              setIsSelected(1);
            }}
          >
            <img
              alt={"list"}
              src={isSelected === 1 ? ListSelected : ListUnselected}
            />
          </Styled.UnselectedButton>
        </>
      )}
    </Styled.ToggleContainer>
  );
};

export default ToggleButton;
