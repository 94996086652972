import { faAdd, faCameraAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Compressor from "compressorjs";
import { useEffect, useRef, useState } from "react";

type FileInputProps = {
  name: string;
  onChange: (image: any) => void;
  error: boolean;
  showPreview?: boolean;
  label?: string;
  customFunc?: () => void;
  allow?: boolean;
};
const FileInput: React.FC<FileInputProps> = (props) => {
  const ref = useRef<any>(null);
  const [image, setImage] = useState<any>("");

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const image = e.target.files[0];
      new Compressor(image, {
        quality: 0.8,
        success: (result) => {
          convert64(result);
        },
      });
    }
  };

  const toBase64 = (file: File | Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const convert64 = async (image: File | Blob) => {
    const converted = await toBase64(image);

    setImage(converted);
    props.onChange(converted);
  };

  useEffect(() => {
    if (props.customFunc && props.allow) {
      ref.current.click();
    }
  }, [props.allow]);

  return (
    <div>
      <label htmlFor={props.name} ref={ref}>
        {props.showPreview ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                color: props.error ? "red" : "#bbb",
                fontSize: "0.6em",
                margin: "0px",
                marginBottom: "5px",
                textAlign: "center",
              }}
            >
              {props.label ?? ""}
            </p>
            <div
              style={{
                borderRadius: "50%",
                backgroundColor: "#f0f1f5",
                width: "60px",
                height: "60px",
                boxShadow: "1px 1px 3px 1px rgba(0, 0, 0, 0.23)",
                border: !props.error ? "none" : "1px solid red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: image.length > 0 ? `url(${image})` : "none",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              {image.length == 0 && (
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ color: "#575252" }}
                ></FontAwesomeIcon>
              )}
            </div>
          </div>
        ) : (
          <div
            className="btn"
            onClick={props.customFunc}
            style={{
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              backgroundColor: props.error
                ? "#dc3545"
                : image.length > 0
                ? "#e0479e"
                : "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faCameraAlt}
              style={{ color: "white" }}
            ></FontAwesomeIcon>
          </div>
        )}
      </label>
      {props.allow && (
        <input
          type="file"
          id={props.name}
          accept="image/*"
          capture="environment"
          style={{ display: "none" }}
          onChange={handleUpload}
        />
      )}
    </div>
  );
};

FileInput.defaultProps = {
  showPreview: false,
  allow: true,
};

export default FileInput;
