// react-router-dom components
import { Link } from "react-router-dom";
import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

function PaymentSuccessful() {

    return (
        <div className="maincontainer" style={{ overflow: 'auto', paddingBottom: '10%' }}>
            <div class="container" style={{ padding: '30px', width: '100%' }}>
                <div class="" style={{height: '200px', width: '200px', marginLeft: '30%', marginBottom: '5%'}}>
                <CardMedia
                    sx={{ objectFit: "contain", width: '100%', height: '100%', backgroundColor: 'white', borderRadius: 2, }}
                    image={require('../assets/images/yes.png')}
                    title=""
                />
                </div>


                <h3 class="card-title mt-3 text-center" style={{ color: "#df479e", fontFamily: "Fredoka One", paddingBottom: "5%", paddingRight: "30%", paddingLeft: "30%"}}>payment successful!</h3>

                <div class='' style={{ width: '100%', backgroundColor: 'white' }}>

                    <div class="row">
                        <div class="col-6">
                            <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'black', fontWeight: '500' }}>
                                Payment Amount
                            </div>
                        </div>
                        <div class="col-6" style={{}}>
                            <div class="text-right" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: '#655ba7', fontWeight: '600' }}>
                                RM100
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'black', fontWeight: '500' }}>
                                Payment Method
                            </div>
                        </div>
                        <div class="col-6" style={{}}>
                            <div class="text-right" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: '#655ba7', fontWeight: '600' }}>
                                Credit Card / Debit Card
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'black', fontWeight: '500' }}>
                                Payment Time
                            </div>
                        </div>
                        {/* <div class="col-3" style={{}}>
                            <div class="text-right" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: '#655ba7', fontWeight: '600' }}>
                                01/03/2023
                            </div>
                        </div> */}
                        <div class="col-6" style={{}}>
                            <div class="text-right" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: '#655ba7', fontWeight: '600' }}>
                                01/03/2023  00:00:00
                            </div>
                        </div>
                    </div>

                    <br />
                    <br />

                    <h4 class="card-title text-center" style={{ color: "#df479e", fontFamily: "sans-serif", fontWeight: 700, fontSize: "18px" }}>Thank You</h4>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div class="form-group" style={{ padding: '10px' }}>
                        <button type="button" class="btn btn-primary btn-block" style={{ borderRadius: "20px", backgroundColor: "lightGrey", borderColor: "lightGrey", fontSize: '20px', padding: '10px' }}> view details </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default PaymentSuccessful;
