import WithAuthWrapper from "hooks/withAuthWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";

const BookingDetailsPage = () => {
  const { state } = useLocation();
  const { title, booking } = state;
  const navigate = useNavigate();

  const formatDate = (date: string) => {
    const newDate = new Date(date);
    const day = newDate.getDate().toString().padStart(2, "0");
    const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
    const year = newDate.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <MDTypography
        variant="h4"
        color="text"
        mt={5}
        mb={1}
        sx={{
          textAlign: "center",
          color: "#e0479e",
          fontFamily: "Fredoka One",
          textTransform: "capitalize",
        }}
      >
        {`${title} booking`}
      </MDTypography>
      <div className="maincontainer" style={{ overflow: "auto" }}>
        <div className="container" style={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              margin: "auto",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img
              src={require("../../../assets/images/drive.png")}
              style={{ height: "200px", width: "200px" }}
            />
          </div>

          <div
            className=""
            style={{
              width: "100%",
              backgroundColor: "#fbebf3",
              padding: "10%",
              marginTop: "45px",
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <h4
              className="card-title"
              style={{ color: "#ff69b4", fontSize: "1.2em" }}
            >
              Summary
            </h4>
            <div
              className=""
              style={{
                fontSize: "0.9em",
                paddingTop: "10px",
                paddingBottom: "5px",
                color: "#aaa",
                fontWeight: "600",
              }}
            >
              Car Plate Number
            </div>
            <hr
              style={{
                height: "1.5px",
                backgroundColor: "lightGrey",
                margin: "0em",
              }}
            />
            <div
              className=""
              style={{
                fontSize: "0.9em",
                paddingBottom: "10px",
                paddingTop: "5px",
              }}
            >
              {booking?.plate_number}
            </div>

            <div
              className=""
              style={{
                fontSize: "0.9em",
                paddingTop: "10px",
                paddingBottom: "5px",
                color: "#aaa",
                fontWeight: "600",
              }}
            >
              Parking Address Details
            </div>
            <hr
              style={{
                height: "1.5px",
                backgroundColor: "lightGrey",
                margin: "0em",
              }}
            />
            <div
              className=""
              style={{
                fontSize: "0.9em",
                paddingBottom: "10px",
                paddingTop: "5px",
              }}
            >
              {booking?.address}
            </div>

            <div
              className=""
              style={{
                fontSize: "0.9em",
                paddingTop: "10px",
                paddingBottom: "5px",
                color: "#aaa",
                fontWeight: "600",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Start Date</span>
              <span>End Date</span>
            </div>
            <hr
              style={{
                height: "1.5px",
                backgroundColor: "lightGrey",
                margin: "0em",
              }}
            />
            <div
              className=""
              style={{
                fontSize: "0.9em",
                paddingBottom: "10px",
                paddingTop: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>{formatDate(booking?.start_date ?? "")}</span>
              <span>{formatDate(booking?.end_date ?? "")}</span>
            </div>

            <div
              className=""
              style={{
                fontSize: "0.9em",
                paddingTop: "10px",
                paddingBottom: "5px",
                color: "#aaa",
                fontWeight: "600",
              }}
            >
              Reference ID
            </div>
            <hr
              style={{
                height: "1.5px",
                backgroundColor: "lightGrey",
                margin: "0em",
              }}
            />

            <div className="row">
              <div className="col-6">
                <div
                  className=""
                  style={{ fontSize: "0.9em", paddingTop: "5px" }}
                >
                  {booking.reference_id}
                </div>
              </div>
            </div>

            <br />
            <br />

            <div className="form-group">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn btn-primary btn-block"
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#e0479e",
                  border: "none",
                  fontSize: "1em",
                  padding: "10px",
                  fontFamily: "Fredoka One",
                }}
              >
                back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithAuthWrapper(BookingDetailsPage);
