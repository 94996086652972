import { AxiosResponse } from "axios";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "services/client/request";
import {
    ProfileObjectDetail,
} from "models/AccountState";
import { AxiosGenericResponse, Status } from "types/common";

const apiVersion = "/api";

export const getProfileFromEndpoint = async (
    accessToken: string
): Promise<ProfileObjectDetail> => {
  const response: AxiosResponse<AxiosGenericResponse<ProfileObjectDetail>> =
    await getRequest(
      `${apiVersion}/carefrees/profile`,
      accessToken
    );

  const profileObjectDetail: ProfileObjectDetail = response.data.success
    ? response.data.data
    : ({} as ProfileObjectDetail);
  return profileObjectDetail;
};
