import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HistoryState } from "models/HistoryState";
import { StoreState } from "models/StoreState";
import { getHistoryAPI } from "services/api/historyApi";

const historySlice = createSlice({
    name: "history",
    initialState: {
        isLoading: false,
        count: 0,
        duration: 0,
        histories: [],
        selectedHistory: undefined
    } as HistoryState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setCount(state, action) {
            state.count = action.payload;
        },
        setDuration(state, action) {
            state.duration = action.payload;
        },
        setHistories(state, action) {
            state.histories = action.payload;
        },
        setSelectedHistory(state, action) {
            state.selectedHistory = action.payload;
        }
    }
});

export const getHistoryThunk = createAsyncThunk(
    "history/getHistoryThunk",
    async (accessToken: string, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(historySlice.actions.setIsLoading(true));
        const response = await getHistoryAPI(accessToken);
        dispatch(historySlice.actions.setIsLoading(false));
        dispatch(historySlice.actions.setCount(response.count));
        dispatch(historySlice.actions.setDuration(response.total_duration));
        dispatch(historySlice.actions.setHistories(response.histories));
    }
);

export const getLoadingStatus = (state: StoreState) =>
    state.history.isLoading;
export const getCount = (state: StoreState) =>
    state.history.count;
export const getTotalDuration = (state: StoreState) =>
    state.history.duration;
export const getHistories = (state: StoreState) =>
    state.history.histories;

export const getSelectedHistory = (state: StoreState) =>
    state.history.selectedHistory;

export const {
    setSelectedHistory
} = historySlice.actions;
export default historySlice.reducer;