import styled from "styled-components";
import Button from "@material-ui/core/Button";
import screenSize from "styles/mediaQueries";
import { Popup } from "react-map-gl";

export const MainContainer = styled.div`
  width: 100%;
  display: block;
  position: fixed;
`;

export const MainHeader = styled.p`
  font-family: fangsong;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  ${screenSize.minTablet`
        font-size: 70px;
    `}
`;

export const SubHeader = styled.p`
  color: #abb2c4;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;
  margin-bottom: 20px;
`;

export const FoodBankButton = styled(Button)`
  &&& {
    color: white;
    margin-left: 30px;
    background-color: #2d65fc;
    border-radius: 6px;
  }
`;

export const NeedHelpButton = styled(Button)`
  &&& {
    color: white;
    background-color: #f86a36;
    border-radius: 6px;
  }
`;

export const AppHeader = styled.div`
  background: rgba(35, 42, 61, 0.9);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  top: 0;
  z-index: 200;
`;

export const HeaderContainer = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
`;
export const PopupModal = styled(Popup)`
  width: 300px;
  padding: 10px;
  border-radius: 20px;
  .mapboxgl-popup-close-button {
    font-size: 20px;
    background: #e0479e;
    border-radius: 50%;
    color: #fff;
    top: 6px;
    right: 6px;
  }

  .mapboxgl-popup-content {
    box-shadow: 0 7px 10px rgb(0 0 0 / 35%);
    border-radius: 20px;
    padding: 0;
  }
  .mapboxgl-popup-tip {
    border-width: 10px !important;
  }

  ${screenSize.minTablet`
      width: 350px;
      max-width: 450px !important;
  `}
`;

export const FooterMobile = styled.footer`
  width: 100%;
  position: fixed;
  bottom: 0;
  justify-content: center;
  display: grid;
  height: 3rem;
  padding: 1rem 0;
  background: white;
`;

export const FooterAboutUsLink = styled.a`
  color: #838a9e;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  margin-top: 5px;
  ${screenSize.minTablet`
	display: none;
  `}
`;

export const FooterHelpButton = styled(Button)`
  &&& {
    color: white;
    margin-left: 30px;
    background-color: #2d65fc;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: inherit;
    width: 150px;
    font-weight: bold;
  }
`;

export const FooterNeedHelpButton = styled(Button)`
  &&& {
    color: white;
    width: 150px;
    background-color: #f86a36;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: inherit;
    font-weight: bold;
  }
`;
