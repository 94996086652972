import styled from "styled-components";
import screenSize from "styles/mediaQueries";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  align-items: center;
  height: 100vh;
  justify-content: space-between;
  ${screenSize.minTablet`

    margin: auto;
      width: 50%;
      `}
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Title = styled.h3`
  color: red;
  font-family: "Fredoka One";
  text-align: center;
  width: 100%;
  color: #e0479e;
`;

export const FormHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SideBySide = styled.div`
  display: flex;

  ${screenSize.minTablet`

display: block;
    `}
`;

type FieldProps = {
  highlighted: boolean;
  error: boolean;
};
export const Field = styled.input<FieldProps>`
  width: 100%;
  border-radius: 30px;
  border: 1px solid
    ${(props) =>
      props.error ? "#e0479e" : props.highlighted ? `#F0F1F5` : `#F0F1F5`};
  padding: 10px 20px;
  outline: none;
  background: #f0f1f5;
  ::placeholder {
    color: #d5d5d5;
  }
`;

export const FieldInfoMessage = styled.p`
  color: #a4a4a4;
  //   text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 0.67em;
`;

export const FieldErrorMessage = styled.p`
  color: red;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 0.8em;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px 20px;
  border: none;
  font-family: "Fredoka One";
  color: #fff;
  border-radius: 20px;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#e0479e")};
`;

export const ForgotPasswordLink = styled.a`
  font-family: "Fredoka One";
  color: #e0479e;
  font-size: 0.9em;
`;

export const PasswordHolder = styled.div`
  position: relative;
`;

type PasswordCheckerHolderProps = {
  highlighted: boolean;
  error: boolean;
  show: boolean;
};
export const PasswordCheckerHolder = styled.div<PasswordCheckerHolderProps>`
  padding: 15px 15px;
  padding-top: 5px;
  border: 1px solid
    ${(props) => (props.error ? "red" : props.highlighted ? `#e0479e` : `#ccc`)};
  border-top: none;
  ${(props) =>
    props.show
      ? `
    opacity: 1;
    visibility: visible;
  `
      : `
    opacity: 0;
    visibility: hidden;
  `}
  border-radius: 0px 0px 30px 30px;
  position: absolute;
  background: #fff;
  z-index: 1;
  width: 100%;
`;

export const PasswordCheckerRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin: 0;
  }
`;

type PasswordCheckerProps = {
  valid: boolean;
};

export const PasswordCheckerIcon = styled.div<PasswordCheckerProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.valid ? "#e0479e" : "#D5D3D3")};
  margin-right: 10px;
`;

export const PasswordCheckerText = styled.p<PasswordCheckerProps>`
  margin: 0;
  font-size: 0.85em;
  color: ${(props) => (props.valid ? "#e0479e" : "#D5D3D3")};
`;

export const SignUpText = styled.p`
  font-size: 0.9em;
`;

export const Link = styled.a`
  color: #e0479e;
`;

export const CheckboxHolder = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    margin-left: 10px;
    font-size: 0.8em;
  }
`;

export const MobileNumberField = styled.div`
  position: relative;
`;

export const GetOTPButton = styled.button`
  position: absolute;
  right: 10px;
  font-family: "Fredoka One";
  font-size: 0.8em;
  padding: 6px;
  border: none;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#e0479e")};
  display: flex;
  color: #fff;
  border-radius: 30px;
  top: 50%;
  transform: translateY(-50%);
`;

export const MidSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
  }
`;

export const ResendLink = styled.span`
  color: #e0479e;
`;

type SpacerProps = {
  height: number;
};
export const Spacer = styled.div<SpacerProps>`
  height: ${(props) => `${props.height}px`};
`;

type ExpanderProps = {
  width: number;
};
export const Expander = styled.div<ExpanderProps>`
  width: ${(props) => `${props.width}px`};
`;
