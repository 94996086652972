import styled from "styled-components";
import Button from "@material-ui/core/Button";
import screenSize from "styles/mediaQueries";

export const MainContainer = styled.div`
  position: absolute;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  background: transparent;
  ${screenSize.minTablet`
  width: 35rem;
`}
`;
export const ModalContentContainer = styled.div`
  width: 90%;
  position: relative;
  ${screenSize.minTablet`
  width: 80%;
`}
`;
export const CloseButton = styled.div`
  position: absolute;
  right: 5px;
  top: 25px;
  transform: translateY(-100%);
  padding: 0.8rem;
  background: transparent;
  border: none;
  margin-top: 2rem;
  cursor: pointer;
  z-index: 999999;
  ${screenSize.minTablet`
        top: 10px;
        padding: 0 .8rem;
    `};
`;
export const SaveButton = styled(Button)`
  &&& {
    background: #232a3d;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 2rem;
  }
`;
export const HoverContainer = styled.div`
  background: rgba(255, 255, 255, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

export const GridContainer = styled.div`
  display: inline-flex;
  width: 100%;
`;
export const NameContainer = styled.div`
  width: 80%;
`;
export const BeenHelpCountContainer = styled.div`
  width: 20%;
`;

export const ButtonContainer = styled.div`
  width: 35%;
  div > & {
    margin: auto;
    justify-content: end;
    align-items: end;
    display: grid;
  }
`;

export const Label = styled.p`
  color: #abb2c4;
  font-weight: 500;
  font-size: 10px;
  text-align: left;
  font-family: Fredoka One;
  margin: 0;
`;
export const ContentLabel = styled.p`
  color: #232a3d;
  font-weight: 500;
  font-size: 10px;
  text-align: left;
  margin-top: 0;
  font-family: Fredoka One;
`;

export const FullWidthContainer = styled.div`
  width: 100%;
  display: block;
`;

export const FilterContainer = styled.div`
  display: block;
  width: 100%;
`;
export const ContactButton = styled(Button)`
  &&& {
    border-radius: 6px;
    background: #f86a36;
    display: inline-block;
    color: white;
    margin: auto;
    border-color: #f86a36;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: bold;
    text-transform: capitalize;
  }
`;
export const FlexButtonContainer = styled.div`
  display: flex;
  justify-content: space-evently;
  align-items: start;
  margin-bottom: 1rem;
`;

export const HelpedButton = styled(Button)`
  &&& {
    border-radius: 6px;
    display: inline-block;
    color: #232a3d;
    font-weight: bold;
    background: white;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border-color: #232a3d;
    text-transform: capitalize;
  }
`;
export const LabelContainer = styled.div`
  margin-top: 0.5px;
`;
