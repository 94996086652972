import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cross24 from "../../assets/Cross24";
import {
  getHasActiveBooking,
  openFilterMobile,
  setDefaultView,
  setHasActiveBooking,
  setIsNavigateFromActiveBooking,
  setNavigateToActiveBooking,
} from "redux/slices/homeSlices";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItemButton from "@mui/material/ListItemButton";
import * as Styled from "./FilterListComponent.styled";
import { useNavigate } from "react-router-dom";
import {
  getIsPendingDriver,
  getIsPendingHomeOwner,
  logout,
} from "redux/slices/authenticationSlices";
import { IconButton, ListItemAvatar } from "@mui/material";
import {
  getProfileThunk,
  getProfile,
  setProfile,
  getLoadingStatus,
} from "redux/slices/accountSlices";
import {
  getIsApprovedDriver,
  getIsApprovedHomeOwner,
} from "redux/slices/authenticationSlices";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HouseIcon from "@mui/icons-material/House";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import HistoryIcon from "@mui/icons-material/History";
import MapIcon from "@material-ui/icons/Map";
import MDTypography from "components/MDTypography";
import { Divider, Typography } from "@material-ui/core";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { setSelectedHistory } from "redux/slices/historySlices";
import LoadingComponent from "components/LoadingComponent";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MapPin from "assets/images/pin.png";

const MobileFilterList: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileDetails = useSelector(getProfile);
  const isApprovedDriver = useSelector(getIsApprovedDriver);
  const isApprovedHomeOwner = useSelector(getIsApprovedHomeOwner);
  const isPendingDriver = useSelector(getIsPendingDriver);
  const isPendingHomeOwner = useSelector(getIsPendingHomeOwner);
  const hasActiveBooking = true; //useSelector(getHasActiveBooking);
  const isLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (profileDetails && Object.keys(profileDetails).length === 0) {
      dispatch(getProfileThunk(accessToken));
    }
  }, [isApprovedHomeOwner, isApprovedDriver, profileDetails]);

  const onClose = useCallback(() => {
    dispatch(openFilterMobile(false));
  }, [dispatch]);

  return (
    <>
      <Styled.MobileFilterListContainer>
        <Styled.SideBarHeaderContainer>
          <List
            style={{
              width: "100%",
              paddingLeft: 15,
              paddingBottom: 15,
            }}
          >
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={onClose}
                  sx={{
                    width: "25px",
                    height: "25px",
                    "&.MuiIconButton-root": {
                      background: "#E0479E",
                      padding: "3px",
                    },
                    "&.MuiIconButton-edgeEnd": {
                      marginRight: "10px",
                    },
                  }}
                >
                  <Cross24 colour="white" />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Styled.AccountLogo
                  sx={{
                    width: 80,
                    height: 80,
                    marginRight: 1,
                    color: "#e0479e",
                  }}
                  src={
                    profileDetails
                      ? isApprovedHomeOwner || isPendingHomeOwner
                        ? profileDetails.home_owner?.houses[0].home_picture_url
                        : profileDetails.driver?.car_picture
                      : ""
                  }
                />
              </ListItemAvatar>
              <ListItemText
                style={{ color: "#e0479e" }}
                primary={
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    {profileDetails
                      ? `${profileDetails.user?.fname ?? "loading..."} ${
                          profileDetails.user?.lname ?? ""
                        }`
                      : "Pending Approval"}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    style={{
                      color: "#e0479e",
                      fontSize: "11px",
                      fontWeight: "normal",
                    }}
                  >
                    {profileDetails
                      ? profileDetails.user
                        ? `@${profileDetails.user?.username}`
                        : ""
                      : ""}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Styled.SideBarHeaderContainer>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <Styled.FilterContainer>
            {profileDetails && (
              <>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          {isApprovedHomeOwner || isPendingHomeOwner ? (
                            <HouseIcon
                              style={{
                                width: 30,
                                height: 30,
                                color: "#e0479e",
                              }}
                            />
                          ) : (
                            <DirectionsCarIcon
                              style={{
                                width: 30,
                                height: 30,
                                color: "#e0479e",
                              }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          style={{ color: "#e0479e" }}
                          primary={
                            isApprovedHomeOwner || isPendingHomeOwner
                              ? "My House"
                              : "My Car"
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  </AccordionSummary>
                  <AccordionDetails style={{ paddingLeft: 50 }}>
                    {!isApprovedHomeOwner && !isPendingHomeOwner ? (
                      <>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          sx={{
                            "&.MuiTypography-button": {
                              fontFamily: "Fredoka One",
                              color: "#575252",
                              fontSize: "12px",
                              textTransform: "capitalize",
                            },
                          }}
                        >
                          {profileDetails.driver?.cars[0].plate_number}
                        </MDTypography>
                        <span style={{ margin: "0 5px", color: "#575252" }}>
                          -
                        </span>
                        <MDTypography
                          variant="button"
                          fontWeight="normal"
                          sx={{
                            "&.MuiTypography-button": {
                              fontFamily: "Fredoka One",
                              color: "#575252",
                              fontSize: "12px",
                              textTransform: "capitalize",
                            },
                          }}
                        >
                          {profileDetails.driver?.cars[0].car_brand} <br />
                          {profileDetails.driver?.cars[0].car_model}
                        </MDTypography>
                      </>
                    ) : (
                      <MDTypography
                        variant="p"
                        fontWeight="regular"
                        color="primary"
                        sx={{ fontFamily: "Fredoka One", color: "#575252" }}
                      >
                        {profileDetails.home_owner?.houses[0].city} -
                        {profileDetails.home_owner?.houses[0].district}
                      </MDTypography>
                    )}
                    <Divider />
                  </AccordionDetails>
                </Accordion>

                <Accordion style={{ boxShadow: "none", margin: 0 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <HistoryIcon
                            style={{ width: 30, height: 30, color: "#e0479e" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          style={{ color: "#e0479e" }}
                          primary={"History"}
                        />
                      </ListItemButton>
                    </ListItem>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ width: "100%", padding: 0, display: "block" }}
                  >
                    {!isApprovedHomeOwner && !isPendingHomeOwner ? (
                      <List style={{ padding: 0 }}>
                        {profileDetails.driver?.parkings.map((parking, i) => {
                          if (i >= 5) {
                            return (
                              <div key={i}>
                                <Divider />
                                <ListItem
                                  sx={{
                                    padding: "20px 20px",
                                    justifyContent: "center",
                                  }}
                                  onClick={() => {
                                    dispatch(openFilterMobile(false));
                                    navigate("/history");
                                  }}
                                >
                                  <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="capitalize"
                                    sx={{
                                      fontFamily: "Roboto !important",
                                      color: "#e0479e",
                                      textAlign: "center",
                                    }}
                                  >
                                    See More
                                  </MDTypography>
                                </ListItem>
                              </div>
                            );
                          }

                          return (
                            <div key={i}>
                              <Divider />
                              <ListItem
                                sx={{
                                  padding: "20px 20px",
                                  paddingLeft: "55px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="capitalize"
                                    sx={{
                                      fontFamily: "Roboto !important",
                                      color: "#e0479e",
                                    }}
                                  >
                                    {parking.date_short}
                                  </MDTypography>
                                  <span
                                    style={{
                                      margin: "0 5px",
                                      color: "#e0479e",
                                    }}
                                  >
                                    -
                                  </span>
                                  <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="capitalize"
                                    sx={{
                                      fontFamily: "Fredoka One",
                                      color: "#e0479e",
                                    }}
                                  >
                                    {parking.street}
                                  </MDTypography>
                                </div>
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  onClick={() => {
                                    dispatch(openFilterMobile(false));
                                    dispatch(setSelectedHistory(parking));
                                    navigate("/historyDetail");
                                  }}
                                  style={{
                                    color: "#e0479e",
                                    width: "10px",
                                    height: "10px",
                                    display: "inline-block",
                                    borderRadius: "60%",
                                    border: "solid 1px #e0479e",
                                    padding: "4px",
                                  }}
                                />
                              </ListItem>
                            </div>
                          );
                        })}
                      </List>
                    ) : (
                      <List style={{ padding: 0 }}>
                        {profileDetails.home_owner?.parkings.map(
                          (parking, i) => {
                            if (i >= 5) {
                              return (
                                <div key={i}>
                                  <Divider />
                                  <ListItem
                                    sx={{
                                      padding: "20px 20px",
                                      justifyContent: "center",
                                    }}
                                    onClick={() => {
                                      dispatch(openFilterMobile(false));
                                      navigate("/history");
                                    }}
                                  >
                                    <MDTypography
                                      variant="button"
                                      fontWeight="regular"
                                      textTransform="capitalize"
                                      sx={{
                                        fontFamily: "Roboto !important",
                                        color: "#e0479e",
                                        textAlign: "center",
                                      }}
                                    >
                                      See More
                                    </MDTypography>
                                  </ListItem>
                                </div>
                              );
                            }

                            return (
                              <div key={i}>
                                <Divider />
                                <ListItem
                                  sx={{
                                    padding: "20px 20px",
                                    paddingLeft: "55px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <MDTypography
                                      variant="button"
                                      fontWeight="regular"
                                      textTransform="capitalize"
                                      sx={{
                                        fontFamily: "Roboto !important",
                                        color: "#e0479e",
                                      }}
                                    >
                                      {parking.date_short}
                                    </MDTypography>
                                    <span
                                      style={{
                                        margin: "0 5px",
                                        color: "#e0479e",
                                      }}
                                    >
                                      -
                                    </span>
                                    <MDTypography
                                      variant="button"
                                      fontWeight="regular"
                                      textTransform="capitalize"
                                      sx={{
                                        fontFamily: "Fredoka One",
                                        color: "#e0479e",
                                      }}
                                    >
                                      {parking.street}
                                    </MDTypography>
                                  </div>
                                  <FontAwesomeIcon
                                    icon={faSearch}
                                    onClick={() => {
                                      dispatch(openFilterMobile(false));
                                      dispatch(setSelectedHistory(parking));
                                      navigate("/historyDetail");
                                    }}
                                    style={{
                                      color: "#e0479e",
                                      width: "10px",
                                      height: "10px",
                                      display: "inline-block",
                                      borderRadius: "60%",
                                      border: "solid 1px #e0479e",
                                      padding: "4px",
                                    }}
                                  />
                                </ListItem>
                              </div>
                            );
                          }
                        )}
                      </List>
                    )}
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {isApprovedHomeOwner && (
              <>
                <Divider />
                <ListItem
                  style={{ padding: 10, paddingLeft: 15 }}
                  onClick={() => {
                    dispatch(setNavigateToActiveBooking(false));
                    dispatch(setIsNavigateFromActiveBooking(true));
                    dispatch(setDefaultView(1));
                    dispatch(openFilterMobile(false));
                    navigate("/home");
                  }}
                >
                  <ListItemIcon>
                    <ManageSearchIcon
                      style={{ width: 30, height: 30, color: "#e0479e" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="My Listing"
                    style={{ fontFamily: "Fredoka One", color: "#e0479e" }}
                  />
                </ListItem>

                <Divider />
                {hasActiveBooking && (
                  <ListItem
                    style={{ padding: 10, paddingLeft: 15 }}
                    onClick={() => {
                      dispatch(setNavigateToActiveBooking(true));
                      dispatch(setIsNavigateFromActiveBooking(false));
                      dispatch(openFilterMobile(false));
                      navigate("/status");
                    }}
                  >
                    <ListItemIcon>
                      <BeenhereIcon
                        style={{ width: 30, height: 30, color: "#e0479e" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Driver Status"}
                      style={{ fontFamily: "Fredoka One", color: "#e0479e" }}
                    />
                  </ListItem>
                )}
              </>
            )}
            {(isApprovedDriver || isPendingDriver) && (
              <>
                <Divider />
                <List>
                  <ListItem
                    style={{ padding: 10, paddingLeft: 15 }}
                    onClick={() => {
                      dispatch(setNavigateToActiveBooking(false));
                      dispatch(setIsNavigateFromActiveBooking(true));
                      dispatch(setDefaultView(1));
                      dispatch(openFilterMobile(false));
                      navigate("/home");
                    }}
                  >
                    <ListItemIcon>
                      <ManageSearchIcon
                        style={{ width: 30, height: 30, color: "#e0479e" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Explore"
                      style={{ fontFamily: "Fredoka One", color: "#e0479e" }}
                    />
                  </ListItem>
                  <Divider />
                  {hasActiveBooking && !isPendingHomeOwner && !isPendingDriver && (
                    <ListItem
                      style={{ padding: 10, paddingLeft: 15 }}
                      onClick={() => {
                        dispatch(setNavigateToActiveBooking(true));
                        dispatch(setIsNavigateFromActiveBooking(false));
                        dispatch(openFilterMobile(false));
                        navigate("/status");
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={MapPin}
                          style={{ width: 30, height: 30, color: "#e0479e" }}
                          alt="pin-label"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          isApprovedHomeOwner ? "Driver Status" : "Check in"
                        }
                        style={{ fontFamily: "Fredoka One", color: "#e0479e" }}
                      />
                    </ListItem>
                  )}
                </List>
              </>
            )}
            {isApprovedHomeOwner ||
              (isApprovedDriver && (
                <>
                  <ListItem
                    style={{ padding: 10, paddingLeft: 15 }}
                    onClick={() => {
                      dispatch(openFilterMobile(false));
                      navigate("/booking");
                    }}
                  >
                    <ListItemIcon>
                      <CalendarTodayIcon
                        style={{ width: 30, height: 30, color: "#e0479e" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="All Bookings"
                      style={{
                        fontFamily: "Fredoka One",
                        color: "#e0479e",
                      }}
                    />
                  </ListItem>
                  <Divider />
                </>
              ))}
            {(isApprovedDriver || isPendingDriver) && (
              <ListItem
                style={{ padding: 10, paddingLeft: 15 }}
                onClick={() => {
                  dispatch(setNavigateToActiveBooking(false));
                  dispatch(setIsNavigateFromActiveBooking(true));
                  dispatch(setDefaultView(0));
                  dispatch(openFilterMobile(false));
                  navigate("/home");
                }}
              >
                <ListItemIcon>
                  <MapIcon
                    style={{ width: 30, height: 30, color: "#e0479e" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Map View"
                  style={{ fontFamily: "Fredoka One", color: "#e0479e" }}
                />
              </ListItem>
            )}
          </Styled.FilterContainer>
        )}

        <Styled.MobileFilterFooter>
          <p style={{ marginBottom: 0 }}>
            <Styled.Link
              href="https://friday-carching.s3.ap-southeast-1.amazonaws.com/carefree/carefree_privacy_policy.pdf"
              target="_blank"
            >
              terms and conditions
            </Styled.Link>
          </p>
          <Button
            onClick={() => {
              onClose();
              dispatch(setProfile({}));
              dispatch(logout());
              navigate("/");
            }}
            style={{
              textTransform: "lowercase",
              fontWeight: "bolder",
              color: "#e0479e",
              fontFamily: "Fredoka One",
            }}
            startIcon={<ExitToAppIcon />}
          >
            logout
          </Button>
        </Styled.MobileFilterFooter>
      </Styled.MobileFilterListContainer>
    </>
  );
};

export default MobileFilterList;
