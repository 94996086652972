import {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
  SimpleInterpolation
} from "styled-components";

export const MIN_MOBILE = 375;
export const MIN_TABLET = 768;
export const MIN_DESKTOP = 1025;
export const MIN_DESKTOP_LG = 1225;

export const sizes = {
  minMobile: `@media print, screen and (min-width: ${MIN_MOBILE}px)`,
  minTablet: `@media print, screen and (min-width: ${MIN_TABLET}px)`,
  minDesktop: `@media print, screen and (min-width: ${MIN_DESKTOP}px)`,
  minLargeDesktop: `@media print, screen and (min-width: ${MIN_DESKTOP_LG}px)`
};
export interface SizeTheme {
  minMobile: string;
  minTablet: string;
  minDesktop: string;
  minLargeDesktop: string;
}

type ScreenSizeFunction = (
  first: CSSObject | TemplateStringsArray,
  ...args: SimpleInterpolation[]
) => FlattenSimpleInterpolation;

// Snippet sourced from: https://medium.com/@samuelresua/easy-media-queries-in-styled-components-690b78f50053
const screenSize = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (acc, label) => {
    acc[label] = (first, ...args) => css`
      ${sizes[label]} {
        ${css(first, ...args)}
      }
      /* stylelint-disable-next-line */
    `;
    return acc;
  },
  {} as { [key in keyof typeof sizes]: ScreenSizeFunction }
);

export default screenSize;
