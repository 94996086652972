import styled from "styled-components";
import Button from "@material-ui/core/Button";
import screenSize from "styles/mediaQueries";

export const CardScrollbar = styled.div`
  height: 100vh;
  padding-top: 80px;
  padding-bottom: 40px;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    outline: 1px solid #d3d3d3;
  }
  ${screenSize.minTablet`
  padding-top: 120px;
  `}
`;

export const CardContainer = styled.div`
  ${screenSize.minTablet`
    width: 30%;
    margin: auto;
    justify-content: center;
    display: grid;
    align-content: center;
`}
`;
export const FooterMobile = styled.footer`
  width: 100%;
  position: fixed;
  bottom: 0;
  justify-content: center;
  display: grid;
  height: 3rem;
  padding: 1rem 0;
  background: white;
`;

export const FooterAboutUsLink = styled.a`
  color: #838a9e;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  margin-top: 5px;
`;

export const FooterHelpButton = styled(Button)`
  &&& {
    color: white;
    margin-left: 30px;
    background-color: #2d65fc;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: inherit;
    width: 150px;
    font-weight: bold;
  }
`;

export const FooterNeedHelpButton = styled(Button)`
  &&& {
    color: white;
    width: 150px;
    background-color: #f86a36;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: inherit;
    font-weight: bold;
  }
`;
