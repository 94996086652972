import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderWidth, borderColor } = borders;
const { transparent, info } = colors;

const checkBox = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: pxToRem(20),
        height: pxToRem(20),
        color: transparent.main,
        border: `${borderWidth[1]} solid ${borderColor}`,
        borderRadius: pxToRem(5.6),
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-focusVisible": {
        border: `${borderWidth[2]} solid ${info.main} !important`,
      },
      "& .Mui-checked": {
        color: "green",
      },
    },

    "&.Mui-checked": {
      color: "blue",

      "& .MuiSvgIcon-root": {
        borderColor: info.main,
      },
    },
    colorPrimary: {
      color: borderColor,

      "&.Mui-checked": {
        color: "blue",

        "& .MuiSvgIcon-root": {
          borderColor: info.main,
        },
      },
    },

    colorSecondary: {
      color: borderColor,

      "& .MuiSvgIcon-root": {
        color: "blue",
        "&.Mui-checked": {
          borderColor: info.main,
        },
      },
    },
  },
};

export default checkBox;
