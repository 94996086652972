import { AxiosResponse } from "axios";
import { AxiosGenericResponse } from "models/common";
import { HistoryResponse } from "models/HistoryState";
import { getRequest } from "services/client/request"

const apiVersion = "/api";

export const getHistoryAPI = async (
    accessToken: string
): Promise<HistoryResponse> => {
    const response: AxiosResponse<AxiosGenericResponse<HistoryResponse>> = await getRequest(`${apiVersion}/carefrees/history`, accessToken);
    return response.data.data;
}