import React, { FC } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import * as Styled from "./RegistrationSuccess.styled";
import { Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { validateAuthentication } from "redux/slices/authenticationSlices";
import register from "assets/json/register.json";
import { Player } from "@lottiefiles/react-lottie-player";

type RegistrationSuccessProps = {
  isHomeOwner: boolean;
};
const RegistrationSuccess: FC<RegistrationSuccessProps> = (props) => {
  const { isHomeOwner } = props;
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Styled.MainContainer>
      <Styled.Title>
        {isHomeOwner ? "homeowner" : "driver / parking seeker"}
      </Styled.Title>
      <Styled.MidSection>
        <Player
          autoplay
          loop
          src={register}
          style={{ height: "150px", width: "150px" }}
        />
        <Styled.Spacer height={20} />
        <Styled.SuccessTitle>thank you for registering!</Styled.SuccessTitle>
        <Styled.Spacer height={15} />
        <Styled.SuccessMessage>
          {`You’re one step closer to ${
            isHomeOwner ? "earning" : "getting"
          } your parking! You will receive an email notification once your information
          has been verified. Kindly note this will take 3 to 5 working days.`}
        </Styled.SuccessMessage>

        <MDBox sx={{ paddingTop: 10, width: "100%" }}>
          <MDButton
            variant="gradient"
            color="primary"
            onClick={() => {
              dispatch(validateAuthentication(accessToken));
              navigate("/home");
            }}
            style={{ borderRadius: 20, textTransform: "lowercase" }}
            fullWidth
          >
            {`take me to ${
              isHomeOwner ? "my home page" : "available parking spots"
            }`}
          </MDButton>
        </MDBox>
      </Styled.MidSection>
      <Styled.Footer>
        <Styled.FooterMessage>Need something changed?</Styled.FooterMessage>
        <Link href="https://wa.me/01156205669">
          <Styled.FooterLink>whatsapp us</Styled.FooterLink>
        </Link>
      </Styled.Footer>
    </Styled.MainContainer>
  );
};

export default RegistrationSuccess;
