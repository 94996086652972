import { AxiosResponse } from "axios";
import { CheckInRequest, CheckInResponse, Parking } from "models/CheckInState";
import { AxiosGenericResponse } from "models/common";
import { getRequest, postRequest } from "services/client/request";

const apiVersion = "/api";

export const getParkingAPI = async (accessToken: string): Promise<Parking> => {
  try {
    const response: AxiosResponse<
      AxiosGenericResponse<Parking>
    > = await getRequest(`${apiVersion}/carefrees/parking`, accessToken);
    return response.data.data;
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "No active booking found";
  }

  return {} as Parking;
};

export const checkInAPI = async (
  request: CheckInRequest,
  accessToken: string
): Promise<CheckInResponse> => {
  try {
    const response: AxiosResponse = await postRequest(
      `${apiVersion}/carefrees/drivers/check-in`,
      accessToken,
      request
    );
    return response.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const checkOutAPI = async (accessToken: string): Promise<string> => {
  const response: AxiosResponse = await postRequest(
    `${apiVersion}/carefrees/drivers/check-out`,
    accessToken
  );
  return response.data.data;
};
