// react-router-dom components
import { Link } from "react-router-dom";
import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

function ConfirmationCar() {

    return (
        <div className="maincontainer" style={{ overflow: 'auto', paddingBottom: '10%' }}>
            <div class="container" style={{ padding: '30px', width: '100%' }}>
                <h3 class="card-title mt-3 text-center" style={{ color: "#ff69b4", fontFamily: "Fredoka One", paddingBottom: "5%" }}>checkout</h3>
                <div class="" style={{height: '200px', width: '200px', marginLeft: '30%', marginBottom: '5%'}}>
                <CardMedia
                    sx={{ objectFit: "contain", width: '100%', height: '100%', backgroundColor: 'white', borderRadius: 2, }}
                    image={require('../assets/images/drive.png')}
                    title=""
                />
                </div>

                <div class='' style={{ width: '100%', backgroundColor: '#fbebf3', padding: "10%", borderRadius: 20, }}>
                    <h4 class="card-title" style={{ color: "#ff69b4", fontFamily: "sans-serif" }}>Summary</h4>
                    <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'lightGrey', fontWeight: '600' }}>
                        Car Plate Number
                    </div>
                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '10px', paddingTop: '5px' }}>
                        ABC 1234
                    </div>

                    <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'lightGrey', fontWeight: '600' }}>
                        Car Details
                    </div>
                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '10px', paddingTop: '5px' }}>
                        Proton Saga - Red
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'lightGrey', fontWeight: '600' }}>
                                Start Date
                            </div>
                        </div>
                        <div class="col-6" style={{}}>
                            <div class="text-right" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'lightGrey', fontWeight: '600' }}>
                                End Date
                            </div>
                        </div>
                    </div>

                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <div class="row">
                        <div class="col-6">
                            <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '5px' }}>
                                28/2/2023
                            </div>
                        </div>
                        <div class="col-6" style={{}}>
                            <div class="text-right" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '5px'}}>
                                29/2/2023
                            </div>
                        </div>
                    </div>

                    <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', color: 'lightGrey', fontWeight: '600' }}>
                        Reference ID
                    </div>
                    <hr style={{ height: '1.5px', backgroundColor: "lightGrey", margin: "0em" }} />
                    <div class="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '20px', paddingTop: '5px' }}>
                        123456789
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div class="form-group" style={{ padding: '10px' }}>
                        <button type="button" class="btn btn-primary btn-block" style={{ borderRadius: "20px", backgroundColor: "lightGrey", borderColor: "lightGrey", fontSize: '20px', padding: '10px' }}> done </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ConfirmationCar;
