import styled from 'styled-components';

export const MainWrapper = styled.div`
    height: 100vh;
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    z-index: 1000;
`;

export const MainContainer = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    width: 80%;
`;

export const TopHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
`;

export const Title = styled.h3`
    font-family: 'Fredoka One';
    color: #e0479e;
    font-size: 1.2em;
    text-align: center;
    margin: 0;
`;

export const Message = styled.p`
    text-align: center;
    margin: 0;
`;

export const Button = styled.button`
    width: 100%;
    background: #e0479e;
    border: none;
    color: #fff;
    font-family: 'Fredoka One';
    border-radius: 20px;
    padding: 8px;
`;

type SizedBoxProps = {
    height?: number;
    width?: number;
};
export const SizedBox = styled.div<SizedBoxProps>`
    height: ${props => props.height ? `${props.height}px` : 0};
    width: ${props => props.width ? `${props.width}px` : 0};
`;