import styled from "styled-components";
import screenSize from "styles/mediaQueries";

export const MainContainer = styled.div`
  padding: 40px 30px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: space-between;

  ${screenSize.minTablet`

    margin: auto;
      width: 60%;
      `}
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Logo = styled.img`
  width: 100px;
`;

export const Title = styled.h3`
  color: red;
  font-family: "Fredoka One";
  text-align: center;
  color: #e0479e;
  margin: 0;
`;

export const SubTitle = styled.h6`
  color: red;
  font-family: "Fredoka One";
  text-align: center;
  color: #e0479e;
  margin: 0;
`;

type OptionProps = {
  selected?: boolean;
};
export const Option = styled.div<OptionProps>`
  border: 1px solid #fdf1f5;
  background-color: ${(props) => (props.selected ? "#e0479e" : "#F0F1F5")};
  color: ${(props) => (props.selected ? "#fff" : "#e0479e")};
  padding: 10px;
  width: 80%;
  border-radius: 50px;
  transition: background 0.25s ease-in-out;
`;

export const OptionTitle = styled.h4`
  font-size: 1.2em;
  font-family: "Fredoka One";
  text-align: center;
  margin: 0;

  transition: color 0.15s ease-in-out;
`;

export const OptionDesc = styled.p<OptionProps>`
  text-align: center;
  font-size: 0.75em;
  margin: 0;
  width: 80%;
  line-height: 18px;
  color: ${(props) => (props.selected ? "#fff" : "#e0479e")};
  transition: color 0.15s ease-in-out;
`;

export const CheckboxHolder = styled.div`
  display: flex;
  align-items: flex-start;

  p {
    margin: 0;
    margin-left: 10px;
    font-size: 0.8em;
  }

  ${screenSize.minTablet`
  align-items: center;
`}
`;

type ButtonProps = {
  disabled: boolean;
};
export const Button = styled.button<ButtonProps>`
  width: 100%;
  padding: 10px 20px;
  border: none;
  font-family: "Fredoka One";
  color: #fff;
  border-radius: 20px;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#e0479e")};
  transition: background 0.25s ease-in-out;
`;

type SpacerProps = {
  height: number;
};
export const Spacer = styled.div<SpacerProps>`
  height: ${(props) => `${props.height}px`};
`;
