import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ChangeEvent } from "react";
import * as Styled from "./PasswordField.styled";

type PasswordFieldProps = {
    name: string;
    placeholder: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleBlur: (e: any) => void;
    showing: boolean;
    setShowing: () => void;
    value: string;
    error: string|undefined;
    showingPrompt?: boolean
};
const PasswordField: React.FC<PasswordFieldProps> = (props) => {
    return (
        <>
            <Styled.PasswordFieldHolder>
                <Styled.Field
                name={props.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                highlighted={!!props.value}
                error={props.error != null}
                value={props.value}
                type={props.showing ? "text" : "password"}
                placeholder={props.placeholder}
                showingPrompt={props.showingPrompt}
                />
                <div onClick={props.setShowing}>
                {!props.showing ? 
                    <VisibilityOff style={{color: "#bbb", position: "absolute", right: 15, top: "50%", transform: "translateY(-50%)"}} /> :
                    <Visibility style={{color: "#e0479e", position: "absolute", right: 15, top: "50%", transform: "translateY(-50%)"}} />}
                </div>
            </Styled.PasswordFieldHolder>
        </>
    )
};

PasswordField.defaultProps = {
    showingPrompt: false
};

export default PasswordField;