import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useScreenSize from "hooks/useScreenSize";
import {
  getAllDriverParkingSpotMap,
  getSelectedGeoLocationObject,
  setSelectedGeoObject,
  getDefaultView,
  getAllHousePostingsThunk,
  getActiveBookingThunk,
  getLoadingStatus,
  getIsNavigateFromActiveBooking,
  getNavigateToActiveBooking,
} from "redux/slices/homeSlices";

import FilterListComponent from "components/FilterListComponent";
import * as Styled from "./Home.styled";
import { MIN_TABLET } from "styles/mediaQueries";
import PopupCard from "components/PopupCard";

import ListView from "components/ListView";
import { setDefaultView } from "redux/slices/homeSlices";
import CreateForm from "components/DetailsModal";
import MobileFilterList from "components/FilterListComponent/MobileFilterList";
import { getFilterMobileIsOpen } from "../../redux/slices/homeSlices";
import WithAuthWrapper from "hooks/withAuthWrapper";
import {
  getIsApprovedDriver,
  getIsApprovedHomeOwner,
  getIsPendingDriver,
  getIsPendingHomeOwner,
} from "redux/slices/authenticationSlices";
import LoadingComponent from "components/LoadingComponent";
import MapComponent from "./ map";
const HomePage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const [isCreateForm, openCreateForm] = useState<boolean>(false);
  const [screenWidth] = useScreenSize();
  const geoDetails = useSelector(getSelectedGeoLocationObject);
  const defaultView = useSelector(getDefaultView);
  const isFilterMobileOpen = useSelector(getFilterMobileIsOpen);
  const isApprovedDriver = useSelector(getIsApprovedDriver);
  const isApprovedHomeOwner = useSelector(getIsApprovedHomeOwner);
  const isPendingDriver = useSelector(getIsPendingDriver);
  const isPendingHomeOwner = useSelector(getIsPendingHomeOwner);
  const navigateToActiveBooking = useSelector(getNavigateToActiveBooking);
  const isLoading = useSelector(getLoadingStatus);
  const isNavigateFromActiveBooking = useSelector(
    getIsNavigateFromActiveBooking
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (navigateToActiveBooking && (isApprovedDriver || isApprovedHomeOwner)) {
      navigate("/status");
    }

    if (isApprovedDriver || isPendingDriver) {
      if (!isNavigateFromActiveBooking) {
        dispatch(getActiveBookingThunk(accessToken));
        dispatch(setDefaultView(0));
      }

      dispatch(getAllDriverParkingSpotMap(accessToken));
    } else if (isApprovedHomeOwner || isPendingHomeOwner) {
      if (!isNavigateFromActiveBooking) {
        dispatch(getActiveBookingThunk(accessToken));
        dispatch(setDefaultView(1));
      }

      dispatch(getAllHousePostingsThunk(accessToken));
    } else {
      navigate("/intro");
    }
  }, [isApprovedHomeOwner, isApprovedDriver, navigateToActiveBooking]);

  return (
    <>
      {isFilterMobileOpen && <MobileFilterList />}

      <CreateForm isOpen={isCreateForm} onClose={() => openCreateForm(false)} />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <Styled.MainContainer>
          <Styled.AppHeader>
            <FilterListComponent />
          </Styled.AppHeader>
          {defaultView === 0 ? (
            <MapComponent>
              {geoDetails && (
                <Styled.PopupModal
                  //tipSize={5}
                  anchor="bottom"
                  longitude={Number(geoDetails.longitude)}
                  latitude={Number(geoDetails.latitude)}
                  closeOnClick={false}
                  // offsetLeft={20}
                  onClose={() => dispatch(setSelectedGeoObject(undefined))}
                >
                  <PopupCard
                    geoDetails={geoDetails}
                    openDetails={() => openCreateForm(true)}
                  />
                </Styled.PopupModal>
              )}
            </MapComponent>
          ) : (
            <ListView />
          )}
        </Styled.MainContainer>
      )}
    </>
  );
};

export default WithAuthWrapper(HomePage);
