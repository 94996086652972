import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { Route, useNavigate } from "react-router-dom";
import { HomeOwnerStepTwo } from "./HomeOwnerStepTwo";

function SubmittedDriver () {
// const navigate = useNavigate();

//     const toAddress = () =>{
//         navigate('/Address');
//     }

    return (
      <div className="maincontainer">
          <div class="container">
  
                {/* <div class="card bg-light">
                <article class="card-body mx-auto" style={{maxWidth: "400px", backgroundColor: "white"}}> */}
                    <a class="back-button" href={'/driverCar'}><FontAwesomeIcon icon={faChevronLeft} style={{color: "grey"}}></FontAwesomeIcon></a>
                    <h3 class="card-title mt-3 text-center" style={{color: "#ff69b4", fontFamily: "Fredoka One", paddingBottom: "5%"}}>driver / parking seeker</h3>
                    <div class="col-3 text-center" style={{paddingTop: "10%", paddingLeft: "40%"}}><FontAwesomeIcon icon={faStopwatch} size = '5x' style={{color:"#ff69b4", paddingTop:"30px", paddingLeft:"10px"}}></FontAwesomeIcon></div>
                    <h6 class="card-title mt-3 text-center" style={{paddingTop: "15px", color: "#ff69b4", fontFamily: "Fredoka One", paddingBottom: "5%"}}>thank you for registering!</h6>
                    <div class="text-center" style={{paddingTop: "5px", fontSize: "14px",}}>
                        <div class="" style={{color: "#ff69b4", fontFamily: "sans-serif"}}>You will receive a notification once</div>
                        <div class="" style={{color: "#ff69b4", fontFamily: "sans-serif"}}>your application has been processed.</div>
                        <div class="" style={{color: "#ff69b4", fontFamily: "sans-serif"}}>Kindly note that this will take 3 to 5</div>
                        <div class="" style={{color: "#ff69b4", fontFamily: "sans-serif"}}>working days.</div>
                    </div>   
                    <div class="text-center" style={{paddingTop: "35%"}}>
                        <div class="" style={{fontSize: "12px", fontFamily: "sans-serif"}}>Need something changed?</div>
                        <div class="" style={{fontSize: "12px", color: "#ff69b4", fontFamily: "sans-serif"}}>whatsapp us</div>
                    </div>                                                                   
             {/* </article>
             </div>  */}
             </div>
             </div>
     )
};


export default SubmittedDriver