import { AxiosResponse } from "axios";
import { AllBooking, BookingStatus } from "models/BookingStatus";
import { GeoObjectDetail, HomePosting } from "models/HomeState";
import { getRequest, postRequest } from "services/client/request";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";

const apiVersion = "/api";

export const getPostingDuration = async (
  request: any,
  accessToken: string
): Promise<number> => {
  const response: AxiosResponse<
    AxiosGenericResponse<number>
  > = await getRequest(
    `${apiVersion}/carefrees/drivers/posting-duration?id=${request.id}&date=${request.date}`,
    accessToken
  );
  return response.data.success ? response.data.data : 0;
};

export const getDriversMap = async (
  accessToken: string
): Promise<GeoObjectDetail[]> => {
  const response: AxiosResponse<
    AxiosGenericResponse<GeoObjectDetail[]>
  > = await getRequest(`${apiVersion}/carefrees/drivers/map`, accessToken);
  return response.data.success ? response.data.data : [];
};

export const getHousePosting = async (
  accessToken: string
): Promise<HomePosting[]> => {
  try {
    const response: AxiosResponse<
      AxiosGenericResponse<HomePosting[]>
    > = await getRequest(
      `${apiVersion}/carefrees/homeowners/postings`,
      accessToken
    );
    return response.data.status ? response.data.data : [];
  } catch (e) {
    return [];
  }
};

export const createHousePosting = async (
  request: any,
  accessToken: string
): Promise<AxiosResponse<any>> => {
  return postRequest(
    `${apiVersion}/carefrees/homeowners/post`,
    accessToken,
    request
  );
};

export const deleteHousePosting = async (
  id: number,
  accessToken: string
): Promise<AxiosResponse<void>> => {
  return postRequest(
    `${apiVersion}/carefrees/homeowners/posting/${id}/cancel`,
    accessToken
  );
};

export const getActiveBooking = async (
  accessToken: string
): Promise<BookingStatus> => {
  const response: AxiosResponse<
    AxiosGenericResponse<BookingStatus>
  > = await getRequest(`${apiVersion}/carefrees/active_booking`, accessToken);
  return response.data.status ? response.data.data : ({} as BookingStatus);
};

export const getAllBookingsApi = async (
  accessToken: string
): Promise<AllBooking> => {
  const response: AxiosResponse<
    AxiosGenericResponse<AllBooking>
  > = await getRequest(`${apiVersion}/carefrees/all_bookings`, accessToken);
  return response.data.status ? response.data.data : ({} as AllBooking);
};
