import { FormValues } from "./index";

export const validateForm = (values: FormValues) => {
  const errors = {} as any;
  const regexPhone = /^\d{10,11}$/;
  if (!values.email) {
    errors.email = "Email address is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email =
      "Invalid email format. Please enter a valid email address (e.g. example@email.com).";
  }

  if (!values.password) {
    errors.password = "Create Password is required";
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = "Password must contain at least one uppercase letter";
  } else if (!/[a-z]/.test(values.password)) {
    errors.password = "Password must contain at least one lowercase letter";
  } else if (!/[0-9]/.test(values.password)) {
    errors.password = "Password must contain at least one number";
  } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(values.password)) {
    errors.password = "Password must contain at least one special character";
  } else if (values.password.length < 8) {
    errors.password = "Minimum password length is 8";
  } else if (values.password.length > 30) {
    errors.password = "Maximum password length is 30";
  }

  if (!values.fname) {
    errors.fname = "First Name is required";
  } else if (!/^[a-zA-Z\s]+$/.test(values.fname)) {
    errors.fname =
      "First Name can only contain alphabetical characters and spaces";
  }

  if (!values.lname) {
    errors.lname = "Last Name is required";
  } else if (!/^[a-zA-Z\s]+$/.test(values.lname)) {
    errors.lname =
      "Last Name can only contain alphabetical characters and spaces";
  }

  if (!values.phone) {
    errors.phone = "Mobile Number is required";
  } else if (!regexPhone.test(values.phone)) {
    errors.phone =
      "Please enter a 10 or 11-digit mobile number without spaces or special characters";
  } else if (
    [
      "0000000000",
      "1111111111",
      "0111111111",
      "0123456789",
      "9876543210"
    ].indexOf(values.phone) !== -1
  ) {
    errors.phone = "Please provide a valid mobile number";
  } else if (!values.otp) {
    errors.otp = "OTP Code is required";
  } else if (!/^[0-9]{6}$/.test(values.otp)) {
    errors.otp = "Your OTP code must be 6 digits long and contain only numbers";
  }

  if (values.password != values.password_confirmation) {
    errors.password_confirmation =
      "The password you entered is not the same as the above";
  }

  return errors;
};
