import styled from "styled-components";

type AutoCompleteHolderProps = {
  highlighted: boolean;
};
export const AutoCompleteHolder = styled.div<AutoCompleteHolderProps>`
  width: 100%;
  border-radius: 20px;
  border: 1px solid ${(props) => (props.highlighted ? "#F0F1F5" : "#F0F1F5")};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const AutoCompleteField = styled.input`
  width: 100%;
  border: none;
  background: #f0f1f5;
  padding: 10px 20px;
  outline: none;
`;

export const AutoCompleteListHolder = styled.div`
  padding: 0px 20px;
  padding-bottom: 10px;
`;

export const AutoCompleteList = styled.div`
  padding-bottom: 10px;
  color: #555;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:last-child {
    padding: 0;
  }
`;
