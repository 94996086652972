import { createAsyncThunk, createSlice, Store } from "@reduxjs/toolkit";
import { CarBrand, CarColor, CarModel } from "models/Cars";
import {
  BookingRequest,
  DriverRegistrationStepOneRequest,
  DriverRegistrationStepTwoRequest,
  EmergencyContactUpdateRequest,
} from "models/Driver";
import { Booking, DriverState } from "models/DriverState";
import { StoreState } from "models/StoreState";
import {
  getCarBrandsAPI,
  getCarColorsAPI,
  getCarModelsByBrandAPI,
} from "services/api/carApi";
import {
  bookParkingSpotAPI,
  checkoutAPI,
  getApplicationStatusAPI,
  submitApplicationAPI,
  updateEmergencyContact,
  existingDriverRegisterationAPI,
  cancelBookingAPI,
} from "services/api/driverApi";
import { setIsLoading as setIsLoadingAuth } from "./authenticationSlices";

const driverSlice = createSlice({
  name: "driver",
  initialState: {
    isLoading: true,
    success: false,
    carBrands: [],
    carModels: [],
    carColors: [],
    applicationStatus: { first_step: null, state: 0 },
    isLoadingBooking: false,
    booking: null,
    redirectUrl: "",
    isMissingEmergencyInfo: false,
  } as DriverState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    },
    setCarModels(state, action) {
      state.carModels = action.payload;
    },
    setCarBrands(state, action) {
      state.carBrands = action.payload;
    },
    setCarColors(state, action) {
      state.carColors = action.payload;
    },
    setApplicationStatus(state, action) {
      state.applicationStatus = action.payload;
    },
    setIsLoadingBooking(state, action) {
      state.isLoadingBooking = action.payload;
    },
    setBooking(state, action) {
      state.booking = action.payload;
    },
    setRedirectUrl(state, action) {
      state.redirectUrl = action.payload;
    },
    setIsMissingEmergencyInfo(state, action) {
      state.isMissingEmergencyInfo = action.payload;
    },
  },
});

type StepRegistrationRequest = {
  accessToken: string;
  driver: DriverRegistrationStepOneRequest | DriverRegistrationStepTwoRequest;
};
export const submitApplicationThunk = createAsyncThunk(
  "driver/submitApplicationThunk",
  async (request: StepRegistrationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, driver } = request;
    dispatch(driverSlice.actions.setIsLoading(true));
    const success = await submitApplicationAPI(driver, accessToken);
    dispatch(driverSlice.actions.setIsLoading(false));
    dispatch(driverSlice.actions.setSuccess(success));
  }
);

export const existingDriverRegisterationThunk = createAsyncThunk(
  "driver/existingDriverRegisterationThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setIsLoadingAuth(true));
    const success = await existingDriverRegisterationAPI(accessToken);
    dispatch(setIsLoadingAuth(false));
    dispatch(driverSlice.actions.setIsMissingEmergencyInfo(true));
  }
);

type UpdateEmegencyContactType = {
  accessToken: string;
  emergencyContact: EmergencyContactUpdateRequest;
};
export const updateEmergencyContactThunk = createAsyncThunk(
  "driver/updateEmergencyContactThunk",
  async (request: UpdateEmegencyContactType, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, emergencyContact } = request;
    dispatch(setIsLoadingAuth(true));
    const success = await updateEmergencyContact(emergencyContact, accessToken);
    dispatch(setIsLoadingAuth(false));
    dispatch(driverSlice.actions.setSuccess(success));
    dispatch(driverSlice.actions.setIsMissingEmergencyInfo(false));
  }
);

export const getCarsOptionsThunk = createAsyncThunk(
  "driver/getCarsOptionsThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    const [carBrands, carColors] = await Promise.all([
      getCarBrandsAPI(accessToken),
      getCarColorsAPI(accessToken),
    ]);

    dispatch(driverSlice.actions.setCarBrands(carBrands));
    dispatch(driverSlice.actions.setCarColors(carColors));
  }
);

type GetCarModelsByBrandType = {
  accessToken: string;
  brandId: number;
};
export const getCarModelsByBrandThunk = createAsyncThunk(
  "driver/getCarModelsByBrandThunk",
  async (request: GetCarModelsByBrandType, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, brandId } = request;
    const carModels = await getCarModelsByBrandAPI(accessToken, brandId);

    dispatch(driverSlice.actions.setCarModels(carModels));
  }
);

export const getApplicationStatusThunk = createAsyncThunk(
  "driver/getApplicationStatusThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(driverSlice.actions.setIsLoading(true));
    const applicationStatus = await getApplicationStatusAPI(accessToken);
    dispatch(driverSlice.actions.setApplicationStatus(applicationStatus));
    dispatch(driverSlice.actions.setIsLoading(false));
  }
);

type BookingType = {
  accessToken: string;
  booking: BookingRequest;
};
export const bookingThunk = createAsyncThunk(
  "drivers/bookingThunk",
  async (request: BookingType, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, booking } = request;
    dispatch(driverSlice.actions.setIsLoadingBooking(true));
    const bookingResponse = await bookParkingSpotAPI(booking, accessToken);
    dispatch(driverSlice.actions.setIsLoadingBooking(false));
    dispatch(driverSlice.actions.setBooking(bookingResponse));
  }
);

type CheckoutType = {
  accessToken: string;
  bookingID: number;
};
export const checkoutThunk = createAsyncThunk(
  "drivers/checkoutThunk",
  async (request: CheckoutType, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, bookingID } = request;
    dispatch(driverSlice.actions.setIsLoadingBooking(true));
    const url = await checkoutAPI(bookingID, accessToken);
    dispatch(driverSlice.actions.setIsLoadingBooking(false));
    dispatch(driverSlice.actions.setRedirectUrl(url));
    // dispatch(driverSlice.actions.setBooking(null));
  }
);

type CancelBookingType = {
  accessToken: string;
  bookingID: number;
};
export const cancelBookingThunk = createAsyncThunk(
  "drivers/cancelBookingThunk",
  async (request: CancelBookingType, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, bookingID } = request;
    dispatch(driverSlice.actions.setIsLoadingBooking(true));
    const success = await cancelBookingAPI(bookingID, accessToken);
    dispatch(driverSlice.actions.setIsLoadingBooking(false));
    dispatch(driverSlice.actions.setSuccess(success));
    dispatch(driverSlice.actions.setBooking(null));
  }
)

export const getIsMissingEmergencyInfo = (state: StoreState): boolean =>
  state.driver.isMissingEmergencyInfo;

export const getLoadingStatus = (state: StoreState): boolean =>
  state.driver.isLoading;
export const getSuccessStatus = (state: StoreState): boolean =>
  state.driver.success;

export const getLoadingBookingStatus = (state: StoreState): boolean =>
  state.driver.isLoadingBooking;
export const getBooking = (state: StoreState): Booking | null =>
  state.driver.booking;
export const getRedirectUrl = (state: StoreState): string =>
  state.driver.redirectUrl;

export const getCarModels = (state: StoreState): CarModel[] =>
  state.driver.carModels;
export const getCarBrands = (state: StoreState): CarBrand[] =>
  state.driver.carBrands;
export const getCarColors = (state: StoreState): CarColor[] =>
  state.driver.carColors;

export const getApplicationStatus = (state: StoreState) =>
  state.driver.applicationStatus;

export const {
  setIsLoading,
  setSuccess,
  setBooking,
  setRedirectUrl,
  setIsMissingEmergencyInfo,
} = driverSlice.actions;
export default driverSlice.reducer;
