import styled, { createGlobalStyle } from "styled-components";
import Button from "@material-ui/core/Button";
import screenSize from "./styles/mediaQueries";

const GlobalStyle = createGlobalStyle`
&&& {
    .MuiDialog-root {
      z-index: 9999999;
    }
    .MuiMenu-root {
      z-index: 9999999;
    }
    .css-1sucic7{
      z-index: 9999999;
    }
    .css-1nf2ro8{
      z-index: 9999999;
    }
    .maincontainer {

      ${screenSize.minTablet`

      margin: auto;
        width: 50%;
        `}
    }
    
  }

`;
export default GlobalStyle;
export const MainContainer = styled.div`
  width: 100%;
  display: grid;

  ${screenSize.minTablet`
  display: inline-flex;
  `};
`;

export const MainHeader = styled.p`
  font-family: "Spectral", serif;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;
  ${screenSize.minTablet`
  font-size: 64px;
  `};
`;

export const SubHeader = styled.p`
  color: #abb2c4;
  line-height: 1.5;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;
  margin-bottom: 20px;
`;

export const FoodBankButton = styled(Button)`
  &&& {
    color: white;
    margin-left: 5px;
    background-color: #2d65fc;
    text-transform: capitalize;
    border-radius: 6px;
  }
`;

export const NeedHelpButton = styled(Button)`
  &&& {
    color: white;
    background-color: #f86a36;
    text-transform: capitalize;
    border-radius: 6px;
  }
`;
export const LoginButton = styled(Button)`
  &&& {
    color: white;
    margin-left: 5px;
    background-color: rgb(85 95 121);
    text-transform: capitalize;
    border-radius: 6px;
  }
`;

export const AppHeader = styled.div`
  background: #232a3d;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  ${screenSize.minTablet`
    width: 400px;
  `}
`;

export const HeaderContainer = styled.div`
  padding-left: 30px;
  padding-right: 20px;
`;
export const Footer = styled.footer`
  color: #838a9e;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 600;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  text-decoration: underline;
`;
export const HeaderMain = styled.header`
  display: flex;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 3%;
  left: 0;
  padding-left: 30px;
  align-items: start;
  text-align: left;
  ${screenSize.minTablet`
	top: 5%;
	left: 0;
	`}
`;
export const HeaderTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  font-family: "Spectral", serif;
  margin: 0 1px;
  ${screenSize.minTablet`
	margin: 0;
	font-size: 20px;
	`}
`;
export const Bluelogo = styled.img`
  height: 1em;
  width: 1.3em;
  margin-right: 2px;
  ${screenSize.minTablet`
width: 2em;
`}
`;
