import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import CardMedia from '@mui/material/CardMedia';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import MDBox from "components/MDBox";
import { Badge } from "reactstrap";
import MDAvatar from "components/MDAvatar";
import { useDispatch, useSelector } from "react-redux";
import { getCount, getHistories, getHistoryThunk, getLoadingStatus, getTotalDuration, setSelectedHistory } from "redux/slices/historySlices";
import LoadingComponent from "components/LoadingComponent";
import { navigate } from "@reach/router";


const History = () => {
    const accessToken = localStorage.getItem("carching-token") || "";
    const dispatch = useDispatch();
    const isLoading = useSelector(getLoadingStatus);
    const count = useSelector(getCount);
    const totalDuration = useSelector(getTotalDuration);
    const histories = useSelector(getHistories);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getHistoryThunk(accessToken));
    }, [dispatch]);

    return (
        <>
            {isLoading && <LoadingComponent />}
            {!isLoading && (
                <div className="maincontainer" style={{ overflow: 'auto', paddingBottom: '10%' }}>
                    <div className="container" style={{ paddingLeft: '30px', width: '100%' }}>
                        <Link to="/home"><FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ccc", paddingTop: "30px" }}></FontAwesomeIcon></Link>
                        <h3 className="card-title text-center" style={{ color: "#e0479e", fontFamily: "Fredoka One", paddingBottom: "15%" }}>history</h3>

                        <div className="row">
                            <div className="col-6"></div>
                            <div className="col-3">
                                <h4 className="text-right" style={{ color: "#52489C", fontFamily: "Fredoka One", marginBottom: '-0.25rem' }}>{count}</h4>
                                <p className="text-right" style={{ color: "#52489C", fontSize: '12px' }}>parkings</p>
                            </div>
                            <div className="col-3">
                                <h4 className="card-title text-center" style={{ color: "#52489C", fontFamily: "Fredoka One", marginBottom: '-0.25rem' }}>{totalDuration}</h4>
                                <p className="text-center" style={{ color: "#52489C", fontSize: '12px' }}>hours</p>
                            </div>
                        </div>

                        <div className='' style={{ padding: '5%' }}>
                            {histories.map(history => (
                                <div className="container" style={{ width: '100%', borderRadius: '40px', boxShadow: '0px 2px 6px 1px lightGrey', marginBottom: '10px' }}>
                                    <div className="row" style={{ padding: "5px", fontSize: '14px' }}>
                                        <div className="col-2 text-left">{history.date ?? "-"}</div>
                                        <div className="col-4 text-center">{history.plate_number}</div>
                                        <div className="col-4 text-center">{history.duration_short}</div>
                                        <div className="col-2">
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                onClick={() => {
                                                    dispatch(setSelectedHistory(history));
                                                    navigate("/historyDetail");
                                                }}
                                                style={{
                                                    color: "#e0479e",
                                                    width: "10px",
                                                    height: "10px",
                                                    display: "inline-block",
                                                    borderRadius: "60%",
                                                    border: "solid 1px #e0479e",
                                                    padding: "4px",
                                                }}
                                            ></FontAwesomeIcon>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>
                </div>
            )}
        </>
    )
};


export default History