import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CurrentHomeOwner,
  HomeOwnerRegistrationStepOneRequest,
  HomeOwnerRegistrationStepTwoRequest,
} from "models/HomeOwner";
import { HomeOwnerState } from "models/HomeOwnerState";
import { StoreState } from "models/StoreState";
import {
  getApplicationStatusAPI,
  getCurrentHomeDetailsAPI,
  submitApplicationAPI,
} from "services/api/homeOwnerApi";
import { validateAuthentication } from "./authenticationSlices";

const homeOwnerSlice = createSlice({
  name: "homeOwner",
  initialState: {
    isLoading: true,
    success: false,
    applicationStatus: { first_step: null, state: 0 },
    currentHomeOwnerDetails: {},
  } as HomeOwnerState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    },
    setApplicationStatus(state, action) {
      state.applicationStatus = action.payload;
    },
    setCurrentHomeOwnerDetails(state, action) {
      state.currentHomeOwnerDetails = action.payload;
    },
  },
});

type StepOneRegistrationRequest = {
  accessToken: string;
  homeOwner:
    | HomeOwnerRegistrationStepOneRequest
    | HomeOwnerRegistrationStepTwoRequest;
};
export const submitApplicationThunk = createAsyncThunk(
  "homeOwner/submitApplicationThunk",
  async (request: StepOneRegistrationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, homeOwner } = request;
    dispatch(homeOwnerSlice.actions.setIsLoading(true));
    const success = await submitApplicationAPI(homeOwner, accessToken);
    dispatch(homeOwnerSlice.actions.setSuccess(success));
    dispatch(validateAuthentication(accessToken));
    dispatch(homeOwnerSlice.actions.setIsLoading(false));
  }
);

export const getApplicationStatusThunk = createAsyncThunk(
  "homeOwner/getapplicationStatusThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(homeOwnerSlice.actions.setIsLoading(true));
    const applicationStatus = await getApplicationStatusAPI(accessToken);
    dispatch(homeOwnerSlice.actions.setApplicationStatus(applicationStatus));
    dispatch(homeOwnerSlice.actions.setIsLoading(false));
  }
);

export const getCurrentHomeOwnerThunk = createAsyncThunk(
  "homeOwner/getCurrentHomeOwnerThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(homeOwnerSlice.actions.setIsLoading(true));
    const details = await getCurrentHomeDetailsAPI(accessToken);
    dispatch(homeOwnerSlice.actions.setCurrentHomeOwnerDetails(details));
    dispatch(homeOwnerSlice.actions.setIsLoading(false));
  }
);

export const getLoadingStatus = (state: StoreState): boolean =>
  state.homeOwner.isLoading;
export const getSuccessStatus = (state: StoreState): boolean =>
  state.homeOwner.success;

export const getApplicationStatus = (state: StoreState) =>
  state.homeOwner.applicationStatus;

export const getCurrentHomeOwnerDetails = (
  state: StoreState
): CurrentHomeOwner => state.homeOwner.currentHomeOwnerDetails;

export const { setIsLoading, setSuccess } = homeOwnerSlice.actions;
export default homeOwnerSlice.reducer;
