import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import MDTypography from "components/MDTypography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import BookingItem from "./chilItem";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllBookings,
  getAllBookingsThunk,
  getFilterMobileIsOpen,
  getLoadingStatus,
  openFilterMobile,
} from "redux/slices/homeSlices";
import LoadingComponent from "components/LoadingComponent";
import useScreenSize from "hooks/useScreenSize";
import { MIN_TABLET } from "styles/mediaQueries";
import KebabIcon from "assets/kebab.svg";
import MobileFilterList from "components/FilterListComponent/MobileFilterList";
import WithAuthWrapper from "hooks/withAuthWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
}));
interface BookingStatus {
  active: boolean;
  upcoming: boolean;
  ended: boolean;
  cancelled: boolean;
}
const BookingPage = () => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("carching-token") || "";
  const allBookings = useSelector(getAllBookings);
  const isLoading = useSelector(getLoadingStatus);
  const classes = useStyles();
  const [screenWidth] = useScreenSize();
  const isFilterMobileOpen = useSelector(getFilterMobileIsOpen);
  const [expanded, setExpanded] = useState<BookingStatus>({
    active: true,
    upcoming: true,
    ended: true,
    cancelled: true,
  });

  useEffect(() => {
    if (Object.keys(allBookings).length === 0) {
      dispatch(getAllBookingsThunk(accessToken));
    }
  }, []);

  const handleChange = (panel: string) => {
    let update = { ...expanded, active: !expanded[panel] };
    switch (panel) {
      case "upcoming":
        update = { ...expanded, upcoming: !expanded[panel] };
        break;
      case "ended":
        update = { ...expanded, ended: !expanded[panel] };
        break;
      case "cancelled":
        update = { ...expanded, cancelled: !expanded[panel] };
        break;
      default:
        update = { ...expanded, active: !expanded[panel] };
        break;
    }
    setExpanded(update);
  };

  return (
    <>
      {isFilterMobileOpen && <MobileFilterList />}
      {isLoading && <LoadingComponent />}
      {!isLoading && (
        <div style={{ paddingTop: 30 }}>
          <img
            onClick={() => dispatch(openFilterMobile(true))}
            alt="logo"
            src={KebabIcon}
            style={{
              position: "fixed",
              top: 20,
              left: 20,
              height: "1.5em",
              width: "1.5em",
              cursor: "pointer",
            }}
          />
          <MDTypography
            variant="h4"
            color="text"
            mt={5}
            mb={2}
            pb={2}
            sx={{
              textAlign: "center",
              color: "#e0479e",
              fontFamily: "Fredoka One",
            }}
          >
            bookings
          </MDTypography>
          {Object.keys(allBookings).map((keyValue) => (
            <Accordion
              key={keyValue}
              style={{ boxShadow: "none", margin: 0 }}
              expanded={expanded[keyValue]}
              onChange={() => handleChange(keyValue)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.root}
                style={{ margin: 0, background: "#f0f2f5", minHeight: 0 }}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText
                      style={{
                        color: "#7b809a",
                        textAlign: "center",
                        margin: 0,
                        textTransform: "capitalize",
                      }}
                      primary={`${keyValue} bookings`}
                    />
                  </ListItemButton>
                </ListItem>
              </AccordionSummary>
              <AccordionDetails
                style={{ width: "100%", padding: 0, display: "block" }}
              >
                <BookingItem
                  bookings={allBookings[keyValue]}
                  title={keyValue}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </>
  );
};
export default WithAuthWrapper(BookingPage);
