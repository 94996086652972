import { FormValues } from "./index";

export const validateForm = (values: FormValues) => {
  let errors: any = {};
  const regexPhone = /^\d{10,11}$/;
  if (!values.emergency_contact_name) {
    errors.emergency_contact_name = "Please fill this field";
  } else if (!/^[a-zA-Z\s]+$/.test(values.emergency_contact_name)) {
    errors.emergency_contact_name =
      "Name can only contain alphabetical characters and spaces";
  }

  if (!values.emergency_contact_relationship) {
    errors.emergency_contact_relationship = "Please fill this field";
  }

  if (!values.emergency_contact_num) {
    errors.emergency_contact_num = "Please fill this field";
  } else if (!regexPhone.test(values.emergency_contact_num)) {
    errors.emergency_contact_num =
      "Please enter a 10 or 11-digit mobile number without spaces or special characters";
  }

  return errors;
};
