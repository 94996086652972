import { Checkbox } from "@mui/material";
import styled from "styled-components";
import screenSize from "styles/mediaQueries";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  align-items: center;
  height: 100vh;
  justify-content: space-between;
  background-color: white;
  ${screenSize.minTablet`

  margin: auto;
	width: 50%;
	`}
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Logo = styled.img`
  width: 60px;
`;

export const Title = styled.h3`
  color: red;
  font-family: "Fredoka One";
  text-align: center;
  color: #e0479e;
`;

export const FormHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type FieldProps = {
  highlighted: boolean;
  error: boolean;
};
export const Field = styled.input<FieldProps>`
  width: 100%;
  border-radius: 30px;
  border: 1px solid
    ${(props) =>
      props.error ? "red" : props.highlighted ? `#e0479e` : `#F0F1F5`};
  padding: 10px 20px;
  outline: none;
  background: #f0f1f5;
  ::placeholder {
    color: #d5d5d5;
  }
`;

export const FieldErrorMessage = styled.p`
  color: red;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 0.8em;
`;

export const PasswordFieldHolder = styled.div`
  position: relative;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px 20px;
  border: none;
  font-family: "Fredoka One";
  color: #fff;
  border-radius: 20px;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#e0479e")};
`;

export const ForgotPasswordLink = styled.span`
  font-family: "Fredoka One";
  color: #e0479e;
  font-size: 0.9em;
`;

export const SignUpText = styled.p`
  font-size: 0.9em;
`;

export const SignUpLink = styled.span`
  font-family: "Fredoka One";
  color: #e0479e;
`;

type SpacerProps = {
  height: number;
};
export const Spacer = styled.div<SpacerProps>`
  height: ${(props) => `${props.height}px`};
`;
