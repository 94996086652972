import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import CardMedia from '@mui/material/CardMedia';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import { Badge } from "reactstrap";
import MDAvatar from "components/MDAvatar";
import { getSelectedHistory } from "redux/slices/historySlices";
import { getIsApprovedDriver } from "redux/slices/authenticationSlices";


const HistoryDetail = () => {
    const selected = useSelector(getSelectedHistory);
    const isApprovedDriver = useSelector(getIsApprovedDriver);
    const navigate = useNavigate();

    return (
        <div className="maincontainer" style={{ padding: '0%' }}>
            <div className="container" style={{ padding: '0%' }}>
                <div className="container" style={{ color: "#ff69b4", fontFamily: "Fredoka One", padding: '5%', width: "100%", height: '200px', backgroundColor: 'rgba(82, 72, 156, 0.1)', borderRadius: "0 0 9% 5%" }}>
                    <div className="container text-center" style={{ height: '150px', width: '150px', borderRadius: "50%", backgroundColor: 'rgba(82, 72, 156, 0.1)', border: "10px solid #fff", boxShadow: '0px 10px 10px lightGrey', padding: '10px', display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={require('../../assets/images/flag.png')} style={{ width: 70 }} />
                    </div>
                </div>

                <div className='text-center' style={{ width: '100%', backgroundColor: 'white', padding: "0% 10% 10% 10%"}}>
                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', fontWeight: '600', }}>
                        {isApprovedDriver ? "Unit No" : "Driver Plate No"}
                    </div>
                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '10px', paddingTop: '5px', color: "#6c64ac", }}>
                        {isApprovedDriver ? selected?.unit_no : selected?.plate_number}
                    </div>

                    <hr style={{ height: '1px', backgroundColor: "lightGrey", margin: "0em" }} />

                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', fontWeight: '600', }}>
                        Street Address
                    </div>
                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '10px', paddingTop: '5px', color: "#6c64ac", }}>
                       {selected?.address} 
                    </div>

                    <hr style={{ height: '1px', backgroundColor: "lightGrey", margin: "0em" }} />

                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', fontWeight: '600', }}>
                        Check In Time
                    </div>
                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '10px', paddingTop: '5px', color: "#6c64ac", }}>
                        {selected?.check_in_at}
                    </div>

                    <hr style={{ height: '1px', backgroundColor: "lightGrey", margin: "0em" }} />

                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', fontWeight: '600', }}>
                        Duration
                    </div>
                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '10px', paddingTop: '5px', color: "#6c64ac", }}>
                        {selected?.duration}
                    </div>

                    <hr style={{ height: '1px', backgroundColor: "lightGrey", margin: "0em" }} />

                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', fontWeight: '600', }}>
                        Check Out Time
                    </div>
                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '10px', paddingTop: '5px', color: "#6c64ac", }}>
                        {selected?.check_out_at}
                    </div>

                    <hr style={{ height: '1px', backgroundColor: "lightGrey", margin: "0em" }} />

                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingTop: '10px', paddingBottom: '5px', fontWeight: '600', }}>
                        Days Remaining
                    </div>
                    <div className="" style={{ fontFamily: "sans-serif", fontSize: '18px', paddingBottom: '10px', paddingTop: '5px', color: "#6c64ac", }}>
                        {selected?.remaining}
                    </div>

                    <br />

                    <div className="form-group" style={{ padding: '10px' }}>
                        <button type="button" className="btn btn-primary btn-block" style={{ fontFamily: "Fredoka One", borderRadius: "20px", backgroundColor: "#ff69b4", borderColor: "#ff69b4", fontSize: '16px', padding: '5px' }} onClick={() => navigate(-1)}>
                            back
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
};


export default HistoryDetail