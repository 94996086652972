import { AxiosError, AxiosResponse } from "axios";
import { getRequest, postRequest } from "services/client/request";
import { UserResponse, User } from "models/User";
import { postRequestWithoutToken } from "../client/request";
import { LoginRequest } from "models/User";
import { CurrentUserStatusResponse, NewUser } from "models/Authentication";
import { AxiosGenericResponse } from "models/common";

const apiVersion = "/api";

export const login = async (
    loginCredentials: LoginRequest
): Promise<UserResponse> => {
    try {
        const response: AxiosResponse<UserResponse> = await postRequestWithoutToken(
            `${apiVersion}/login`,
            loginCredentials
        );
        const userResponse: UserResponse = response.data
            ? response.data
            : ({} as UserResponse);
        return userResponse;
    } catch (e) {
        console.error(e);
        return {} as UserResponse;
    }
};

export const getUserDetail = async (
    accessToken: string
): Promise<User | undefined> => {
    try {
        const response: AxiosResponse<User> = await getRequest(
            `${apiVersion}/user`,
            accessToken
        );
        const userDetails: User | undefined = response.data
            ? response.data
            : undefined;
        return userDetails;
    } catch (e) {
        console.error(e);
        return;
    }
};

export const signUp = async (signUpRequest: NewUser): Promise<UserResponse> => {
    try {
        const response: AxiosResponse<UserResponse> = await postRequestWithoutToken(
            `${apiVersion}/register`,
            signUpRequest
        );
        const userResponse: UserResponse = response.data
            ? response.data
            : ({} as UserResponse);
        return userResponse;
    } catch (error: any) {
        const errorResponse: UserResponse = error.response.data ? error.response.data : ({} as UserResponse);
        return errorResponse;
    }
};

export const forgetPassword = async (email: string): Promise<void> => {
    await postRequestWithoutToken(`${apiVersion}/forgot`, email);
};

export const getCurrentUserCarefreeStatus = async (accessToken: string): Promise<CurrentUserStatusResponse> => {
    const response: AxiosResponse<CurrentUserStatusResponse> = await getRequest(`${apiVersion}/carefrees/current`, accessToken);
    const currentStatus: CurrentUserStatusResponse = response.data;
    return currentStatus;
};

export const requestOTPAPI = async (phone: string): Promise<string> => {
    try {
        const response: AxiosResponse = await postRequestWithoutToken(
            `${apiVersion}/request-otp`,
            {phone}
        );
        return "success";
    } catch (error: any) {
        const response = error.response.data.error;
        return response;
    }
}

export const resetPassword = async (
    email: string
): Promise<boolean> => {
    try {
        const response: AxiosResponse = await postRequestWithoutToken(`${apiVersion}/forgot`, {email});
        return response.data.success;
    } catch {
        return false;
    }
};

export const resendVerificationEmail = async (
    email: string
): Promise<boolean> => {
    try {
        const response: AxiosResponse = await postRequestWithoutToken(`${apiVersion}/users/resend`, {email});
        return response.data.success;
    } catch {
        return false;
    }
}