import RegistrationSuccess from "components/RegistrationSuccess";
import HomeOwnerStepOne from "components/HomeOwnerStepOne";
import HomeOwnerStepTwo from "components/HomeOwnerStepTwo";
import React, { useEffect } from "react";
import {
  HomeOwnerRegistrationStepOneRequest,
  HomeOwnerRegistrationStepTwoRequest,
} from "models/HomeOwner";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationStatus,
  getApplicationStatusThunk,
  getLoadingStatus,
  submitApplicationThunk,
  setSuccess,
  getSuccessStatus,
} from "redux/slices/homeOwnerSlices";
import WithAuthWrapper from "hooks/withAuthWrapper";
import LoadingComponent from "components/LoadingComponent";
import { getIsRegisteringDriver } from "redux/slices/authenticationSlices";
import { useNavigate } from "react-router-dom";

const HomeOwnerRegister: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const [page, setPage] = React.useState<number>(0);
  const success = useSelector(getSuccessStatus);
  const isLoading = useSelector(getLoadingStatus);
  const applicationStatus = useSelector(getApplicationStatus);
  const isRegisteringDriver = useSelector(getIsRegisteringDriver);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isRegisteringDriver) {
      navigate("/driver/register");
    }
  }, [isRegisteringDriver]);

  useEffect(() => {
    if (!isLoading && !!success) {
      setPage((page) => page + 1);
      dispatch(setSuccess(false));
    }
  }, [success, isLoading]);

  useEffect(() => {
    if (!!applicationStatus) {
      setPage(applicationStatus.state);
    }
  }, [applicationStatus]);

  useEffect(() => {
    if (!!accessToken) {
      dispatch(getApplicationStatusThunk(accessToken));
    }
  }, [dispatch]);

  const submitApplication = (
    request:
      | HomeOwnerRegistrationStepOneRequest
      | HomeOwnerRegistrationStepTwoRequest
  ) => {
    if (
      window.confirm("Have you verified that all the information is correct?")
    ) {
      if (!!accessToken) {
        dispatch(
          submitApplicationThunk({
            accessToken,
            homeOwner: request,
          })
        );
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingComponent />}
      {!isLoading && (
        <>
          {page === 0 && (
            <HomeOwnerStepOne submitApplication={submitApplication} />
          )}
          {page === 1 && (
            <HomeOwnerStepTwo
              submitApplication={submitApplication}
              goBack={() => setPage((page) => page - 1)}
            />
          )}
          {page === 2 && <RegistrationSuccess isHomeOwner={true} />}
        </>
      )}
    </>
  );
};

export default WithAuthWrapper(HomeOwnerRegister);
