import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import { NewUser } from "models/Authentication";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getErrorMessage,
  getIsAuthenticatedStatus,
  getIsLoadingOTP,
  getIsRegistered,
  getIsSentOTP,
  getLoadingStatus,
  getLoadingVerificationEmailStatus,
  getRegisterFailed,
  getSentVerificationEmail,
  requestOTP,
  resendVerificationEmailThunk,
  setErrorMessage,
  setIsRegistered,
  setIsSentOTP,
  setIsSentVerificationEmail,
  setRegisterFailed,
  signUpNewAccount,
} from "redux/slices/authenticationSlices";
import * as Styled from "./SignUp.styled";
import { validateForm } from "./validateForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, Snackbar, colors } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { Alert } from "@mui/material";
import PasswordField from "components/PasswordField";
import screenSize, { MIN_TABLET } from "styles/mediaQueries";
import useScreenSize from "hooks/useScreenSize";
import { Player } from "@lottiefiles/react-lottie-player";
import login from "assets/json/email.json";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

export interface FormValues {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  otp: string;
  password: string;
  password_confirmation: string;
  marketing: boolean;
  policy: boolean;
}

const initialValues: FormValues = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  otp: "",
  password: "",
  password_confirmation: "",
  marketing: false,
  policy: false,
};

const SignUp: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = React.useRef<any>(null);
  const isAuthenticated = useSelector(getIsAuthenticatedStatus);
  const isLoading = useSelector(getLoadingStatus);
  const isLoadingOTP = useSelector(getIsLoadingOTP);
  const isLoadingVerificationEmail = useSelector(
    getLoadingVerificationEmailStatus
  );
  const isResentEmail = useSelector(getSentVerificationEmail);
  const isSentOTP = useSelector(getIsSentOTP);
  const registerFailed = useSelector(getRegisterFailed);
  const errorMessage = useSelector(getErrorMessage);
  const isRegistered = useSelector(getIsRegistered);
  const [policyError, setPolicyError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(
    false
  );
  const [otpCountdown, setOtpCountdown] = useState<number>(60);
  const [runningCountdown, setRunningCountdown] = useState<boolean>(false);
  const [state, setState] = useState<number>(0);
  const [resendEmailCountdown, setResendEmailCountdown] = useState<number>(60);
  const [runningResendCountdown, setRunningResendCountdown] = useState<boolean>(
    false
  );
  const [email, setEmail] = useState<string>("");
  const [screenWidth] = useScreenSize();
  const [hasRequestedOTP, setHasRequestedOTP] = useState<boolean>(false);
  const [hasUppercase, setHasUppercase] = useState<boolean>(false);
  const [hasLowercase, setHasLowercase] = useState<boolean>(false);
  const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false);
  const [isAlphanumeric, setIsAlphanumeric] = useState<boolean>(false);
  const [hasMinLength, setHasMinLength] = useState<boolean>(false);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/intro");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let timer;
    if (runningCountdown && otpCountdown > 0) {
      timer = setInterval(() => {
        setOtpCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (otpCountdown === 0) {
      clearInterval(timer);
      setRunningCountdown(false);
      dispatch(setIsSentOTP(false));
    }

    return () => clearInterval(timer);
  }, [runningCountdown, otpCountdown]);

  useEffect(() => {
    let timer;
    if (runningResendCountdown && resendEmailCountdown > 0) {
      timer = setInterval(() => {
        setResendEmailCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (resendEmailCountdown === 0) {
      clearInterval(timer);
      setRunningResendCountdown(false);
      dispatch(setIsSentVerificationEmail(null));
    }

    return () => clearInterval(timer);
  }, [runningResendCountdown, resendEmailCountdown]);

  useEffect(() => {
    if (isResentEmail && !isLoadingVerificationEmail) {
      setResendEmailCountdown(60);
      setRunningResendCountdown(true);
    }
  }, [isResentEmail, isLoadingVerificationEmail]);

  useEffect(() => {
    if (!isLoadingOTP && isSentOTP) {
      setOtpCountdown(60);
      setRunningCountdown(true);
      setHasRequestedOTP(true);
    }
  }, [isLoadingOTP, isSentOTP]);

  useEffect(() => {
    if (!isLoading && isRegistered) {
      setState(1);
      setEmail(formikRef.current.values.email);
      dispatch(setIsRegistered(false));
    }
  }, [isLoading, isRegistered]);

  const onSubmit = (values: FormValues) => {
    const { email, password, marketing, policy } = values;

    if (!policy) {
      setPolicyError(true);
      return;
    }

    if (email !== "" && password !== "" && !!email && !!password) {
      const request: NewUser = {
        ...values,
        country_code: "+60",
        role_id: 3,
        source: "carefree",
      };
      dispatch(signUpNewAccount(request));
    }
  };

  const validatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hasUppercaseCheck = /[A-Z]/.test(e.target.value);
    const hasLowercaseCheck = /[a-z]/.test(e.target.value);
    const hasSpecialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(e.target.value);
    const isAlphanumericCheck =
      (hasLowercaseCheck || hasUppercaseCheck) && /[0-9]/.test(e.target.value);
    const hasMinLengthCheck = e.target.value.length >= 8;

    setHasUppercase(hasUppercaseCheck);
    setHasLowercase(hasLowercaseCheck);
    setHasSpecialChar(hasSpecialCharCheck);
    setIsAlphanumeric(isAlphanumericCheck);
    setHasMinLength(hasMinLengthCheck);

    setShowPasswordPrompt(
      e.target.value.length > 0 &&
        (!hasLowercaseCheck ||
          !hasUppercaseCheck ||
          !hasMinLengthCheck ||
          !isAlphanumericCheck ||
          !hasSpecialCharCheck)
    );
  };

  return (
    <Styled.MainContainer>
      {state === 0 && (
        <>
          <Snackbar
            open={policyError}
            autoHideDuration={2000}
            onClose={() => {
              setPolicyError(false);
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }} variant="filled">
              Please agree to the Terms of Service and Privacy Policy
            </Alert>
          </Snackbar>
          <Snackbar
            open={registerFailed}
            autoHideDuration={2000}
            onClose={() => {
              dispatch(setRegisterFailed(false));
              dispatch(setErrorMessage(null));
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }} variant="filled">
              {errorMessage}
            </Alert>
          </Snackbar>
          <Styled.TopSection>
            <Link
              to="/"
              onClick={() => {
                dispatch(setIsSentOTP(false));
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="#ccc" size="xl" />
            </Link>
            <Styled.Spacer height={10} />
            <Styled.Title>sign up</Styled.Title>
            <Styled.Spacer height={20} />
            <Styled.FormHolder>
              <Formik
                initialValues={initialValues}
                validate={(values) => validateForm(values)}
                onSubmit={onSubmit}
                innerRef={formikRef}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }) => (
                  <FormikForm style={{ width: "100%" }}>
                    <Styled.SideBySide>
                      <div>
                        <Styled.Field
                          name="fname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fname}
                          highlighted={!!values.fname}
                          error={errors.fname != null}
                          type="text"
                          placeholder="First Name"
                        />
                        <Styled.FieldErrorMessage>
                          <ErrorMessage name="fname" />
                        </Styled.FieldErrorMessage>
                      </div>
                      <Styled.Expander width={30} />
                      {screenWidth >= MIN_TABLET && (
                        <Styled.Spacer height={20} />
                      )}

                      <div>
                        <Styled.Field
                          name="lname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lname}
                          type="text"
                          placeholder="Last Name"
                          highlighted={!!values.lname}
                          error={errors.lname != null}
                        />
                        <Styled.FieldErrorMessage>
                          <ErrorMessage name="lname" />
                        </Styled.FieldErrorMessage>
                      </div>
                    </Styled.SideBySide>
                    <Styled.Spacer height={20} />
                    <Styled.Field
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      type="text"
                      placeholder="Email"
                      highlighted={!!values.email}
                      error={errors.email != null}
                    />
                    <Styled.FieldErrorMessage>
                      <ErrorMessage name="email" />
                    </Styled.FieldErrorMessage>
                    <Styled.FieldInfoMessage>
                      To receive booking notices, please provide a valid email
                      address
                    </Styled.FieldInfoMessage>
                    <Styled.Spacer height={20} />
                    <Styled.MobileNumberField>
                      <Styled.Field
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        type="text"
                        placeholder="Mobile Number (01xxxxxxxx)"
                        highlighted={!!values.phone}
                        error={errors.phone != null}
                      />
                      <Styled.GetOTPButton
                        disabled={values.phone == ""}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!runningCountdown) {
                            dispatch(requestOTP(values.phone));
                          }
                        }}
                      >
                        {isLoadingOTP ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : runningCountdown ? (
                          `${otpCountdown}s`
                        ) : hasRequestedOTP ? (
                          "resend code"
                        ) : (
                          "get code"
                        )}
                      </Styled.GetOTPButton>
                    </Styled.MobileNumberField>
                    <Styled.FieldErrorMessage>
                      <ErrorMessage name="phone" />
                    </Styled.FieldErrorMessage>
                    <Styled.FieldInfoMessage>
                      We need a valid number to contact you in case of an
                      emergency.
                    </Styled.FieldInfoMessage>
                    <Styled.Spacer height={20} />
                    {`${values.phone}`.length > 0 && (
                      <>
                        <Styled.Field
                          name="otp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="6 Digit OTP Code"
                          type="text"
                          max={6}
                          min={6}
                          highlighted={!!values.otp}
                          error={errors.otp != null}
                        />
                        <Styled.FieldErrorMessage>
                          <ErrorMessage name="otp" />
                        </Styled.FieldErrorMessage>
                        <Styled.FieldInfoMessage>
                          OTP expires in 5 mins
                        </Styled.FieldInfoMessage>
                        <Styled.Spacer height={20} />
                      </>
                    )}
                    <Styled.PasswordHolder>
                      <PasswordField
                        name="password"
                        placeholder="Create Password"
                        handleBlur={handleBlur}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          validatePassword(e);
                          handleChange(e);
                        }}
                        value={values.password}
                        error={errors.password}
                        showing={showPassword}
                        setShowing={() => setShowPassword((show) => !show)}
                        showingPrompt={showPasswordPrompt}
                      />
                      <Styled.PasswordCheckerHolder
                        show={showPasswordPrompt}
                        highlighted={!!values.password}
                        error={errors.password != null}
                      >
                        <Styled.PasswordCheckerRow>
                          <Styled.PasswordCheckerIcon valid={hasSpecialChar}>
                            {hasSpecialChar && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                color="#fff"
                                size="sm"
                              />
                            )}
                          </Styled.PasswordCheckerIcon>
                          <Styled.PasswordCheckerText valid={hasSpecialChar}>
                            Includes a special character
                          </Styled.PasswordCheckerText>
                        </Styled.PasswordCheckerRow>
                        <Styled.PasswordCheckerRow>
                          <Styled.PasswordCheckerIcon valid={hasLowercase}>
                            {hasLowercase && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                color="#fff"
                                size="sm"
                              />
                            )}
                          </Styled.PasswordCheckerIcon>
                          <Styled.PasswordCheckerText valid={hasLowercase}>
                            Includes a lowercase letter
                          </Styled.PasswordCheckerText>
                        </Styled.PasswordCheckerRow>
                        <Styled.PasswordCheckerRow>
                          <Styled.PasswordCheckerIcon valid={hasUppercase}>
                            {hasUppercase && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                color="#fff"
                                size="sm"
                              />
                            )}
                          </Styled.PasswordCheckerIcon>
                          <Styled.PasswordCheckerText valid={hasUppercase}>
                            Includes an uppercase letter
                          </Styled.PasswordCheckerText>
                        </Styled.PasswordCheckerRow>
                        <Styled.PasswordCheckerRow>
                          <Styled.PasswordCheckerIcon valid={isAlphanumeric}>
                            {isAlphanumeric && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                color="#fff"
                                size="sm"
                              />
                            )}
                          </Styled.PasswordCheckerIcon>
                          <Styled.PasswordCheckerText valid={isAlphanumeric}>
                            A mix of alphanumeric characters
                          </Styled.PasswordCheckerText>
                        </Styled.PasswordCheckerRow>
                        <Styled.PasswordCheckerRow>
                          <Styled.PasswordCheckerIcon valid={hasMinLength}>
                            {hasMinLength && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                color="#fff"
                                size="sm"
                              />
                            )}
                          </Styled.PasswordCheckerIcon>
                          <Styled.PasswordCheckerText valid={hasMinLength}>
                            At least 8 characters
                          </Styled.PasswordCheckerText>
                        </Styled.PasswordCheckerRow>
                      </Styled.PasswordCheckerHolder>
                      <Styled.FieldErrorMessage>
                        <ErrorMessage name="password" />
                      </Styled.FieldErrorMessage>
                    </Styled.PasswordHolder>
                    <Styled.Spacer height={20} />
                    <PasswordField
                      name="password_confirmation"
                      placeholder="Confirm Password"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.password_confirmation}
                      error={errors.password_confirmation}
                      showing={showConfirmPassword}
                      setShowing={() => setShowConfirmPassword((show) => !show)}
                    />
                    <Styled.FieldErrorMessage>
                      <ErrorMessage name="password_confirmation" />
                    </Styled.FieldErrorMessage>
                    <Styled.Spacer height={20} />
                    <Styled.CheckboxHolder>
                      <Checkbox
                        name="marketing"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.marketing}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        sx={{
                          color: "#EBEFF4",
                          "&.Mui-checked": {
                            color: "#E0479E",
                          },
                        }}
                      />
                      <p
                        onClick={() =>
                          setFieldValue("marketing", !values.marketing)
                        }
                      >
                        I want to receive marketing materials
                      </p>
                    </Styled.CheckboxHolder>
                    <Styled.Spacer height={5} />
                    <Styled.CheckboxHolder>
                      <Checkbox
                        name="policy"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.policy}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        sx={{
                          color: "#EBEFF4",
                          "&.Mui-checked": {
                            color: "#E0479E",
                          },
                        }}
                      />
                      <p>
                        I agree to carching's{" "}
                        <Styled.Link
                          href="https://friday-carching.s3.ap-southeast-1.amazonaws.com/carefree/carefree_privacy_policy.pdf"
                          target="_blank"
                        >
                          Terms of Service
                        </Styled.Link>{" "}
                        and{" "}
                        <Styled.Link
                          href="https://friday-carching.s3.ap-southeast-1.amazonaws.com/carefree/carefree_terms_of_service.pdf"
                          target="_blank"
                        >
                          Privacy Policy
                        </Styled.Link>
                      </p>
                    </Styled.CheckboxHolder>
                    <Styled.Spacer height={40} />
                    <Styled.Button
                      type="submit"
                      disabled={isLoading}
                      onClick={() => {
                        setShowPasswordPrompt(false);
                      }}
                    >
                      {isLoading ? "loading..." : "sign up"}
                    </Styled.Button>
                    <Styled.Spacer height={10} />
                  </FormikForm>
                )}
              </Formik>
            </Styled.FormHolder>
          </Styled.TopSection>
        </>
      )}
      {state === 1 && (
        <>
          <Styled.TopSection>
            <Link to="/">
              <FontAwesomeIcon icon={faChevronLeft} color="#ccc" size="xl" />
            </Link>
            <Styled.Spacer height={10} />
            <Styled.Title>confirm your email</Styled.Title>
          </Styled.TopSection>
          <Styled.MidSection>
            <p>
              A confirmation email has been
              <br />
              sent to your inbox.
            </p>
            <Styled.Spacer height={40} />
            <Player
              autoplay
              loop
              src={login}
              style={{ height: "200px", width: "200px" }}
            />
            <Styled.Spacer height={40} />
            <p>
              Please click on the link in the
              <br />
              email to confirm your email
            </p>
          </Styled.MidSection>
          <Styled.ResendLink
            onClick={() => {
              if (!runningResendCountdown) {
                dispatch(resendVerificationEmailThunk(email));
              }
            }}
          >
            {isLoadingVerificationEmail
              ? "loading..."
              : runningResendCountdown
              ? `Resend in ${resendEmailCountdown}s`
              : "Click here to resend verification email"}
          </Styled.ResendLink>
        </>
      )}
    </Styled.MainContainer>
  );
};

export default SignUp;
