// react-router-dom components
import { Link } from "react-router-dom";
import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function Explore() {

    return (
        <div className="maincontainer">
            <div class="container" style={{ padding: '60px' }}>
                <h3 class="card-title mt-3 text-center" style={{ color: "#ff69b4", fontFamily: "Fredoka One", paddingBottom: "5%" }}>explore</h3>

                <div style={{ padding: '10px' }}>
                    <Card sx={{ maxWidth: 400, maxHeight: 170, borderRadius: 2, backgroundColor: '#52489c' }}>
                        <CardContent>
                            <div class="row">
                                <div class='col-8'>
                                    <Typography gutterBottom variant="h6" component="div" style={{ color: "white", fontFamily: "Fredoka One" }}>
                                        carching's parking
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ color: "white" }}>
                                        Distance to Address
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ color: "white" }}>
                                        1.5 km
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ color: "white", paddingTop: '20px' }}>
                                        Price Per Month
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ color: "white" }}>
                                        RM 123.00
                                    </Typography>
                                </div>
                                <div class='col-4'>
                                    <CardMedia
                                        sx={{ height: 140, width: 100, backgroundColor: 'white', borderRadius: 2, }}
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="green iguana"
                                    />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div style={{ padding: '10px' }}>
                    <Card sx={{ maxWidth: 400, maxHeight: 170, borderRadius: 2, backgroundColor: '#6ee2d4' }}>
                        <CardContent>
                            <div class="row">
                                <div class='col-8'>
                                    <Typography gutterBottom variant="h6" component="div" style={{ fontFamily: "Fredoka One" }}>
                                        carching's parking
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Distance to Address
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        1.5 km
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ paddingTop: '20px' }}>
                                        Price Per Month
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        RM 123.00
                                    </Typography>
                                </div>
                                <div class='col-4'>
                                    <CardMedia
                                        sx={{ height: 140, width: 100, backgroundColor: 'white', borderRadius: 2, }}
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="green iguana"
                                    />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div style={{ padding: '10px' }}>
                    <Card sx={{ maxWidth: 400, maxHeight: 170, borderRadius: 2, backgroundColor: '#df479e' }}>
                        <CardContent>
                            <div class="row">
                                <div class='col-8'>
                                    <Typography gutterBottom variant="h6" component="div" style={{ color: "white", fontFamily: "Fredoka One" }}>
                                        carching's parking
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ color: "white" }}>
                                        Distance to Address
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ color: "white" }}>
                                        1.5 km
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ color: "white", paddingTop: '20px' }}>
                                        Price Per Month
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ color: "white" }}>
                                        RM 123.00
                                    </Typography>
                                </div>
                                <div class='col-4'>
                                    <CardMedia
                                        sx={{ height: 140, width: 100, backgroundColor: 'white', borderRadius: 2, }}
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="green iguana"
                                    />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div style={{ padding: '10px' }}>
                    <Card sx={{ maxWidth: 400, maxHeight: 170, borderRadius: 2, backgroundColor: '#fee440' }}>
                        <CardContent>
                            <div class="row">
                                <div class='col-8'>
                                    <Typography gutterBottom variant="h6" component="div" style={{ fontFamily: "Fredoka One" }}>
                                        carching's parking
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" >
                                        Distance to Address
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" >
                                        1.5 km
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ paddingTop: '20px' }}>
                                        Price Per Month
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" >
                                        RM 123.00
                                    </Typography>
                                </div>
                                <div class='col-4'>
                                    <CardMedia
                                        sx={{ height: 140, width: 100, backgroundColor: 'white', borderRadius: 2, }}
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="green iguana"
                                    />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

            </div>
        </div>
    );
}

export default Explore;
