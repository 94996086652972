import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCameraAlt } from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
// import { Address } from "./Address";

function Homeowner () {
const navigate = useNavigate();

    return (
      <div className="maincontainer">
          <div class="container">
  
                {/* <div class="card bg-light">
                <article class="card-body mx-auto" style={{maxWidth: "100%", backgroundColor: "white"}}> */}
                    <i class="back-button"><FontAwesomeIcon icon={faChevronLeft} style={{color: "grey"}}></FontAwesomeIcon></i>
                    <h3 class="card-title mt-3 text-center" style={{color: "#ff69b4", fontFamily: "Fredoka One", paddingBottom: "5%"}}>homeowner</h3>
                    <p>
                    <div class="" style={{color: "#ff69b4", fontFamily: "sans-serif", fontSize: "10px"}}>Step 1 of 2</div>
                    <div class="" style={{color: "#ff69b4", fontFamily: "Fredoka One", fontSize: "20px"}}>tell us more about yourself</div>
                    </p>
                    <form>
                    <div class="form-group input-group" >
                        <input name="" class="form-control" style={{borderRadius: "20px", fontFamily: "sans-serif", color: "grey"}} placeholder="Full Name (As per IC)" type="text" />
                    </div> 
                    <div class="form-group input-group">
                        <input name="" class="form-control" style={{borderRadius: "20px", fontFamily: "sans-serif", color: "grey"}} placeholder="IC Number" type="number" />
                    </div>
                    <div class="form-group input-group">
                        <select class="form-control" style={{borderRadius: "20px", fontFamily: "sans-serif", color: "grey"}}>
                            <option selected="">Gender</option>
                            <option>Female</option>
                            <option>Male</option>
                        </select>
                    </div>  
                    <div class="" style={{fontFamily: "sans-serif", fontSize: "17px", paddingTop: "3%"}}>Kindly take a picture of your:</div>
                    <div class="row" style={{paddingTop: "2.5%"}}>
                        <div class="col-10" style={{color: "#ff69b4", fontFamily: "sans-serif"}}>• Malaysian IC (front)</div>
                        <div class="col-2"><button type="button" class="btn" style={{borderRadius: "50%", backgroundColor:"#ff69b4", width: "30px", height: "30px", paddingLeft: "6.5px"}}><FontAwesomeIcon icon={faCameraAlt} style={{display: "flex", color: "white"}}></FontAwesomeIcon></button></div>
                    </div>  
                    <div class="row" style={{paddingTop: "2.5%"}}>
                        <div class="col-10" style={{color: "black", fontFamily: "sans-serif"}}>• Malaysian IC (back)</div>
                        <div class="col-2"><button type="button" class="btn" style={{borderRadius: "50%", backgroundColor:"black", width: "30px", height: "30px", paddingLeft: "6.5px"}}><FontAwesomeIcon icon={faCameraAlt} style={{display: "flex", color: "white"}}></FontAwesomeIcon></button></div>
                    </div> 
                    <div class="row" style={{paddingTop: "2.5%"}}>
                        <div class="col-10" style={{color: "black", fontFamily: "sans-serif"}}>• Self (selfie)</div>
                        <div class="col-2"><button type="button" class="btn" style={{borderRadius: "50%", backgroundColor:"black", width: "30px", height: "30px", paddingLeft: "6.5px"}}><FontAwesomeIcon icon={faCameraAlt} style={{display: "flex", color: "white"}}></FontAwesomeIcon></button></div>
                    </div>  
                    <div class="" style={{fontFamily: "sans-serif", fontSize: "17px", paddingTop: "5%"}}>Emergency contact details:</div>    
                    <div class="form-group input-group" style={{paddingTop: "5%"}}>
                        <input name="" class="form-control" style={{borderRadius: "20px", fontFamily: "sans-serif", color: "grey"}} placeholder="Full Name (As per IC)" type="text" />
                    </div> 
                    <div class="form-group input-group">
                        <select class="form-control" style={{borderRadius: "20px", fontFamily: "sans-serif", color: "grey"}}>
                            <option selected="">Relationship</option>
                            <option>Female</option>
                            <option>Male</option>
                        </select>
                    </div>  
                    <div class="form-group input-group">
                        <input name="" class="form-control" style={{borderRadius: "20px", fontFamily: "sans-serif", color: "grey"}} placeholder="Mobile Number" type="number" />
                    </div>                               
                    <div class="form-group">
                        <button type="button" class="btn btn-primary btn-block" style={{borderRadius: "20px", backgroundColor:"#ff69b4", borderColor:"#ff69b4"}} onClick={()=>navigate('/address')}> next </button>
                        {/* <button type="button" class="btn btn-primary btn-block" style={{borderRadius: "20px", backgroundColor:"#ff69b4", borderColor:"#ff69b4"}}>
                           <a href="./Address" style={{color: "white"}}> next </a> 
                        </button> */}
                    </div>                                                                     
                </form>
                {/* </article>
                </div>  */}
                
                </div> 
             
        </div>
     )
};


export default Homeowner