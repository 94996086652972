import React from "react";
import { GeoObjectDetail } from "models/HomeState";
import * as Styled from "./PopupCard.styled";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import useScreenSize from "hooks/useScreenSize";
import { MIN_TABLET } from "styles/mediaQueries";
import MDButton from "components/MDButton";
import { setSelectedGeoObject } from "redux/slices/homeSlices";
import { useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

type Props = {
  geoDetails: GeoObjectDetail | undefined;
  openDetails: () => void;
};
const PopupCard: React.FC<Props> = (props) => {
  const { geoDetails, openDetails } = props;
  const [screenWidth] = useScreenSize();
  const dispatch = useDispatch();

  const restructureAddress = () => {
    if (geoDetails) {
      var firstIndexWithComma = geoDetails.address.indexOf(",");
      var arr = [
        geoDetails.address.slice(0, firstIndexWithComma),
        geoDetails.address.slice(firstIndexWithComma + 1),
      ];

      if (/\d/.test(arr[0])) {
        // Remove unit no
        return geoDetails.address.replace(/.*?,/, "");
      } else {
        return geoDetails.address;
      }
    }
    return "";
  };
  return (
    <>
      {!!geoDetails && (
        <>
          <Card
            style={{
              padding: 0,
              borderRadius: 20,
            }}
            onClick={() => {
              dispatch(setSelectedGeoObject(geoDetails));
              openDetails();
            }}
          >
            <CardMedia
              image={geoDetails.home_picture_url}
              title={geoDetails.address}
              style={{
                width: "100%",
                height: 200,
              }}
            ></CardMedia>
            <CardContent
              style={{
                padding: "0px 10px",
              }}
            >
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  primary={
                    <Typography
                      component="h5"
                      variant="h5"
                      style={{
                        color: "#E0479E",
                        fontSize: 15,
                        fontWeight: "bold",
                        marginBottom: 15,
                      }}
                    >
                      {geoDetails.street.toLowerCase()}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Styled.LabelContainer>
                        <Styled.ContentLabel>
                          {restructureAddress().toLowerCase()}
                        </Styled.ContentLabel>
                      </Styled.LabelContainer>
                      <Styled.LabelContainer>
                        <Styled.ContentLabel>
                          {`Date: ${geoDetails.start_date} to ${geoDetails.end_date}`}
                        </Styled.ContentLabel>
                        <Styled.ContentLabel>
                          {`RM ${geoDetails.price}`}
                        </Styled.ContentLabel>
                      </Styled.LabelContainer>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default PopupCard;
