import { FormValues } from "./index";

export const validateForm = (values: FormValues) => {

    let errors: any = {};

    if (!values.unit_no) {
        errors.unit_no = "Unit No is required.";
    }

    if (!values.electricity_bill_url) {
        errors.electricity_bill_url = "Please take a picture of your Electricity Bill";
    } else if (!values.home_url) {
        errors.home_url = "Please take a picture of your Home Picture";
    }

    return errors;

};