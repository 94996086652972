import styled from "styled-components";

type ButtonProps = {
  isSelected: boolean;
};
export const ToggleContainer = styled.div<ButtonProps>`
  width: 70px;
  height: 28px;
  background: ${(props) =>
    props.isSelected === true ? "#f0f3fa" : "transparent"};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1rem;
`;

export const UnselectedButton = styled.button<ButtonProps>`
  background: transparent;
  border: none;
  background: ${(props) =>
    props.isSelected === true ? "#E0479E" : "transparent"};
  width: 35px;
  border-radius: 100px;
  height: 28px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const mapLogo = styled.img`
  justify-content: center;
`;
