import { ListItem } from "@material-ui/core";
import styled from "styled-components";
import screenSize from "../../styles/mediaQueries";
import { TextField } from "@mui/material";

export const MainContainer = styled.div`
  position: absolute;
  background-color: white;
  border-radius: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #838a9e;
    outline: 1px solid slategrey;
  }
  ${screenSize.minTablet`
  width: 35rem;
  height: 85%;
  border-radius: 10px;
`}
`;

export const ModalContentContainer = styled.div`
  width: 100%;
  height: 100%;
  ${screenSize.minTablet`
  width: 80%;
`}
`;
export const CloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0.5rem;
  background: #e0479e;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

export const MainTitle = styled.h3`
  font-weight: bold;
  font-size: 40px;
  font-family: "Spectral", serif;
  width: 50%;
  text-align: center;
  margin: auto;
  color: #232a3d;
`;

type ImageProps = {
  isNew?: boolean;
};
export const ImageContainer = styled.div<ImageProps>`
  height: ${(props) => (props.isNew ? "20%" : "40%")};
  width: 100%;
  padding: 20px;
`;
export const ImageContent = styled.img`
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
`;

export const ListContainer = styled.div`
  width: 90%;
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 10px;

  & .MuiFormControl-root {
    margin: 0px !important;
  }
`;
export const ListTitle = styled.p`
  text-align: center;
  font-size: 14px;
  margin: auto;
  color: #575252;
`;

export const PostingContainer = styled.div`
  margin-top: 80px;
`;

export const SubmitButton = styled.button`
  width: 90%;
  padding: 10px 20px;
  border: none;
  font-family: "Fredoka One";
  color: #fff;
  border-radius: 20px;
  background-color: ${(props) => (props.disabled ? "#D3D3D3" : "#e0479e")};
  margin-top: 20px;
  margin-bottom: 80px;
`;

export const Field = styled.textarea`
  width: 90%;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  background: #f0f1f5;
  ::placeholder {
    color: #d5d5d5;
  }
  text-align: center;
  outline: none;
`;
export const Addresscontainer = styled.div<ImageProps>`
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f1f5;
  border-radius: 20px;
  height: fit-content;
  padding: 10px;
  position: absolute;
  top: ${(props) => (props.isNew ? "18%" : "33%")};
  ${screenSize.minTablet`
  top: 19%;
`}
`;

export const AddressLabel = styled.p`
  font-size: 0.9em;
  margin: 0;
  text-align: center;
  color: #575252;
`;

export const PriceContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f1f5;
  border-radius: 20px;
  height: fit-content;
  padding: 10px;
`;

export const DateTextField = styled(TextField)`
  &&& {
    text-align: center;

    .MuiOutlinedInput-input {
      text-align: center;
    }
  }
`;

export const Note = styled.p`
  text-align: center;
  font-size: 12px;
  margin: 10px;
`;
