import WithAuthWrapper from "hooks/withAuthWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cancelBookingThunk,
  checkoutThunk,
  getBooking,
  getLoadingBookingStatus,
  getRedirectUrl,
  getSuccessStatus,
  setSuccess,
} from "redux/slices/driverSlices";
import Cross24 from "assets/Cross24";
import LoadingComponent from "components/LoadingComponent";

const Checkout: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const booking = useSelector(getBooking);
  const redirectUrl = useSelector(getRedirectUrl);
  const accessToken = localStorage.getItem("carching-token");
  const isLoading = useSelector(getLoadingBookingStatus);
  const success = useSelector(getSuccessStatus);

  useEffect(() => {
    if (!isLoading && success) {
      dispatch(setSuccess(false));
      navigate("/home");
    }
  }, [isLoading, success]);

  useEffect(() => {
    if (!booking) {
      navigate("/home");
    }
  }, [booking]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const handleCheckout = () => {
    if (!!accessToken && !!booking) {
      dispatch(
        checkoutThunk({
          accessToken,
          bookingID: booking?.id,
        })
      );
    }
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);
    const day = newDate.getDate().toString().padStart(2, "0");
    const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
    const year = newDate.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      {isLoading && <LoadingComponent />}
      {!isLoading && (
        <div className="maincontainer" style={{ overflow: "auto" }}>
          <div className="container" style={{ padding: "0px", width: "100%" }}>
            <div
              style={{
                padding: 5,
                background: "#e0479e",
                borderRadius: "100%",
                width: "35px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 5,
                right: 5,
              }}
              onClick={() => {
                if (window.confirm("Are you sure to cancel booking?")) {
                  if (!!accessToken && !!booking) {
                    dispatch(
                      cancelBookingThunk({
                        accessToken,
                        bookingID: booking?.id,
                      })
                    );
                  }
                }
              }}
            >
              <Cross24 colour="#fff" />
            </div>
            <div
              style={{
                paddingTop: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../assets/icons/payment/Parking Confirmation_.png")}
                style={{ height: "90px", width: "90px", margin: 0 }}
              />
            </div>

            <div
              style={{
                display: "flex",
                paddingTop: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3
                style={{
                  color: "#e0479e",
                  fontFamily: "Fredoka One",
                  // paddingTop: "20px",
                  padding: "0 !important",
                  margin: 0,
                }}
              >
                parking confirmation
              </h3>
            </div>

            <div
              className=""
              style={{
                width: "100%",
                //backgroundColor: "#fbebf3",
                padding: "10%",
                marginTop: 10,
              }}
            >
              <div
                className=""
                style={{
                  fontSize: "0.9em",
                  paddingTop: "10px",
                  paddingBottom: "5px",
                  fontWeight: "600",
                  color: "#e0479e",
                }}
              >
                Car Plate Number
              </div>
              <hr
                style={{
                  height: "1px",
                  backgroundColor: "#e0479e",
                  margin: "0em",
                }}
              />
              <div
                className=""
                style={{
                  fontSize: "0.9em",
                  paddingBottom: "10px",
                  paddingTop: "5px",
                  color: "#575252",
                }}
              >
                {booking?.plate_number}
              </div>

              <div
                className=""
                style={{
                  fontSize: "0.9em",
                  paddingTop: "10px",
                  paddingBottom: "5px",
                  fontWeight: "600",
                  color: "#e0479e",
                }}
              >
                Parking Address Details
              </div>
              <hr
                style={{
                  height: "1px",
                  backgroundColor: "#e0479e",
                  margin: "0em",
                }}
              />
              <div
                className=""
                style={{
                  fontSize: "0.9em",
                  paddingBottom: "10px",
                  paddingTop: "5px",
                  color: "#575252",
                }}
              >
                {booking?.address}
              </div>

              <div
                className=""
                style={{
                  fontSize: "0.9em",
                  paddingTop: "10px",
                  paddingBottom: "5px",
                  color: "#e0479e",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>Start Date</span>
                <span>End Date</span>
              </div>
              <hr
                style={{
                  height: "1px",
                  backgroundColor: "#e0479e",
                  margin: "0em",
                }}
              />
              <div
                className=""
                style={{
                  fontSize: "0.9em",
                  paddingBottom: "10px",
                  paddingTop: "5px",
                  display: "flex",
                  color: "#575252",
                  justifyContent: "space-between",
                }}
              >
                <span>{formatDate(booking?.start_date ?? "")}</span>
                <span>{formatDate(booking?.end_date ?? "")}</span>
              </div>

              <div
                className=""
                style={{
                  fontSize: "0.9em",
                  paddingTop: "10px",
                  paddingBottom: "5px",
                  color: "#e0479e",
                  fontWeight: "600",
                }}
              >
                Your Order
              </div>
              <hr
                style={{
                  height: "1px",
                  backgroundColor: "#e0479e",
                  margin: "0em",
                }}
              />
              <div className="row">
                <div className="col-6">
                  <div
                    className=""
                    style={{
                      fontSize: "0.9em",
                      paddingBottom: "10px",
                      paddingTop: "5px",
                      color: "#575252",
                    }}
                  >
                    {booking?.duration ?? 0}{" "}
                    {+(booking?.duration ?? 0) > 1 ? "months" : "month"} season
                    pass
                  </div>
                </div>
                <div className="col-6" style={{}}>
                  <div
                    className="text-right"
                    style={{
                      fontSize: "0.9em",
                      paddingBottom: "10px",
                      paddingTop: "5px",
                      color: "#575252",
                    }}
                  >
                    MYR {booking?.amount ?? 0.0}
                  </div>
                </div>
              </div>

              <hr
                style={{
                  height: "1px",
                  backgroundColor: "#e0479e",
                  margin: "0em",
                }}
              />
              <div className="row">
                <div className="col-6">
                  <div
                    className=""
                    style={{
                      fontSize: "0.9em",
                      paddingTop: "5px",
                      color: "#e0479e",
                    }}
                  >
                    Subtotal
                  </div>
                </div>
                <div className="col-6" style={{}}>
                  <div
                    className="text-right"
                    style={{
                      fontSize: "0.9em",
                      paddingTop: "5px",
                      color: "#575252",
                    }}
                  >
                    MYR {booking?.amount}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div
                    className=""
                    style={{
                      fontSize: "0.9em",
                      paddingTop: "5px",
                      color: "#e0479e",
                    }}
                  >
                    Service Tax
                  </div>
                </div>
                <div className="col-6" style={{}}>
                  <div
                    className="text-right"
                    style={{
                      fontSize: "0.9em",
                      paddingTop: "5px",
                      color: "#575252",
                    }}
                  >
                    MYR 0.00
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div
                    className=""
                    style={{
                      fontSize: "0.9em",
                      paddingTop: "5px",
                      fontWeight: "600",
                      color: "#e0479e",
                    }}
                  >
                    Grand Total
                  </div>
                </div>
                <div className="col-6" style={{}}>
                  <div
                    className="text-right"
                    style={{
                      fontSize: "0.9em",
                      paddingTop: "5px",
                      color: "#575252",
                    }}
                  >
                    MYR {(+(booking?.amount ?? 0)).toFixed(2)}
                  </div>
                </div>
              </div>
              <br />
              <br />

              <div className="form-group">
                <button
                  type="button"
                  onClick={handleCheckout}
                  className="btn btn-primary btn-block"
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#e0479e",
                    border: "none",
                    fontSize: "1em",
                    padding: "10px",
                    fontFamily: "Fredoka One",
                  }}
                >
                  proceed to payment
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WithAuthWrapper(Checkout);
