import { StoreState } from "models/StoreState";
import { AccountState } from "models/AccountState";
import { ProfileObjectDetail } from "models/AccountState";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getProfileFromEndpoint } from "services/api/accountApi";

const accountSlice = createSlice({
  name: "account",
  initialState: {
    isLoading: true,
    profile: {},
    defaultView: 0,
  } as AccountState,
  reducers: {
    setLoadingStatus(state, action) {
      state.isLoading = action.payload;
    },
    setDefaultView(state, action) {
      state.defaultView = action.payload;
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
  },
});

export const getDefaultView = (state: StoreState): number =>
  state.account.defaultView;
export const getLoadingStatus = (state: StoreState): boolean =>
  state.account.isLoading;

export const getProfile = (state: StoreState): ProfileObjectDetail =>
  state.account.profile;

export const getProfileThunk = createAsyncThunk(
  "account/getProfileThunk",
  async (access_token: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(accountSlice.actions.setLoadingStatus(true));
    try {
      const profile = await getProfileFromEndpoint(access_token);
      dispatch(accountSlice.actions.setProfile(profile));
    } catch (e) {
      console.error(e);
    }

    dispatch(accountSlice.actions.setLoadingStatus(false));
  }
);

export const { setDefaultView, setProfile } = accountSlice.actions;
export default accountSlice.reducer;
