import { FormValues } from "./index";

export const validateForm = (values: FormValues) => {

    let errors: any = {};

    if (!values.plate_number) {
        errors.plate_number = "Plate Number is required.";
    }

    if (!values.car_brand_id) {
        errors.car_brand_id = "Car Brand is required.";
    }

    if (!values.car_model_id) {
        errors.car_model_id = "Car Model is required";
    }

    if (!values.car_color_id) {
        errors.car_color_id = "Car Color is required"
    }

    if (!values.roadtax_picture) {
        errors.roadtax_picture = "Please take a picture of your Road Tax";
    }

    if (!values.car_front_picture) {
        errors.car_front_picture = "Required";
    }

    if (!values.car_back_picture) {
        errors.car_back_picture = "Required";
    }

    if (!values.car_left_picture) {
        errors.car_left_picture = "Required";
    }

    if (!values.car_right_picture) {
        errors.car_right_picture = "Required";
    }

    return errors;

};