import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faCameraAlt,
  faCar,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCarBrands,
  getCarColors,
  getCarModels,
  getCarModelsByBrandThunk,
  getCarsOptionsThunk,
} from "redux/slices/driverSlices";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import { DriverRegistrationStepTwoRequest } from "models/Driver";
import FileInput from "../FileInput";
import { validateForm } from "./validateForm";

export interface FormValues {
  plate_number: string;
  car_brand_id: string;
  car_model_id: string;
  car_color_id: string;
  roadtax_picture: string;
  car_front_picture: string;
  car_back_picture: string;
  car_left_picture: string;
  car_right_picture: string;
}

const initialValues: FormValues = {
  plate_number: "",
  car_brand_id: "",
  car_model_id: "",
  car_color_id: "",
  roadtax_picture: "",
  car_front_picture: "",
  car_back_picture: "",
  car_left_picture: "",
  car_right_picture: "",
};

type DriverRegistrationStepTwoProps = {
  submitApplication: (request: DriverRegistrationStepTwoRequest) => void;
  goBack: () => void;
};

const DriverRegistrationStepTwo: React.FC<DriverRegistrationStepTwoProps> = (
  props
) => {
  const accessToken = localStorage.getItem("carching-token");
  const { submitApplication } = props;
  const formikRef = React.useRef<any>(null);
  const dispatch = useDispatch();

  const carModels = useSelector(getCarModels);
  const carBrands = useSelector(getCarBrands);
  const carColors = useSelector(getCarColors);

  useEffect(() => {
    if (!!accessToken) {
      dispatch(getCarsOptionsThunk(accessToken));
    }
  }, [dispatch]);

  const getCarModelsByBrand = (brandId: number) => {
    if (!!accessToken) {
      dispatch(
        getCarModelsByBrandThunk({
          accessToken,
          brandId,
        })
      );
    }
  };

  const onSubmit = (values: FormValues) => {
    const request: DriverRegistrationStepTwoRequest = {
      ...values,
      step: 2,
    };

    submitApplication(request);
  };

  return (
    <div className="maincontainer">
      <div
        className="container"
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "80px",
        }}
      >
        <FontAwesomeIcon
          onClick={props.goBack}
          icon={faChevronLeft}
          style={{ color: "#ccc", paddingTop: "40px" }}
        ></FontAwesomeIcon>
        <h3
          className="text-center"
          style={{
            color: "#e0479e",
            fontFamily: "Fredoka One",
            paddingBottom: "5%",
            paddingTop: "20px",
          }}
        >
          driver / parking seeker
        </h3>
        <p>
          <div className="" style={{ color: "#e0479e", fontSize: "10px" }}>
            Step 2 of 2
          </div>
          <div
            className=""
            style={{
              color: "#e0479e",
              fontFamily: "Fredoka One",
              fontSize: "20px",
            }}
          >
            tell us more about your car
          </div>
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formikRef}
          validate={validateForm}
        >
          {({ values, handleBlur, handleChange, setFieldValue, errors }) => (
            <FormikForm>
              <div className="form-group input-group flex-column">
                <input
                  name="plate_number"
                  onChange={handleChange}
                  aria-valuetext=""
                  onBlur={handleBlur}
                  value={values.plate_number}
                  className="form-control w-100"
                  style={{
                    borderRadius: "20px",
                    background: "#f0f1f5",
                    border: errors.plate_number
                      ? "1px solid #dc3545"
                      : values.plate_number
                      ? "1px solid #f0f1f5"
                      : "1px solid #f0f1f5",
                  }}
                  placeholder="Plate Number"
                  type="text"
                />
                <p
                  className="m-0 mt-1 error text-danger"
                  style={{ fontSize: "0.9em" }}
                >
                  <ErrorMessage name="plate_number" />
                </p>
              </div>
              <div className="form-group input-group flex-column">
                <select
                  className="form-control w-100"
                  name="car_brand_id"
                  onChange={(e) => {
                    handleChange(e);
                    getCarModelsByBrand(+e.target.value);
                  }}
                  onBlur={handleBlur}
                  style={{
                    borderRadius: "20px",
                    background: "#f0f1f5",
                    border: errors.car_brand_id
                      ? "1px solid #dc3545"
                      : values.car_brand_id
                      ? "1px solid #f0f1f5"
                      : "1px solid #f0f1f5",
                  }}
                >
                  <option value="">Brand</option>
                  {carBrands &&
                    carBrands.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                </select>
                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="car_brand_id" />
                </p>
              </div>
              <div className="form-group input-group flex-column">
                <select
                  className="form-control w-100"
                  name="car_model_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!carModels}
                  style={{
                    borderRadius: "20px",
                    background: "#f0f1f5",
                    border: errors.car_model_id
                      ? "1px solid #dc3545"
                      : values.car_model_id
                      ? "1px solid #f0f1f5"
                      : "1px solid #f0f1f5",
                  }}
                >
                  <option value="">Model</option>
                  {carModels &&
                    carModels.map((model) => (
                      <option key={model.id} value={model.id}>
                        {model.name}
                      </option>
                    ))}
                </select>
                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="car_model_id" />
                </p>
              </div>
              <div className="form-group input-group flex-column">
                <select
                  className="form-control w-100"
                  name="car_color_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{
                    borderRadius: "20px",
                    background: "#f0f1f5",
                    border: errors.car_color_id
                      ? "1px solid #dc3545"
                      : values.car_color_id
                      ? "1px solid #f0f1f5"
                      : "1px solid #f0f1f5",
                  }}
                >
                  <option value="">Colour</option>
                  {carColors &&
                    carColors.map((color) => (
                      <option key={color.id} value={color.id}>
                        {color.name}
                      </option>
                    ))}
                </select>
                <p
                  className="m-0 ml-1 mt-1 error"
                  style={{
                    fontSize: "0.7em",
                    color: "#e0479e",
                  }}
                >
                  <ErrorMessage name="car_color_id" />
                </p>
              </div>
              <div className="" style={{ fontSize: "15px", paddingTop: "3%" }}>
                Kindly take a picture of your:
              </div>
              <p
                className="m-0 ml-1 mt-1 error"
                style={{
                  fontSize: "0.7em",
                  color: "#e0479e",
                }}
              >
                <ErrorMessage name="roadtax_picture" />
              </p>
              <div className="row" style={{ paddingTop: "2.5%" }}>
                <div
                  className="col-10"
                  style={{
                    color: errors.roadtax_picture
                      ? "#dc3545"
                      : values.roadtax_picture
                      ? "#e0479e"
                      : "black",
                  }}
                >
                  • Road Tax
                </div>
                <FileInput
                  name="roadtax_picture"
                  error={errors.roadtax_picture != null}
                  onChange={(image) => {
                    setFieldValue("roadtax_picture", image);
                  }}
                />
              </div>
              <p style={{ fontSize: "0.68em", color: "#a4a4a4", marginTop: 5 }}>
                * Please make sure photos are clear to speed up approval process
              </p>
              <div
                style={{
                  fontSize: "15px",
                  paddingTop: "5%",
                  color:
                    errors.car_front_picture ||
                    errors.car_back_picture ||
                    errors.car_left_picture ||
                    errors.car_right_picture
                      ? "red"
                      : "black",
                }}
              >
                Please take a picture of all sides of your car:
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <FileInput
                  name="car_front_picture"
                  error={errors.car_front_picture != null}
                  showPreview={true}
                  label="Front"
                  onChange={(image) => {
                    setFieldValue("car_front_picture", image);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "20px 15px",
                }}
              >
                <FileInput
                  name="car_left_picture"
                  error={errors.car_left_picture != null}
                  showPreview={true}
                  label="Left"
                  onChange={(image) => {
                    setFieldValue("car_left_picture", image);
                  }}
                />
                <img
                  src={require("../../assets/icons/CARTOP.png")}
                  style={{
                    width: "40px",
                  }}
                />

                <FileInput
                  name="car_right_picture"
                  error={errors.car_right_picture != null}
                  showPreview={true}
                  label="Right/Driver"
                  onChange={(image) => {
                    setFieldValue("car_right_picture", image);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginBottom: "30px",
                }}
              >
                <FileInput
                  name="car_back_picture"
                  error={errors.car_back_picture != null}
                  showPreview={true}
                  label="Back"
                  onChange={(image) => {
                    setFieldValue("car_back_picture", image);
                  }}
                />
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#e0479e",
                    borderColor: "#e0479e",
                    fontFamily: "Fredoka One",
                  }}
                  onClick={() => {
                    if (formikRef.current) {
                      formikRef.current.handleSubmit();
                    }
                  }}
                >
                  {" "}
                  submit{" "}
                </button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DriverRegistrationStepTwo;
