import { AxiosResponse } from "axios";
import { CarBrand, CarColor, CarModel } from "models/Cars";
import { AxiosGenericResponse } from "models/common";
import { getRequest } from "services/client/request";

const apiVersion = "/api";

export const getCarModelsByBrandAPI = async (
    accessToken: string,
    brandId: number
): Promise<CarModel[]> => {
    const response: AxiosResponse<AxiosGenericResponse<CarModel[]>> = await getRequest(`${apiVersion}/car_models/brand/${brandId}`, accessToken);
    const carModels: CarModel[] = response.data.success ? response.data.data : [];
    return carModels;
};

export const getCarColorsAPI = async (
    accessToken: string
): Promise<CarColor[]> => {
    const response: AxiosResponse<AxiosGenericResponse<CarColor[]>> = await getRequest(`${apiVersion}/car_colors`, accessToken);
    const carColors: CarColor[] = response.data.success ? response.data.data : [];
    return carColors;
};

export const getCarBrandsAPI = async (
    accessToken: string
): Promise<CarBrand[]> => {
    const response: AxiosResponse<AxiosGenericResponse<CarBrand[]>> = await getRequest(`${apiVersion}/car_brands`, accessToken);
    const carBrands: CarBrand[] = response.data.success ? response.data.data : [];
    return carBrands;
}