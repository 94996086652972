import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "redux/store";
import HomePage from "pages/Home";
import Account from "components/account";
import Explore from "components/explore";
import Map from "components/map";
import Homeowner from "components/homeowner";
import SubmittedDriver from "components/SubmittedDriver";
import ExploreDriver from "components/exploreDriver";
import ExploreOwner from "components/exploreOwner";
import ConfirmationCar from "components/confirmationCar";
import ConfirmationParking from "components/confirmationParking";
import PaymentSuccessful from "components/paymentSuccessful";
import PaymentUnsuccessful from "components/paymentUnsuccessful";
import LoginComponent from "pages/sign-in";
import SignUpComponent from "pages/sign-up";
import HomeOwnerRegister from "pages/HomeOwner";
import DriverRegister from "pages/Driver";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import Intro from "pages/Intro";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import WithThemeWrapper from "./withThemeWrapper";
import Checkout from "pages/Checkout";
// import StripeCheckout from "pages/StripeCheckout";
import StripeCallback from "pages/StripeCallback";
import Status from "pages/Status";
import History from "pages/History";
import HistoryDetail from "pages/HistoryDetail";
import BookingPage from "pages/Booking";
import BookingDetailsPage from "pages/Booking/details";

const routes = [
  {
    exact: true,
    path: "/",
    dataTestId: "sign-in",
    component: SignIn,
  },
  {
    exact: true,
    path: "/signup",
    dataTestId: "signup",
    component: SignUp,
  },
  {
    exact: true,
    path: "/home",
    dataTestId: "default",
    component: WithThemeWrapper(HomePage),
  },
  {
    exact: true,
    path: "/intro",
    dataTestId: "intro",
    component: WithThemeWrapper(Intro),
  },
  {
    exact: true,
    path: "/homeowner/register",
    dataTestId: "homeowner-register",
    component: WithThemeWrapper(HomeOwnerRegister),
  },
  {
    exact: true,
    path: "/driver/register",
    dataTestId: "driver-register",
    component: WithThemeWrapper(DriverRegister),
  },

  {
    exact: false,
    path: "/explore",
    dataTestId: "explore",
    component: WithThemeWrapper(Explore),
  },
  {
    exact: false,
    path: "/homeowner",
    dataTestId: "homeowner",
    component: Homeowner,
  },
  {
    exact: false,
    path: "/booking",
    dataTestId: "booking",
    component: WithThemeWrapper(BookingPage),
  },
  {
    exact: false,
    path: "/booking-details",
    dataTestId: "booking-details",
    component: WithThemeWrapper(BookingDetailsPage),
  },
  {
    exact: false,
    path: "/submittedDriver",
    dataTestId: "submittedDriver",
    component: SubmittedDriver,
  },
  {
    exact: false,
    path: "/exploreDriver",
    dataTestId: "exploreDriver",
    component: ExploreDriver,
  },
  {
    exact: false,
    path: "/exploreOwner",
    dataTestId: "exploreOwner",
    component: ExploreOwner,
  },
  {
    exact: false,
    path: "/confirmationCar",
    dataTestId: "confirmationCar",
    component: ConfirmationCar,
  },
  {
    exact: false,
    path: "/confirmationParking",
    dataTestId: "confirmationParking",
    component: ConfirmationParking,
  },
  // {
  //   exact: false,
  //   path: "/checkout",
  //   dataTestId: "checkout",
  //   component: Checkout,
  // },
  {
    exact: false,
    path: "/explore",
    dataTestId: "explore",
    component: Explore,
  },
  {
    exact: false,
    path: "/paymentSuccessful",
    dataTestId: "paymentSuccessful",
    component: PaymentSuccessful,
  },
  {
    exact: false,
    path: "/paymentUnsuccessful",
    dataTestId: "paymentUnsuccessful",
    component: PaymentUnsuccessful,
  },
  {
    exact: false,
    path: "/sign-in",
    dataTestId: "sign-in",
    component: LoginComponent,
  },
  {
    exact: false,
    path: "/sign-up",
    dataTestId: "sign-up",
    component: SignUpComponent,
  },
  {
    exact: false,
    path: "/status",
    dataTestId: "Status",
    component: WithThemeWrapper(Status),
  },
  {
    exact: false,
    path: "/history",
    dataTestId: "History",
    component: WithThemeWrapper(History),
  },
  {
    exact: false,
    path: "/historyDetail",
    dataTestId: "HistoryDetail",
    component: HistoryDetail,
  },
  {
    exact: true,
    path: "/checkout",
    dataTestId: "checkout",
    component: WithThemeWrapper(Checkout),
  },
  // {
  //   exact: true,
  //   path: "/stripe-checkout",
  //   dataTestId: "stripe-checkout",
  //   component: WithThemeWrapper(StripeCheckout)
  // },
  {
    exact: true,
    path: "/callback",
    dataTestId: "stripe-callback",
    component: StripeCallback,
  },
];

const AppWrapperRoute: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <Provider store={store}>
                <route.component data-test-id={route.dataTestId} />
              </Provider>
            }
          />
        ))}
      </Routes>
      {pathname !== "/" && pathname !== "/signup" && (
        <Link href={`https://wa.me/601156205669`} target="_blank">
          <Tooltip title="Contact us through Whatsapp">
            <Fab
              aria-label="add"
              style={{
                position: "fixed",
                bottom: 10,
                right: 10,
                backgroundColor: "#E0479E",
                color: "white",
                borderRadius: "50%",
              }}
            >
              <WhatsAppIcon />
            </Fab>
          </Tooltip>
        </Link>
      )}
    </>
  );
};

export default AppWrapperRoute;
