import React from "react";

const getDimensions = () => {
  return {
    height: window.innerHeight,
    width: window.innerWidth
  };
};

const useScreenSize = (): number[] => {
  const [windowSize, setWindowSize] = React.useState(getDimensions());

  const onResize = () => {
    setWindowSize(getDimensions());
  };

  React.useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return [windowSize.width, windowSize.height];
};

export default useScreenSize;
