import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

type SelectInputProps = {
  title: string;
  items: any[];
  onSelected: (selectedValue: string) => void;
  width?: string;
  margin?: number;
  defaultValue?: any;
  itemName?: string;
  disabled?: boolean;
  value?: any;
};

const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    title,
    items,
    onSelected,
    width,
    margin,
    defaultValue,
    itemName = "name",
    disabled,
    value,
  } = props;
  const [selectValue, setSelectValue] = React.useState(defaultValue || "");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as string);
    onSelected(event.target.value as string);
  };

  return (
    <FormControl
      sx={{
        margin: !!margin ? margin : 1,
        width: width ? width : "25ch",
      }}
    >
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <Select
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value ?? selectValue}
        label={title}
        defaultValue={defaultValue}
        onChange={handleChange}
        // Hacky way to override select component padding
        sx={{
          "& .css-103b10f-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select": {
            padding: "12px !important",
          },
          "& .css-1ngdtxc.MuiSelect-select": {
            padding: "12px !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          background: "#f0f1f5",
          borderRadius: "30px",
        }}
      >
        <MenuItem key={""} value={""}>
          {"None"}
        </MenuItem>
        {items &&
          items.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              disabled={option.disabled ?? false}
            >
              {option[itemName]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
