import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import Checkbox from "@mui/material/Checkbox";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import ExistingDriverRegistrationSuccess from "components/ExistingDriverRegistrationSuccess";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "hooks/withAuthWrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCarefreeStatus,
  getIsApprovedDriver,
  getIsApprovedHomeOwner,
  getIsRegisteringDriver,
  getIsRegisteringHomeOwner,
  getLoadingStatus,
  getUserDetails,
  setIsHomeOwner,
} from "redux/slices/authenticationSlices";
import {
  existingDriverRegisterationThunk,
  getIsMissingEmergencyInfo,
  getSuccessStatus,
  setIsLoading as setIsLoadingDriver,
} from "redux/slices/driverSlices";
import { setIsLoading as setIsLoadingHomeOwner } from "redux/slices/homeOwnerSlices";
import * as Styled from "./Intro.styled";

const Intro: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number | null>(null);
  const [agreed, setAgreed] = useState<boolean>(false);
  const isLoading = useSelector(getLoadingStatus);
  const isApprovedDriver = useSelector(getIsApprovedDriver);
  const isApprovedHomeOwner = useSelector(getIsApprovedHomeOwner);
  const isRegisteringDriver = useSelector(getIsRegisteringDriver);
  const isRegisteringHomeOwner = useSelector(getIsRegisteringHomeOwner);
  const isMissingEmergencyInfo = useSelector(getIsMissingEmergencyInfo);
  const isSuccess = useSelector(getSuccessStatus);
  const userDetails = useSelector(getUserDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) {
      if (isApprovedDriver || isApprovedHomeOwner) {
        navigate("/home");
      } else if (isRegisteringDriver) {
        navigate("/driver/register");
      } else if (isRegisteringHomeOwner) {
        navigate("/homeowner/register");
      }
    }
  }, [
    navigate,
    isApprovedDriver,
    isApprovedHomeOwner,
    isRegisteringDriver,
    isRegisteringHomeOwner,
    isLoading,
  ]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      navigate("/home");
    }
  }, [navigate, isSuccess, isLoading]);

  useEffect(() => {
    if (!!accessToken) {
      dispatch(getCarefreeStatus(accessToken));
    }
  }, [dispatch]);

  return (
    <>
      {isLoading && <LoadingComponent />}
      {!isLoading && isMissingEmergencyInfo && (
        <ExistingDriverRegistrationSuccess />
      )}
      {!isLoading && !isMissingEmergencyInfo && (
        <Styled.MainContainer>
          <Styled.TopSection>
            <Styled.Spacer height={10} />
            <Styled.Title>I am a ...</Styled.Title>
            <Styled.Spacer height={30} />
            <Styled.Logo
              onClick={() => setSelected(1)}
              src={require("../../assets/icons/DRIVER.png")}
            />
            <Styled.Option
              onClick={() => setSelected(1)}
              selected={selected === 1}
            >
              <Styled.OptionTitle>driver</Styled.OptionTitle>
              <Styled.Spacer height={10} />
            </Styled.Option>
            <Styled.OptionDesc>
              Looking for your own hassle-free private spot? We got you!
            </Styled.OptionDesc>
            <Styled.Spacer height={30} />
            <Styled.Logo
              onClick={() => setSelected(2)}
              src={require("../../assets/icons/HOMEOWNER.png")}
            />
            <Styled.Option
              onClick={() => setSelected(2)}
              selected={selected === 2}
            >
              <Styled.OptionTitle>homeowner</Styled.OptionTitle>
            </Styled.Option>
            <Styled.Spacer height={10} />
            <Styled.OptionDesc>
              Have unused space outside your gate or front porch? Rent it out
              and get extra monthly income!
            </Styled.OptionDesc>
            <Styled.Spacer height={40} />
            <Styled.SubTitle>valid parking times</Styled.SubTitle>
            <Styled.Spacer height={10} />
            <Styled.OptionDesc style={{ color: "#e0479e" }}>
              Monday to Friday
            </Styled.OptionDesc>
            <Styled.Spacer height={5} />
            <Styled.OptionDesc style={{ color: "#e0479e" }}>
              8:30am - 6:30pm
            </Styled.OptionDesc>
          </Styled.TopSection>
          <div>
            <Styled.Spacer height={40} />
            <Styled.CheckboxHolder
              onClick={() => setAgreed((agreed) => !agreed)}
            >
              <Checkbox
                name="policy"
                checked={agreed}
                onChange={() => setAgreed((agreed) => !agreed)}
                onClick={() => setAgreed((agreed) => !agreed)}
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled color="secondary" />}
                sx={{
                  color: "#EBEFF4",
                  "&.Mui-checked  .MuiSvgIcon-root": {
                    color: "#e0479e",
                    borderColor: "white",
                  },
                }}
              />
              <p>
                Select your decision carefully, explorer! Once you check the box
                and confirm your choice, your journey will be set and cannot be
                altered! Ready to go?
              </p>
            </Styled.CheckboxHolder>
            <Styled.Spacer height={30} />
            <Styled.Button
              disabled={!selected || !agreed}
              onClick={() => {
                const isHomeOwner = selected === 2;
                dispatch(setIsHomeOwner(isHomeOwner));
                if (selected === 1) {
                  if (userDetails.role_id === 4) {
                    dispatch(existingDriverRegisterationThunk(accessToken));
                  } else {
                    navigate("/driver/register");
                    setIsLoadingDriver(true);
                  }
                } else if (selected === 2) {
                  navigate("/homeowner/register");
                  setIsLoadingHomeOwner(true);
                }
              }}
            >
              sign me up!
            </Styled.Button>
            <Styled.Spacer height={40} />
          </div>
        </Styled.MainContainer>
      )}
    </>
  );
};

export default WithAuthWrapper(Intro);
