import React, { FC, useState } from "react";
import * as Styled from "./FilterListComponent.styled";
import Logo from "assets/images/logo-park.png";
import KebabIcon from "assets/kebab.svg";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { getAllGeoLocations, openFilterMobile } from "redux/slices/homeSlices";
import { useSelector, useDispatch } from "react-redux";
import useScreenSize from "hooks/useScreenSize";
import ToggleButton from "components/ToggleButton";
import { MIN_TABLET } from "styles/mediaQueries";
import CardComponent from "./CardComponent";
import { makeStyles } from "@material-ui/core/styles";
import DetailsModal from "components/DetailsModal";

const useStyles = makeStyles((theme) => ({
  styleOverrides: {
    root: {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7b809a",
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#vE0479E",
        },
      },
    },
  },
}));
const FilterListComponent: FC = () => {
  const [isAboutUsOpen, openAboutUs] = useState<boolean>(false);
  const allGeoLocations = useSelector(getAllGeoLocations);

  const [screenWidth] = useScreenSize();
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <>
      <DetailsModal isOpen={isAboutUsOpen} onClose={() => openAboutUs(false)} />

      <Styled.FilterMainComponent>
        <Styled.HeaderContainer>
          {/* {screenWidth < MIN_TABLET && ( */}
          <Styled.KebabIcon
            onClick={() => dispatch(openFilterMobile(true))}
            alt="logo"
            src={KebabIcon}
          />
          {/* )} */}

          <div style={{ display: "flex", alignItems: "center" }}>
            <Styled.Bluelogo alt="logo" src={Logo} />
          </div>

          <ToggleButton />
        </Styled.HeaderContainer>
        {/* 
        {screenWidth >= MIN_TABLET && (
          <>
            <Styled.CardScrollbar>
              {!!allGeoLocations &&
                allGeoLocations.map((location, index) => {
                  return (
                    <CardComponent
                      location={location}
                      key={index}
                      openDetails={() => openAboutUs(true)}
                    />
                  );
                })}
            </Styled.CardScrollbar>
          </>
        )} */}
      </Styled.FilterMainComponent>
    </>
  );
};

export default FilterListComponent;
